import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CTooltip,
	CLink,
	CListGroup,
	CListGroupItem,
	CForm,
	CFormInput,
	CFormSelect,
	CSpinner,
	CImage,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,CDropdownDivider,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPagination,
	MyDialoglookup,
	MyDialogform,
	MyDialogview,
	MyPage500,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     	= require('../../package.json');
const Kasirpiutang 	= (props) => {
	const { 
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);

	//--GLOBAL_VARS--/
	const uIsAllowedhapus	= (uTokenObj.userhak==="FREEROLE") && uIsEOMExecuted;
	//const uMaxData			= isMobile ? (uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const uMaxData			= isMobile ? 30 : 100;//(uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	//---DATA--/
	const uKeywordallObj						= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  				= useSelector(state => state.gInitHeaderAction);
	const uHandelView 						= uHeaderActionObj.isHeaderView || false;
	const [uFirstload,setFirstload]			= React.useState("YA");
	//const uKasGLloadArr						= JSON.parse(localStorage.getItem("kasgllist")) || [];

	const [uJmlData,setJmlData]				= React.useState(0);
	const [uJmlHal,setJmlHal]				= React.useState(1);
	const [uPageActive,setPageActive]		= React.useState(0);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const uElJmldata						= document.getElementById("idjmldata");
	//---END DATA--/

	//--FORMDIALOG--/
	const [uDataformObj,setDataformObj]		= React.useState({})
	const [uIsShowForm,setShowForm]			= React.useState(false);
	const [uNotanew,setNotanew]				= React.useState("");
	const [uDialogHeader,setDialogHeader]	= React.useState("");

	const [uElBtnDlgsimpan,setElBtnDlgsimpan]= React.useState(document.getElementById("btnDialogSimpan"));
	//--END FORMDIALOG--/

	const [uIsShowview,setShowview]			= React.useState(false);
	const [uDatahistoriArr,setDatahistoriArr]= React.useState([]);
	const [uDatapaymentArr,setDatapaymentArr]= React.useState([]);
	const [uDetilDiskon,setDetilDiskon]		= React.useState(0);
	const [uDetilBKartu,setDetilBKartu]		= React.useState(0);
	//--END GLOBAL_VARS--/

	//--CETAK_VARS--/
	const uHTMLstylenota					= useSelector(state => state.gHTMLstyleNota);
	const [uDataidentitas,setDataidentitas]	= React.useState({});
	const [uDatasetting,setDatasetting]		= React.useState({});
	const [uDataCetakObj,setDataCetakObj]	= React.useState(null);
	const uSettingcetakObj					= JSON.parse(localStorage.getItem("settingnota") || "{}");
	const uLebarKertas						= parseInt(uSettingcetakObj.lebarkertas)||0;
	const uMarginKiri						= parseInt(uSettingcetakObj.marginkiri)||1;
	const uFontsize							= parseInt(uSettingcetakObj.fontsize)||12;
	const uFontstyle						= uSettingcetakObj.fontstyle || "Courier, Courier New, Sans Serif";
	const uFrameExport 						= document.getElementById("ifmcontentstoprint");
	const [uFinishingBayar,setFinishingBayar] = React.useState(false);
	//--END CETAK_VARS--/

	const [uDlgviewjenis,setDlgviewjenis]	= React.useState("");
	const [uIsLookupShow,setLookupShow] 	= React.useState(false);
	const [uInpLookupKwd,setInpLookupKwd] 	= React.useState("");
	const [uLookupDataArr,setLookupDataArr] = React.useState([]);

	const uKwdpiutangObj					= uKeywordallObj.kwd_kasirpiutang || {};
	const uKeywordteks						= uKwdpiutangObj.kwd_inputteks || "YA";
	const uKeywordstr 						= uKwdpiutangObj.kwd_value || "";

	//---HANDLE--/
	const hdlToggleForm=()=>{ setShowForm(false) }
	const hdlToggleLookup=()=>{ setLookupShow(false) }
	const hdlToggleView=()=>{ setShowview(false) }
	const hdlKlikFormSimpan=()=>{
		//---VALIDASI--/
		if(UFunc.isEmpty(uDataformObj.tgl_bayar)) {
			document.getElementById("inptglbayar").focus();
			showToast((uBahasaObj.caption_tglbayar||"caption_tglbayar")+" "+uBahasaObj.caption_invalid+"..");
			return;
		}

		const vTanggalDt 	= new Date(uDataformObj.tgl_bayar);
		const vDateBeyond = UFunc.DateIsBeyond(vTanggalDt);
		if(vDateBeyond) {
			document.getElementById("inptglbayar").focus();
			showToast((uBahasaObj.pesan_tglisbeyond||"pesan_tglisbeyond")+"..");
			return;
		}
		const vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
		if(vDateExceededBl) {
			document.getElementById("inptglbayar").focus();
			showToast((uBahasaObj.pesan_tglisexpiredyear||"pesan_tglisexpiredyear")+"..");
			return;
		}

		if((parseInt(uDataformObj.nilai_bayar)||0) <= 0 ) {
			document.getElementById("inpnilaibayar").focus();
			showToast((uBahasaObj.caption_nilaibayar||"caption_nilaibayar")+" "+uBahasaObj.caption_invalid+"..");
			return;
		}
		if((parseInt(uDataformObj.id_payment_method)||0) === 0) {
			showToast((uBahasaObj.caption_paymentmethod||"caption_paymentmethod")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		//---END VALIDASI--/

		apiSimpanbayar(uDataformObj.id_piutang);
	}
	const hdlKlikLookuppilih = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM) || 0; if(_IDITEM <= 0) { setLookupShow(false); return }

		const vObjdx = UFunc.getObjectIndeks(uLookupDataArr,_IDITEM,"id");
		if(vObjdx < 0) return;

		uDataformObj.id_payment_method 	= (uLookupDataArr[vObjdx].id)
		uDataformObj.nama_metode 		= (uLookupDataArr[vObjdx].caption)
			
		if(parseInt(uDataformObj.id_piutang||"0") > 0) {
			uDataformObj.no_nota 		= uNotanew;
			uDataformObj.tgl_bayar 		= UFunc.DbDate();

			hdlToggleLookup();		
			setShowForm(true);// initLoadbayar(uDataformObj.id_piutang);
		}
	}
	const hdlKlikHistori=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		//alert(vObjdx);return;
		const vHeadercaption = (uBahasaObj.caption_historibayar||"caption_historibayar")+": "+
			((uDatamainArr[vObjdx].no_nota||"")+
				((uDatamainArr[vObjdx].caption_customer||"")!==""?" ("+(uDatamainArr[vObjdx].caption_customer||"")+")" : "")
				).toUpperCase();
		setDlgviewjenis("HISTORI");
		setDialogHeader(vHeadercaption);
		apiLoadhistori(_IDTABEL);
	}
	const hdlKlikDetil=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		//alert(vObjdx);return;
		const vDetilsArr= uDatamainArr[vObjdx].items_arr ||[]
		const vHeadercaption = (uBahasaObj.caption_detilitem||"caption_detilitem")+": "+
			((uDatamainArr[vObjdx].no_nota||"")+
				((uDatamainArr[vObjdx].caption_customer||"")!==""?" ("+(uDatamainArr[vObjdx].caption_customer||"")+")" : "")
				).toUpperCase();
		setDlgviewjenis("DETIL");
		setDialogHeader(vHeadercaption);

		if(vDetilsArr.length > 0) {
			setShowview(true); return;
		}

		apiLoaddetil(_IDTABEL);
	}
	const hdlKlikBayar = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL||0); if(_IDTABEL <= 0) return;

		uDataformObj.id_piutang	= (_IDTABEL)
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setLookupDataArr(uDatapaymentArr);
		setDialogHeader((uBahasaObj.word_pilih||"Pilih")+
			": "+(uBahasaObj.caption_paymentmethod||"").toUpperCase());
		setLookupShow(true)
	}
	const hdlKlikHapuspiutang=async(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vConfirm	= 
			(uBahasaObj.caption_penghapusanpiutang||"caption_penghapusanpiutang")+": <B>"+
			uDatamainArr[vObjdx].no_nota+" ("+(uDatamainArr[vObjdx].caption_customer||"").toUpperCase()+")</B>"+
			"<BR>"+(uBahasaObj.petunjuk_hapuspiutang||"petunjuk_hapuspiutang")+
			"<BR><BR><B>"+(uBahasaObj.confirm_yakin||"confirm_yakin")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiHapuspiutang(_IDTABEL);
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initJmldata=()=>{
		if (uKeywordstr.length < 3) {
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
			return;
		}//-*/

		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initResetDlgform=()=>{
		setTabelIDPilih(0);
		/*uDataformObj.nota_trx 		= ("");
		uDataformObj.tgl_piutang 	= ("");
		uDataformObj.piutang_sisa 	= (0);
		uDataformObj.id_payment_method = (0);
		uDataformObj.nama_metode 	= ("");
		uDataformObj.b_kartu 		= (0);
		uDataformObj.ket_kartu 		= ("");

		uDataformObj.tgl_bayar 		= (UFunc.DbDate());
		uDataformObj.nilai_bayar 	= (0);//-*/

		setDataformObj({})
		setEfekview(!uEfekview)
	}
	const initLoadbayar = ()=>{
		const vTabelID	= parseInt(uDataformObj.id_piutang) ||0;if(vTabelID <= 0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);

		const vSisaPiutang	= (parseInt(uDatamainArr[vObjdx].nilai_piutang)||0)
			- (parseInt(uDatamainArr[vObjdx].terbayar)||0);
		const vHeadercaption= (uBahasaObj.caption_bayarpiutang||"caption_bayarpiutang")+": "+
			(uDatamainArr[vObjdx].caption_customer||"").toUpperCase()+
			" ("+(uBahasaObj.word_sisa||"word_sisa")+" Rp "+UFunc.formatAngka(vSisaPiutang)+")"+
			"";
		setDialogHeader(vHeadercaption);

		setTabelIDPilih(vTabelID)
		uDataformObj.nota_trx 		= (uDatamainArr[vObjdx].no_nota || "{UNDF}");
		uDataformObj.tgl_piutang 	= (uDatamainArr[vObjdx].tgl_piutang || "");
		uDataformObj.piutang_sisa 	= (vSisaPiutang);
		//uDataformObj.tgl_bayar 		= UFunc.DbDate();
		uDataformObj.nilai_bayar 	= (vSisaPiutang);

		//setShowForm(true)
	}
	const initCetakNota=()=>{
		//console.log("uDataCetakObj = "+JSON.stringify(uDataCetakObj||{}));
		if(uDataCetakObj === null) return "";

		let vHTMLs 	= uHTMLstylenota;

		vHTMLs 		= vHTMLs.replace(/_LEBARKERTAS_/g, (uLebarKertas<=0?"auto":uLebarKertas+"mm"));//-->PAKESATUAN
		vHTMLs 		= vHTMLs.replace(/_MARGINKIRI_/g,uMarginKiri);
		vHTMLs 		= vHTMLs.replace(/_FONTSIZE_/g,uFontsize);
		vHTMLs 		= vHTMLs.replace(/_FONTFAMILY_/g,uFontstyle);

		const vIsWithlogo	= (uDatasetting.view_image_nota||"TIDAK")==="YA"?true:false;
		const vURLlogo		= uDatasetting.url_image_nota||"";//getBase64Image(document.getElementById("idimglogo"));

		const vMainbody	= UFunc.Rendernota(uDataCetakObj);
		vHTMLs 		+=  vMainbody;

		//---LOGO--/
		let vLogoheader	= "";
		if(vIsWithlogo) 
			vLogoheader = `
			<tr valign="top">
			<td colSpan="4" align="center">
				<img src="`+vURLlogo+`" height="25mm" style="height:25mm" />
			</td>
			</tr>
			`;
		//---END LOGO--/

		//---TELP--/
		let vTelpPerusahaan	= "";
		if((uDataidentitas.telp || "") !== "")
			vTelpPerusahaan += `
			<tr valign="top">
			<td colSpan="4" align="center">`+
				//(uBahasaObj.caption_telphp||"caption_telphp")+". "+
				UFunc.capitalize(uDataidentitas.telp || "")+
			`</td>
			</tr>
			`;
		//---END TELP--/

		//--TAGLINE--/
		const vTagline	=  uDataidentitas.slogan !== "" 
			? `
			<tr valign="top">
			<td colSpan="4" align="center">`+
				UFunc.capitalize(uDataidentitas.slogan || "")+
			`</td>
			</tr>
			` : ``;
		//--END TAGLINE--/

		const vFooternota = (uDatasetting.footer_nota||"").trim() !=="" ?
			`
			<tr>
			<td colSpan="4"><HR></td>
			</tr>
			<tr>
			<td colSpan="4" align="center">`+(uDatasetting.footer_nota||"")+`</td>
			</tr>
			` : ``;

		vHTMLs 		= vHTMLs.replace(/_TRBODYLOGO_/g,vLogoheader);
		vHTMLs 		= vHTMLs.replace(/_NAMAPERUSAHAAN_/g,(uDataidentitas.nama_perusahaan || "").toUpperCase());
		vHTMLs 		= vHTMLs.replace(/_ALAMATPERUSAHAAN_/g,UFunc.capitalize(uDataidentitas.alamat || ""));
		vHTMLs 		= vHTMLs.replace(/_TRBODYTELP_/g,vTelpPerusahaan);
		vHTMLs 		= vHTMLs.replace(/_FOOTERNOTA_/g,vFooternota);
		vHTMLs 		= vHTMLs.replace(/_TRBODYTAGLINE_/g,vTagline);

		return vHTMLs;
	}
	const prosesCetakNota=()=>{
		var vContent 	= initCetakNota();
		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();

		setFinishingBayar(false);
		hdlToggleForm();

		setDataCetakObj(null);
	}
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= uKeywordteks==="YA" ? UFunc.filterRegex(uKeywordstr) : uKeywordstr;
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		//const vDataviewArr	= isMobile ? uDatamainArr.slice(0,(vMax+1)) : uDatamainArr.slice(vMin,(vMax+1));
		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="3" className="text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol md="3" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_totalpiutang||"caption_totalpiutang"}<small className="d-block">(Rp)</small></CCol>
				<CCol md="1" className="px-0">{uBahasaObj.word_terbayar||"word_terbayar"}<small className="ms-1">(Rp)</small></CCol>
				<CCol md="2" className="">{uBahasaObj.caption_sisapiutang||"caption_sisapiutang"}<small className="d-block">(Rp)</small></CCol>
				<CCol md="1" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>

			<tr className="d-md-none">
			<td width={30} className="p-0"/>
			<td className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,caption_customer,tgl_piutang,nilai_piutang,tgl_input,tgl_jt,terbayar,
				caption_pasien,no_nota,alamat,telp,init_loading,id_gl
			} = vItems;

			vNumber++;
			const vCaptionUpper	= ((caption_customer||"")+
				((no_nota||"")!==""?" ("+(no_nota||"")+")":"")).toUpperCase();

			const vSisapiutang 	= parseInt(nilai_piutang) - parseInt(terbayar);
			const vGLID			= parseInt(id_gl);

			let vNamaCustomer 	= UFunc.capitalize(caption_customer||"");
			let vNonota			= (no_nota||"");
			let vPasienlist		= (caption_pasien||"");
			let vAlamat			= (alamat||"");
			let vTelp 			= (telp||"");
			let vTglPiutang		= UFunc.TglAngka(tgl_piutang);
			let vTglJT			= UFunc.TglAngka(tgl_jt);

			if(uKeywordteks === "YA") {
				vNamaCustomer	= vKeyword==="" ? UFunc.capitalize(caption_customer||"") 
					: (UFunc.capitalize(caption_customer||"")).replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
				vNonota= vKeyword==="" ? (no_nota||"") 
					: (no_nota||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
				vPasienlist= vKeyword==="" ? (caption_pasien||"") 
					: (caption_pasien||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
				vAlamat = vKeyword==="" ? (alamat||"") 
					: (alamat||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
				vTelp = vKeyword==="" ? (telp||"") 
					: (telp||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
				vTglPiutang= vKeyword==="" ? UFunc.TglAngka(tgl_piutang||"") 
					: (UFunc.TglAngka(tgl_piutang||"")).replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
				vTglJT= vKeyword==="" ? UFunc.TglAngka(tgl_jt||"") 
					: (UFunc.TglAngka(tgl_jt||"")).replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			}
			
			return (
			<tr id={"idtr"+id} 
				className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")+
					(vGLID!==34?" border border-success":"")}
				key={vKeys}>
				<td className="px-1 pe-0">{(vNumber)}.</td>
				<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="12" className="px-1 text-start d-flex d-md-none justify-content-between">
					<b className="text-info">{UFunc.renderHTML(vNamaCustomer)}</b>

					<div className="d-md-none">
					{(init_loading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<>
					<CDropdown>
						<CDropdownToggle color="transparent" className="p-0" caret={false}>
							<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu>
							{(uIsEOMExecuted && vSisapiutang > 0) && (
							<>
							<CDropdownItem onClick={()=>hdlKlikBayar(id)}>
								<CIcon className="classikonbayar mx-0 me-1 align-middle" height={25}/>
								{uBahasaObj.caption_bayarpiutang||"caption_bayarpiutang"}
							</CDropdownItem> 
							<CDropdownDivider/>
							</>
							)}

							<CDropdownItem onClick={()=>hdlKlikHistori(id)}>
							<CIcon className="classikonhistoribayar mx-0 me-1 align-middle" height={25}/>
							{uBahasaObj.caption_historibayar||"caption_historibayar"}
							</CDropdownItem> 

							<CDropdownItem onClick={()=>hdlKlikDetil(id)}>
							<CIcon className="classikondetil mx-0 me-1 align-middle" height={25}/>
							{uBahasaObj.caption_detilitem||"caption_detilitem"}
							</CDropdownItem> 

							{(uIsAllowedhapus && vSisapiutang > 0 && vGLID===34) && (
							<>
							<CDropdownDivider/>
							<CDropdownItem onClick={()=>hdlKlikHapuspiutang(id)}>
								<CIcon className="classikonhapus mx-0 me-1 align-middle" height={25}/>
								{uBahasaObj.caption_penghapusanpiutang||"caption_penghapusanpiutang"}
							</CDropdownItem> 
							</>
							)}
						</CDropdownMenu> 
					</CDropdown>
					</>
					)}
					</div>
				</CCol>

				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

				{(caption_pasien||"")!=="" && (
				<>
				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_pasien||"Pasien"}</CCol>
				<CCol xs="7" className="px-1 d-md-none text-primary">
					<small>{UFunc.renderHTML(vPasienlist)}</small>
				</CCol>
				</>
				)}

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_nota||"word_nota"}</CCol>
				<CCol xs="7" className="px-1 d-md-none">
					<span className="fw-bolder">{UFunc.renderHTML(vNonota)}</span>
				</CCol>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol xs="6" md="3" className="px-1 text-md-start">
					{UFunc.renderHTML(vTglPiutang)}
					{uTokenObj.userhak==="FREEROLE" && (
						<span className="classfontsmaller"> &middot; <small>({id})</small></span>
					)}

					{(tgl_jt||"")!=="" && (
					<div className="d-none d-md-block">
					<small className="classfontsmaller">
						{uBahasaObj.caption_tgljt||"caption_jthtempo"}:{" "}
						<span className={UFunc.DateIsMundur(UFunc.toDate(tgl_jt||""))?"fw-bolder text-danger":"text-success"}>
						{UFunc.renderHTML(vTglJT)}
						</span>

						{UFunc.DateIsMundur(UFunc.toDate(tgl_jt||"")) && (
						<CIcon className="classblinking classikonwarning align-middle" height={23}/>
						)}					
					</small>
					</div>
					)}

					<div className="d-none d-md-block fw-bolder">
						{UFunc.renderHTML(vNonota)}
					</div>
				</CCol>

				{(tgl_jt||"")!=="" && (
				<>
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_tgljt||"caption_tgljt"}</CCol>
				<CCol xs="6" className="px-1 d-md-none">
					<small className={UFunc.DateIsMundur(UFunc.toDate(tgl_jt||""))?"fw-bolder text-danger":"text-success"}>
						{UFunc.renderHTML(vTglJT)}
					</small>

					{UFunc.DateIsMundur(UFunc.toDate(tgl_jt||"")) && (
						<CIcon className="classblinking classikonwarning align-middle" height={23}/>
					)}
				</CCol>
				</>
				)}

				{((telp||"").length >= 3) && (
				<>
				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_telphp||"caption_telphp"}</CCol>
				<CCol xs="8" className="px-1 d-md-none classfontsmaller">
					<b>{UFunc.renderHTML(vTelp)}</b>
				</CCol>
				</>
				)}
				{((alamat||"").length >= 3 && (telp||"").length < 3) && (
				<>
				<CCol xs="3" className="px-1 d-md-none text-start">{uBahasaObj.word_alamat||"word_alamat"}</CCol>
				<CCol xs="9" className="px-1 d-md-none">
					<small className="fst-italic">{UFunc.renderHTML(vAlamat)}</small>
				</CCol>
				</>
				)}

				<CCol md="3" className="px-1 d-none d-md-block text-start">
					<div className="fw-bolder text-info">
						{UFunc.renderHTML(vNamaCustomer)}
					</div>	

					{(caption_pasien||"")!=="" && (
						<div className="classfontsmaller text-primary"><small>&middot; {UFunc.renderHTML(vPasienlist)} &middot;</small></div>
					)}

					{((telp||"").length >= 3) && (
					<div className="d-none d-md-block fst-italic classfontsmaller">
						<small>
						{uBahasaObj.caption_telphp||"caption_telphp"}
						<span className="me-1">:</span>
						<b>{UFunc.renderHTML(vTelp)}</b>
						</small>
					</div>
					)}

					{((alamat||"").length >= 3 && (telp||"").length < 3) && (
					<div className="d-none d-md-block classfontsmaller">
						<small className="fst-italic">{UFunc.renderHTML(vAlamat)}</small>
					</div>
					)}
				</CCol>

				<CCol xs="12" className="d-md-none my-3 px-1 classborderbottom"/>
				
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_totalpiutang||"caption_totalpiutang"}</CCol>
				<CCol xs="6" md="2" className="px-1">
					<span className="d-md-none">Rp </span>{UFunc.formatAngka(nilai_piutang)}
				</CCol>
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.word_terbayar||"word_terbayar"}</CCol>
				<CCol xs="6" md="1" className="px-1 px-md-0">
					<small className="d-none d-md-block">{UFunc.formatAngka(terbayar)}</small>
					<div className="d-md-none">Rp {UFunc.formatAngka(terbayar)}</div>
				</CCol>
				
				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_sisapiutang||"caption_sisapiutang"}</CCol>
				<CCol xs="6" md="2" className="px-1 text-primary">
					<span className="d-md-none">Rp </span>{UFunc.formatAngka(vSisapiutang)}
				</CCol>

				<CCol md="1" className="text-center px-0 d-none d-md-block">
				{(init_loading||false)===true ? (
					<CSpinner color="primary" size="sm"/>
				) : (
				<>
					{(uIsEOMExecuted && vSisapiutang > 0) && (
					<>
					<CTooltip content={"--"+(uBahasaObj.caption_bayarpiutang||"caption_bayarpiutang")+": "+vCaptionUpper}>
					<CLink 
						className="classikontabel classikonbayar classcursorpointer classikoncoreui"
						onClick={()=>hdlKlikBayar(id)}/>
					</CTooltip>
					</>
					)}

					<CDropdown>
					<CDropdownToggle color="transparent" className="p-0" caret={false}>
						<CIcon icon="cilChevronBottom" className="classikoncoreui mx-0 ms-lg-1 d-none d-md-block" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem 
							className="classcursorpointer"
							onClick={()=>hdlKlikHistori(id)}>
						<CIcon className="classikonhistoribayar me-1 align-middle" height={25}/>
						{uBahasaObj.caption_historibayar||"caption_historibayar"}
						</CDropdownItem> 

						<CDropdownItem 
							className="classcursorpointer"
							onClick={()=>hdlKlikDetil(id)}>
						<CIcon className="classikondetil me-1 align-middle" height={25}/>
						{uBahasaObj.caption_detilitem||"caption_detilitem"}
						</CDropdownItem> 

						{(uIsAllowedhapus && vSisapiutang > 0 && vGLID===34) && (
						<>
						<CDropdownDivider/>
						<CDropdownItem 
							className="classcursorpointer"
							onClick={()=>hdlKlikHapuspiutang(id)}>
						<CIcon className="classikonhapus me-1 align-middle" height={25}/>
						{uBahasaObj.caption_penghapusanpiutang||"caption_penghapusanpiutang"}
						</CDropdownItem> 
						</>
						)}
					</CDropdownMenu> 
					</CDropdown>
				</>
				)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgform=()=>{
		if(!uIsShowForm) return (<></>)//>

		return (
		<CForm>
		<CRow className="align-items-stretch">
		<CCol lg="6" className="mb-3 my-lg-0">
		<CCard className="h-100">
			<CCardHeader className="py-0 pt-1"><small>Summary Transaksi</small></CCardHeader>
			<CCardBody>
			<div className="my-1 d-flex justify-content-between">
				<div>{uBahasaObj.caption_notatransaksi||"caption_notatransaksi"}</div>
				<div className="text-end w-50">
					<CFormInput 
						size="sm"
						value={uDataformObj.nota_trx||""}
						disabled={true}
						className="text-uppercase"
						id="inpnotatransaksi"/>
				</div>
			</div>

			<div className="my-1 d-flex justify-content-between">
				<div>{uBahasaObj.caption_sisapiutang||"caption_sisapiutang"}</div>
				<div className="text-end w-50">
					<CFormInput size="sm"
						value={"Rp"+UFunc.formatAngka(uDataformObj.piutang_sisa||"0")}
						disabled={true}
						className="text-end fw-bolder"
					id="inpnilaisisa"/>
				</div>
			</div>

			<div className="my-1 d-flex justify-content-between">
				<div>{uBahasaObj.caption_tgltransaksi||"caption_tgltransaksi"}</div>
				<div className="text-end w-50">
					<CFormInput size="sm"
						type="date"
						value={uDataformObj.tgl_piutang||""}
						disabled={true}
						id="inptgltransaksi"/>
				</div>
			</div>

			<div className="my-3 classborderbottom"/>

			<div className="my-2 d-flex justify-content-between">
				<div>{uBahasaObj.caption_paymentmethod||"caption_paymentmethod"}</div>
				<div className="text-end text-primary">
					<b>{uDataformObj.nama_metode}</b>
				</div>
			</div>

			{(uDataformObj.nama_metode||"").toUpperCase()!=="TUNAI" && (
			<>
			<div className="my-1 d-flex justify-content-between">
				<small>{uBahasaObj.caption_biayanontunai||"caption_biayanontunai"}</small>
				<div className="text-end w-50">
				<NumberFormat 
					value={uDataformObj.b_kartu||"0"}
					displayType={"text"} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					allowNegative={false}
					onValueChange={(values) => uDataformObj.b_kartu = values.value }
					renderText={values => (
						<CFormInput size="sm"
							value={values}
							className="text-end"
							onChange={(e) => {uDataformObj.b_kartu = e.target.value;setEfekview(!uEfekview)} }
							onFocus={(e)=>e.target.select()}
						id="inppaymentbiaya"/>
					)} 
				prefix={isMobile?"":"Rp"}/>
				</div>
			</div>

			<div className="my-1">
				<CFormInput maxLength={255}
					size="sm"
					placeholder={"--"+(uBahasaObj.caption_ketnontunai||"caption_ketnontunai")}
					value={uDataformObj.ket_kartu||""}
					onChange={(e)=>{uDataformObj.ket_kartu = e.target.value;setEfekview(!uEfekview)} }
					id="inppaymentket"/>
			</div>
			</>
			)}
		</CCardBody>
		</CCard>
		</CCol>

		<CCol className="">
		<CCard className="h-100">
		<CCardHeader className="py-0 pt-1"><small>Pembayaran</small></CCardHeader>
		<CCardBody>
			<div className="my-1 d-flex justify-content-between">
				<div>{uBahasaObj.word_nonota||"word_nonota"}</div>
				<div className="text-end w-50">
				<CFormInput maxLength={20}
					size="sm"
					value={uDataformObj.no_nota||""}
					className="text-uppercase"
					disabled={true}
					id="inpnonota"/>
				</div>
			</div>

			<div className="my-1 d-flex justify-content-between">
				<div>{uBahasaObj.caption_totalnetto||"caption_totalnetto"}</div>
				<div className="text-end w-50">
				<CFormInput 
					value={"Rp"+(UFunc.formatAngka(uDataformObj.nilai_netto||"0"))}
					disabled={true}
					size="sm"
					className="text-end text-primary fw-bolder"
				id="inpnilainetto"/>
				</div>
			</div>

			<div className="classborderbottom my-4"/>

			<div className="my-1 d-flex justify-content-between">
				<div className="">{uBahasaObj.caption_tglbayar||"caption_tglbayar"}</div>
				<div className="text-end w-50">
					<CFormInput type="date"
						size="sm"
						value={uDataformObj.tgl_bayar||""}
						onChange={(e)=>{uDataformObj.tgl_bayar = e.target.value;setEfekview(!uEfekview)} }
						id="inptglbayar"/>
				</div>
			</div>
			

			<div className="my-1 d-flex justify-content-between">
				<div className="text-info">{uBahasaObj.caption_nilaibayar||"caption_nilaibayar"}</div>
				<div className="text-end w-50">
				<NumberFormat 
					value={uDataformObj.nilai_bayar||"0"}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					allowNegative={false}
					onValueChange={(values)=>{uDataformObj.nilai_bayar = values.value} }
					renderText={values => (
						<CFormInput 
							size="sm"
							value={values}
							className="text-end"
							onChange={(e)=>{uDataformObj.nilai_bayar = e.target.value;setEfekview(!uEfekview)} }
							onFocus={(e)=>e.target.select()}
							onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
						id="inpnilaibayar"/>
					)} 
				prefix={isMobile?"":"Rp"}/>
				</div>
			</div>
			
			<div className="my-2 d-flex justify-content-between">
				<div className="fw-bolder">
					<big className={((parseInt(uDataformObj.nilai_sisa||"0")) <= 0 ? "text-success":"text-danger")}>{uDataformObj.caption_sisa}</big>
				</div>
				<div className="text-end fw-bolder w-50">
					<big className={((parseInt(uDataformObj.nilai_sisa||"0")) <= 0 ? "text-success":"text-danger")}>
					Rp{UFunc.formatAngka(Math.abs(uDataformObj.nilai_sisa||0))}
					</big>
				</div>
			</div>
		</CCardBody>
		</CCard>

		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentDlgview = () => {
		if(!uIsShowview) return (<></>)//>

		if(uDlgviewjenis === "HISTORI") return contentHistori();
		if(uDlgviewjenis === "DETIL") return contentDetil();
		return (<></>)//>
	}
	const contentHistori = () => {
		if(uDlgviewjenis !== "HISTORI") return (<></>)//>
		if(uDatahistoriArr.length <= 0) return (<></>)//>

		return (
		<div className="mx-0 mx-lg-5">
		<table className="table table-striped table-borderless" id="idtabeldetil">
		<thead>
			<tr className="fw-bolder align-top text-center classfontsmaller">
			<td width="5%" className="px-1">No</td>
			<td className="px-1 text-start">Tgl Bayar</td>
			<td width="25%" className="px-1">Jml Bayar (Rp)</td>
			</tr>
		</thead>

		<tbody>
			{uDatahistoriArr.map((vItems,vKeys)=>{
				const {
					id,tgl_bayar,no_nota,nilai_bayar,status_penghapusan
				} = vItems;

				const vBgcolor 	= vKeys%2===0 ? "secondary" : "white";
				const vAccent 	= vKeys%2===0 ? "success" : "warning";
				return (
				<tr className={"align-top classfontsmaller border-start-5 border-start-"+vAccent}
					key={vKeys}>
					<td className="px-1 text-end">{vKeys+1}.</td>
					<td className="px-1 text-start">
						<div><b>{UFunc.HariAngka(tgl_bayar)}</b>
						{(status_penghapusan||"TIDAK")==="YA" && (
							<span className="text-danger fst-italic">
								<span className="mx-1">&middot;</span>
								{uBahasaObj.caption_penghapusanpiutang||"caption_penghapusanpiutang"}
							</span>
						)}
						</div>

						{(no_nota||"")!=="" && (
						<div className="fst-italic">
							<small>
							{uBahasaObj.word_nonota||"word_nonota"}:{" "}
							<b className="text-info text-uppercase">{UFunc.capitalize(no_nota||"")}</b>
							</small>
						</div>
						)}
					</td>

					<td className="px-1 text-end text-primary">{UFunc.formatAngka(nilai_bayar)}</td>
				</tr>
				)
			})}
		</tbody>
		</table>
		</div>
		)//>
	}
	const contentDetil = () => {
		if(uDlgviewjenis !== "DETIL") return (<></>)//>

		const vObjdx		= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		const vDataitemArr	= (uDatamainArr[vObjdx]||{}).items_arr || []

		if(vDataitemArr.length <= 0) return (<></>)//>

		const vSubtotal	= vDataitemArr.reduce((vSum,vItems)=>{
			return vSum + ((parseFloat(vItems.hsatuan)||0) * (parseFloat(vItems.qty)||0) )
		},0);

		const vNetto 	= vSubtotal + ((parseFloat(uDetilBKartu)||0) - (parseFloat(uDetilDiskon)||0))

		return (
		<>
		<CRow className="align-items-stretch">
		<CCol lg="6" className="mb-3">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller"><small>Data Transaksi</small></CCardHeader>
			<CCardBody>
			<div className="d-flex justify-content-between my-1">
				<div>{uBahasaObj.caption_tgltransaksi||"caption_tgltransaksi"}</div>
				<div className="text-end">
					{UFunc.TglAngka(uDatamainArr[vObjdx].tgl_piutang)}
				</div>
			</div>

			{(uDatamainArr[vObjdx].no_nota||"")!=="" && (
			<div className="d-flex justify-content-between my-1">
				<div>{uBahasaObj.word_nonota||"word_nonota"}</div>
				<div className="text-end">
					<b className="text-info">{(uDatamainArr[vObjdx].no_nota||"")}</b>
				</div>
			</div>
			)}

			<div className="d-flex justify-content-between my-1">
				<div>{uBahasaObj.word_customer||"word_customer"}</div>
				<div className="text-end">
					{(uDatamainArr[vObjdx].caption_customer||"")}
				</div>
			</div>
			</CCardBody>
			</CCard>
		</CCol>

		<CCol className="mb-3">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller"><small>Total Summary</small></CCardHeader>
			<CCardBody>
			{(vSubtotal !== vNetto) && (
			<div className="d-flex justify-content-between my-1">
				<div className="">{uBahasaObj.caption_subtotal||"Sub Total"}</div>
				<div className="text-end text-primary">Rp{UFunc.formatAngka(vSubtotal)}</div>
			</div>
			)}

			{((parseFloat(uDetilDiskon)||0) > 0) && (
			<div className="d-flex justify-content-between my-1">
				<div>{uBahasaObj.word_diskon||"word_diskon"}</div>
				<div className="text-end text-danger">Rp{UFunc.formatAngka(uDetilDiskon)}</div>
			</div>
			)}

			{((parseFloat(uDetilBKartu)||0) > 0) && (
			<div className="d-flex justify-content-between my-1">
				<div>{uBahasaObj.caption_biayanontunai||"caption_biayanontunai"}</div>
				<div className="text-end">Rp{UFunc.formatAngka(uDetilBKartu)}</div>
			</div>
			)}

			<div className="d-flex justify-content-between my-1">
				<div>{uBahasaObj.word_total||"word_total"}</div>
				<div className="text-end text-primary fw-bolder">
					Rp{UFunc.formatAngka(vNetto)}
				</div>
			</div>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		
		<CCard className="mb-3">
		<CCardHeader className="py-0 pt-1 classfontsmaller"><small>Data Item</small></CCardHeader>
		<CCardBody className="px-0">
		<table className="table table-borderless table-striped" id="idtabelitem">
		<thead>
			<tr className="align-top text-center fw-bolder classfontsmaller">
			<td width="5%" className="p-0"/>
			<td className="p-0"/>
			<td width="30%" className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataitemArr.map((vItems,vKeys)=>{
			const {
				id,caption_item,caption_pasien,hsatuan,qty
			} = vItems;

			//vSubtotal	= vSubtotal + ((parseInt(hsatuan)||0) * (parseInt(qty)||0));

			const vBgcolor 	= vKeys%2===0 ? "secondary" : "white";
			const vAccent 	= vKeys%2===0 ? "success" : "warning";
			return (
			<tr className={"align-top border-start-5 border-start-"+vAccent}
				key={vKeys}>
				<td className="px-1 text-end">{(vKeys+1)}.</td>

				<td className="px-1 text-start">
					{((caption_pasien||"")!=="") && (
					<small className="text-success me-1">[{UFunc.capitalize(caption_pasien||"")}]</small>
					)}
					<small className="fst-italic">{(caption_item||"")}</small>
				</td>

				<td className="px-1">
					<div className=" d-flex justify-content-between">
						<div>
						<b>{UFunc.formatAngka(qty)}</b> 
						<span className="mx-1">x</span>
						{UFunc.formatAngka(hsatuan)}
						</div>

						<div className="text-end text-primary">Rp{UFunc.formatAngka((hsatuan*qty))}</div>
					</div>
				</td>
			</tr>
			)
		})}
		</tbody>
		<tfoot className="bg-dark text-white fw-bolder">
		<tr className="border-start-5 border-start-primary">
			<td colSpan={2} className="text-center">JUMLAH</td>
			<td className="text-end">{UFunc.formatAngka(vSubtotal)}</td>
		</tr>
		</tfoot>
		</table>
		</CCardBody>
		</CCard>
		</>
		)//>
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiLoaddata = (_PAGE) => {
		uFuncname 	= apiLoaddata.name;
		_PAGE		= parseInt(_PAGE) || 1;
		setHTMLError500();

		//const vKasGLDefaultArr = JSON.parse(localStorage.getItem("kasgllist"));

		const vKeyword 		= uKeywordstr;
		const vKeywordteks 	= uKeywordteks;

		const vHandelview	= (uHandelView===true || uFirstload==="YA") ?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Kasirpiutang-apiLoaddata) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_keyword : vKeyword,
			send_keywordteks : vKeywordteks,
			send_pg: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirpiutang/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("(Kasirpiutang-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				let vTmpObj = JSON.parse(output_string.dataobject||"[]");
				vTmpObj.map(vItems=>vItems.pg = _PAGE);
				setDatamainArr([...vDatamainArr,...vTmpObj]);

				if(vHandelview === "YA")
					setJmlData(parseInt(output_string.totaldata||uJmlData))

				if(uFirstload === "YA") {
					setNotanew(output_string.notanew||uNotanew);
					setDataidentitas(JSON.parse(output_string.dataidentitas||JSON.stringify(uDataidentitas)));
					setDatasetting(JSON.parse(output_string.datasetting||JSON.stringify(uDatasetting)));
					setDatapaymentArr(JSON.parse(output_string.paymentlist||JSON.stringify(uDatapaymentArr)));

					setFirstload("TIDAK");
				}

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kasirpiutang-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Kasirpiutang-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadhistori = (_IDTABEL) => {
		uFuncname = apiLoadhistori.name;
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setDatahistoriArr([]);
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vObjdx].init_loading = true;

		/*//--TESTING_FRONTEND--/
		console.log("(Kasirpiutang-apiLoadhistori) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_piutangid : _IDTABEL.toString(),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirpiutang/ld_histori";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vObjdx].init_loading = false;
			setEfekview(!uEfekview);
			//console.log("(Kasirpiutang-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.historiobject||"[]");
				if(vTmpObj.length <= 0) {
					showToast((uBahasaObj.caption_dataempty||"caption_dataempty")+"..");
					return;
				}
				setDatahistoriArr(vTmpObj);
				setShowview(true)
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kasirpiutang-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(uNavigate);
			}
		})
		.catch((error) =>{
			uDatamainArr[vObjdx].init_loading = false;
			setEfekview(!uEfekview);
			console.log("(Kasirpiutang-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaddetil = (_IDTABEL)=>{
		uFuncname = apiLoaddetil.name;
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setDetilDiskon(0);
		setDetilBKartu(0);
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vObjdx].init_loading = true;

		/*//--TESTING_FRONTEND--/
		console.log("(Kasirpiutang-apiLoaddetil) LEWAT ");
		let vTimeout = setTimeout(()=>{
			setShowview(true)
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_piutangid : _IDTABEL.toString(),
			send_tokenauth : uTokenObj.userinit
		});

		const vURLs	= pjson.homepage+"api/api_kasirpiutang/ld_detilitem";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vObjdx].init_loading = false;
			setEfekview(!uEfekview);
			if(output_string.tampil) {
				//console.log("(Kasirpiutang-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
				uDatamainArr[vObjdx].items_arr = JSON.parse(output_string.detilobject||"[]");
				setDetilDiskon(output_string.detildiskon || 0);
				setDetilBKartu(output_string.detilbkartu || 0);
				setShowview(true)
			} else if(output_string.info) {
				showToast(output_string.info);
				setShowview(false);
			} else if(output_string.errors) {
				console.log("(Kasirpiutang-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
				setShowview(false);
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(uNavigate);
			}
		})
		.catch((error) =>{
			uDatamainArr[vObjdx].init_loading = false;
			setEfekview(!uEfekview);
			console.log("(Kasirpiutang-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpanbayar = () => {
		uFuncname = apiSimpanbayar.name;

		const vPiutangID = parseInt(uTabelIDPilih) || 0;
		if(vPiutangID <= 0) return;
		const vIdx 	= UFunc.getObjectIndeks(uDatamainArr,vPiutangID);

		const vJmluang		= parseInt(uDataformObj.nilai_bayar)||0;
		const vBKartu		= parseInt(uDataformObj.b_kartu)||0;

		const vJmlbayar		= vJmluang - vBKartu;
		const vTerbayarnew	= vJmlbayar > (parseInt(uDataformObj.piutang_sisa)||0) 
			? (parseInt(uDataformObj.piutang_sisa)||0) : vJmlbayar;

		const vELbtnsimpan	= uElBtnDlgsimpan;
		vELbtnsimpan && (vELbtnsimpan.disabled=true);
		setDataCetakObj(null);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Kasirpiutang-apiSimpanbayar) vIdx = "+vIdx);
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_piutangid : vPiutangID,
			send_tglbayar : uDataformObj.tgl_bayar || UFunc.DbDate(),
			send_nilaiuang : uDataformObj.nilai_bayar||0,
			send_paymentid : uDataformObj.id_payment_method||0,
			send_bkartu : uDataformObj.b_kartu||0,
			send_ketkartu : uDataformObj.ket_kartu||"",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirpiutang/pr_simpanbayar";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			vELbtnsimpan && (vELbtnsimpan.disabled=false);
			setLoading(false);
			//console.log("(Kasirpiutang-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				setNotanew(output_string.notanew||uNotanew);
				uDatamainArr[vIdx].terbayar = 
					(parseInt(uDatamainArr[vIdx].terbayar)||0) + vTerbayarnew;

				if(output_string.pesankhusus) {
					const vPesan = output_string.pesankhusus;
					showToast(vPesan,"SUKSES");
				}
				setDataCetakObj(JSON.parse(output_string.datanota || "{}"));
				setFinishingBayar(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kasirpiutang-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(uNavigate);
			}
		})
		.catch((error) =>{
			vELbtnsimpan && (vELbtnsimpan.disabled=false);
			setLoading(false);
			console.log("(Kasirpiutang-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiHapuspiutang = (_IDTABEL) => {
		uFuncname		= apiHapuspiutang.name;
		_IDTABEL		= parseInt(_IDTABEL || "0");
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		/*//---TESTING_FRONTEND--/
		console.log("(Kasirpiutang-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_piutangid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirpiutang/pr_hapuspiutang";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("(Kasirpiutang-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
				setShowview(false);
			} else if(output_string.errors) {
				console.log("(Kasirpiutang-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
				setShowview(false);
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(uNavigate);
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Kasirpiutang-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return()=>{
    		uDispatch({type: "set", gInitHeaderAction: {}});
			hdlToggleForm(); hdlToggleLookup(); hdlToggleView();
    		setDataformObj({})
    		setDatamainArr([]);
			setDatahistoriArr([])
			setDatapaymentArr([])
			setLookupDataArr([])
			setDatasetting({});
			setDataidentitas({});
			setFirstload("YA");
    	}
	},[]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{ uElJmldata && initJmldata(); },[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData]);
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		//console.log("(Kasirpiutang - [uPageActive]) vDatapageArr => "+JSON.stringify(vDatapageArr));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]);//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uIsShowForm) {
			initResetDlgform(); return;
		}

		initLoadbayar()
		let vTimeout = setTimeout(() => {
			clearTimeout(vTimeout);
			setElBtnDlgsimpan(document.getElementById("btnDialogSimpan"));

			document.getElementById("inpnilaibayar") 
				&& document.getElementById("inpnilaibayar").focus();
		},550);
	},[uIsShowForm]);
	React.useEffect(()=>{
		if(!uIsShowForm) return;

		const vNetto = parseInt(uDataformObj.piutang_sisa||"0")
			+ parseInt(uDataformObj.b_kartu||"0")
		//console.log("(Kasirpiutang - [uIsShowForm,uDataformObj.b_kartu]) vNetto => "+vNetto)
		uDataformObj.nilai_netto = vNetto;
	},[uIsShowForm,uDataformObj.b_kartu]);
	React.useEffect(()=>{
		const vNilaibayar	= parseInt(uDataformObj.nilai_bayar)||0;
		const vNilainetto	= parseInt(uDataformObj.nilai_netto)||0;

		const vNilaisisa 	= vNilainetto - vNilaibayar;
		const vCaptionsisa	= vNilaisisa <= 0 
			? (uBahasaObj.caption_kembalian||"caption_kembalian")
			: (uBahasaObj.caption_sisapiutang||"caption_sisapiutang")

		uDataformObj.nilai_sisa = (vNilaisisa);
		uDataformObj.caption_sisa = (vCaptionsisa);
		setEfekview(!uEfekview)
	},[uDataformObj.nilai_netto,uDataformObj.nilai_bayar]);
	React.useEffect(()=>{
		if(!uIsLookupShow) 
			{ setLookupDataArr([]); return  }
	},[uIsLookupShow]);
	React.useEffect(()=>{
		if(!uFinishingBayar) return; 
		prosesCetakNota(); 
	},[uFinishingBayar]);
	//---END EFFECT--/

	//console.log("(Kasirpiutang) Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>

	return (
		<>
		<CCard className="classcardbg mb-3">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					if(uKeywordstr.length < 3) {
						return (<>&#8734;</>)
					}//-*/

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData && uKeywordstr.length >= 3) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialoglookup
			show={uIsLookupShow}
			dialogHeader={uDialogHeader}
			inputValue={uInpLookupKwd}
			dataItemArr={uLookupDataArr} 
			onKlikPilihItem={hdlKlikLookuppilih}
			toggle={()=>hdlToggleLookup()}
			id="iddialoglookup"/>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uIsShowview} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleView}
			renderContent={contentDlgview()}/>

		<MyDialogform
			options={{size:"lg"}}
			onSimpan={hdlKlikFormSimpan}
			show={uIsShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}
			renderContent={contentDlgform()}/>

		<iframe id="ifmcontentstoprint" className="d-none">
			{(uDatasetting.url_image_nota||"")!=="" && (
			<CImage src={uDatasetting.url_image_nota} id="idimglogo"/>
			)}
		</iframe>
		</>
	)//>
}
	
export default Kasirpiutang;
