import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CHeader,
  CContainer,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler,
  CTooltip,
  CLink,
  CBadge,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { ContHeaderProfile,ContHeaderSub } from './index'
import { UFunc } from '../helpers/functions'

const pjson = require('../../package.json');
const ContHeader = (props) => {
	const { setToken,setLoading, showToast } = props 

	const uDispatch 	= useDispatch()
	const uNavigate 	= useNavigate()
	const uSidebarshow	= useSelector((state) => state.sidebarShow)
	const uBahasaObj  	= useSelector(state => state.listBahasa)
	const uTokenObj		= JSON.parse(localStorage.getItem("token")||"{}");
	const [uEfekview,setEfekview] = React.useState(uSidebarshow);
	const uActiveroute	= useSelector(state => state.activeRoute)
	const uMasterdatashow	= useSelector((state) => state.masterdataShow);
	const uEOMLabel		= useSelector((state) => state.gEOMLabel) || "";

	//React.useEffect(()=>{},[])

	return (
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid style={{height:64}} className="classheadermain border-bottom">
			<CHeaderToggler onClick={() => {
				uDispatch({ type: 'set', sidebarShow: !uSidebarshow });
				}}
				className="ps-1">
			<CIcon icon="cilMenu" size="lg" />
			</CHeaderToggler>

			<CHeaderBrand className="d-flex d-lg-none me-auto text-normal py-0 classcursorpointer" 
				onClick={()=>uNavigate("/dashboard")}>
				{((uActiveroute||"").toUpperCase() === "DASHBOARD")
					|| ((uBahasaObj["menus_"+(uActiveroute||"")]||"") === "") ? (
					<small className="classtitle d-md-none fw-normal">{pjson.name}</small>
				) : (
					<small className="d-md-none"><b>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}</b></small>
				)}
				<small className="d-none d-md-block"><b>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}</b></small>
			</CHeaderBrand>

			<CHeaderBrand className="d-none d-lg-flex me-auto text-normal classcursorpointer" 
				onClick={()=>uNavigate("/dashboard")}>
				<small><b><small>{(()=>{
					const vTglnowDT	= new Date();
					if(vTglnowDT.getHours() >= 19 || vTglnowDT.getHours() < 3) return "Malam";
					
					if(vTglnowDT.getHours() >= 3 && vTglnowDT.getHours() < 11) return "Pagi";

					if(vTglnowDT.getHours() >= 11 && vTglnowDT.getHours() < 15) return "Siang";

					return "Sore";

				})()}</small>, {UFunc.Usercaptionfilter(uTokenObj.usercaption)}</b>..</small>
			</CHeaderBrand>

			<CHeaderNav className="align-items-center">
				{uEOMLabel !== "" && (
				<>
					<CBadge color="danger" onClick={()=>{
						if(uTokenObj.userhak==="FREEROLE" || uTokenObj==="ADMIN")
							uNavigate("/tutupperiode");
						}} className="classcursorpointer me-2 p-2 px-3 border border-warning d-none d-md-block">
						<span className="classblinking">&middot;&middot; {uEOMLabel} &middot;&middot;</span>
					</CBadge>
					<CTooltip content={uEOMLabel}>
					<CBadge color="danger" className="me-2 p-2 d-md-none">
						<CIcon icon="cilBellExclamation" className="classblinking"/>
					</CBadge>
					</CTooltip>
				</>
				)}
				<ContHeaderProfile {...props}/>
			</CHeaderNav>

			<CHeaderNav className="mx-2 mx-lg-4">
			<CTooltip content="Master Data">
			<CLink className="classcursorpointer d-none d-md-block text-secondary"
				onClick={()=>{
					uDispatch({ type: 'set', masterdataShow: !uMasterdatashow });
					}}>
				<CIcon icon="cilApplicationsSettings"/>
			</CLink>
			</CTooltip>

			<CLink className="classcursorpointer d-md-none text-secondary"
				onClick={()=>{
					uDispatch({ type: 'set', masterdataShow: !uMasterdatashow });
					}}>
				<CIcon icon="cilApplicationsSettings"/>
			</CLink>
			</CHeaderNav>
		</CContainer>

		<ContHeaderSub {...props}/>
		</CHeader>
	)
}

export default ContHeader

	