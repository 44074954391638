import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderBrand,
	CContainer,
	CCard,
	CCardHeader,
	CSubheader,
	CCardBody,
	CFooter,
	CForm,
	CRow,
	CCol,
	CFormInput,
	CFormSelect,
	CLink,
	CTooltip,
	CInputGroup,
	CInputGroupText,
	CButton,
	CListGroup,
	CListGroupItem,
	CBadge,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyButton,
	MyDialoglookup,
	MyDialogform,
	MyProfileDetil,
	MyPage500,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     = require('../../../package.json');
const Konsinyasimain = (props) => {
	const uComponentname	= Konsinyasimain.name;
	const {
		prosesExpired, setLoading, showToast, setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate 		= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uDefaultObj  		= useSelector(state => state.gListDefaultSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	//--DIALOG--/
	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const [uDatamainObj,setDatamainObj]	 	= React.useState({})
	const uDatatabelArr						= uDatamainObj.items_arr || [];
	const [uFormitemObj,setFormitemObj]		= React.useState({});
	const [uTabeleditObj,setTabeleditObj]	= React.useState({editid:"0",editfield:""});
	
	const [uIsDataUpdate,setDataUpdate]		= React.useState(false);
	const [uIsSuppliernew,setSuppliernew]	= React.useState(false);
	const [uDatabarangArr,setDatabarangArr]	= React.useState([]);
	const [uDatasupplierArr,setDatasupplierArr]= React.useState([]);//-*/

	//---DIALOG_FORM--/
	const [uISDlgformshow,setDlgformshow]			= React.useState(false);
	const [uDlgforminputObj,setDlgforminputObj]		= React.useState({});
	const [uBarangjenisArr,setBarangjenisArr]		= React.useState([]);
	/*()	{value:"0",caption:(uBahasaObj.caption_dataempty||"caption_dataempty"),},
	]);//-*/
	//---END DIALOG_FORM--/

	//---DIALOG_LOOKUP--/
	const [uDlglookupjenis,setDlglookupjenis]		= React.useState("");
	const [uDlglookupheader,setDlglookupheader] 	= React.useState("");
	const [uIsDlglookupshow,setDlgLookupshow]		= React.useState(false);
	const [uInpDlglookuptext,setInpDlglookuptext]	= React.useState("");
	const [uDataDlglookupArr,setDataDlglookupArr]	= React.useState([]);
	//---END DIALOG_LOOKUP--/

	const [uIsFormupdate,setFormupdate]		= React.useState(false);
	const [uIsFormprocess,setIsFormprocess]	= React.useState(false);

	//---HANDEL--/
	const hdlKDownDocument=(_EV)=>{
		if(_EV.which === 120) {
			if(uIsFormprocess) return;

			_EV.preventDefault();
			hdlKlikSimpanMain();
		}
	}
	const hdlToggleDlglookup=()=>{ setDlgLookupshow(false); }
	const hdlToggleDlgform = () => { setDlgformshow(false); }
	const hdlKlikDlglookuppilih=(_IDITEM)=>{
		const vIdx = UFunc.getObjectIndeks(uDataDlglookupArr,_IDITEM,"id");
		//console.log("(Konsinyasimain - hdlKlikDlglookuppilih) vIdx =>"+(vIdx))
		if(vIdx < 0) return;

		let vFocusnext;
		if(uDlglookupjenis==="BARANG") {
			vFocusnext	= document.getElementById("inpformitemqty");
			uFormitemObj.itemid = (uDatabarangArr[vIdx].id);
			uFormitemObj.nama 	= (uDatabarangArr[vIdx].caption);
			uFormitemObj.qty 	= (0);
			uFormitemObj.satuan = (uDatabarangArr[vIdx].satuan);
			uFormitemObj.h_jual_sat 	= (uDatabarangArr[vIdx].harga_satuan);
		} else if(uDlglookupjenis === "ITEMKONSI") {
			vFocusnext	= document.getElementById("inpformbarangqty");
			const vNilaiKonsi = Math.round(
				((parseInt(uDefaultObj.persen_konsi)||100) / 100) * 
					parseInt(uDatabarangArr[vIdx].harga_satuan||0)
			);

			uDlgforminputObj.barang_id 		= uDatabarangArr[vIdx].id||0;
			uDlgforminputObj.barang_caption = (uDatabarangArr[vIdx].caption);
			uDlgforminputObj.qty 			= 1;
			uDlgforminputObj.barang_satuan 	= (uDatabarangArr[vIdx].satuan);
			uDlgforminputObj.harga_satuan 	= (uDatabarangArr[vIdx].harga_satuan);
			uDlgforminputObj.nilai_konsi 	= vNilaiKonsi;
		} else if(uDlglookupjenis === "SUPPLIER") {
			//console.log("(Konsinyasimain - hdlKlikDlglookuppilih) uDatasupplierArr => "+JSON.stringify(uDatasupplierArr));
			vFocusnext	= document.getElementById("inpformitemcaption");
			uDatamainObj.id_supplier		= uDatasupplierArr[vIdx].value;
			uDatamainObj.nama_supplier		= uDatasupplierArr[vIdx].caption;
		}

		hdlToggleDlglookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			vFocusnext && vFocusnext.focus();
		},350);
	}
	const hdlKlikDlgformSimpan = () => {
		if(uDlgforminputObj.jenisform === "REGISTERBARANG") {
			if(uDlgforminputObj.barang_nama.trim()==="" 
				|| parseInt(uDlgforminputObj.jenis_id||"0") <= 0) {
				 return;
			}

			initSimpanregister(); return;
		}

		if(uDlgforminputObj.jenisform === "ITEMKONSI") {
			//console.log("(Konsinyasimain - hdlKlikDlgformSimpan) uDlgforminputObj => "+JSON.stringify(uDlgforminputObj));
			if((uDlgforminputObj.barang_caption||"").trim()==="" 
				|| parseInt(uDlgforminputObj.qty||"0") <= 0
				|| parseInt(uDlgforminputObj.barang_id||"0") <= 0
				) { return; }

			initSimpanitemkonsi(); return;
		}
	}
	const hdlKlikBack=async()=>{
		const vProsesBack=()=>{
			uNavigate(-1);
		}
		if(uIsFormupdate) {
			if(await(Konfirm(
				"<strong>"+(uBahasaObj.pesan_formisupdate||"pesan_formisupdate")+"..</strong>"+
				"<BR><BR>"+(uBahasaObj.confirm_kembali||"confirm_kembali")+
				""))) vProsesBack();

			return;
		}
		vProsesBack();
	}
	const hdlKlikSimpanMain=async()=>{
		if(!uIsFormupdate) return;

		//---VALIDASI--/
		let vELfocus	= document.getElementById("inptanggal");
		if(UFunc.isEmpty(uDatamainObj.tgl_konsinyasi)) {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.word_tanggal||"word_tanggal")+" "+uBahasaObj.caption_invalid+"..");
			return;
		}

		const vTanggalDt 	= new Date(uDatamainObj.tgl_konsinyasi);
		const vDateBeyond = UFunc.DateIsBeyond(vTanggalDt);
		if(vDateBeyond) {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.pesan_tglisbeyond||"pesan_tglisbeyond")+"..");
			return;
		}
		const vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
		if(vDateExceededBl) {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.pesan_tglisexpiredyear||"pesan_tglisexpiredyear")+"..");
			return;
		}
		if(!uIsSuppliernew) {
			vELfocus	= document.getElementById("inpsuppliercaption");
			if(parseInt(uDatamainObj.id_supplier||"0") <= 0) {
				vELfocus && vELfocus.focus();
				showToast((uBahasaObj.word_supplier||"word_supplier")+
					" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
				return;
			}
		} else {
			vELfocus	= document.getElementById("inpsupplierbaru");
			if((uDatamainObj.nama_supplier||"").trim() === "") {
				vELfocus && vELfocus.focus();
				showToast((uBahasaObj.word_supplier||"word_supplier")+
					" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+"..");
				return;
			}
		}
		vELfocus	= document.getElementById("inpformitemcaption");
		if(uDatatabelArr.length <= 0){
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.pesan_itemstillempty||"pesan_itemstillempty"));
			return;
		}

		//---CEK_BATCHNO--/
		let vIsBatchNoInvalid	= false;
		let vIsExpDateInvalid	= false;
		uDatatabelArr.forEach((vItem,vKey)=>{
			//console.log("vItem.is_batchno = "+vItem.is_batchno);
			if(vItem.is_batchno==="YA" && (vItem.batchno||"").trim() === "") {
				vIsBatchNoInvalid = true;
				return false;
			}

			if(vItem.expdate) {
				const vExpdateDt 		= new Date(vItem.expdate);
				const vIsExpDateBeyond 	= UFunc.DateIsBeyond(vExpdateDt);
				if(vIsExpDateBeyond) {
					if((vItem.batchno||"") == "") {
						vIsExpDateInvalid = true;
						return false;
					}
				}
			}
		})
		if(vIsBatchNoInvalid){
			showToast((uBahasaObj.pesan_itembatcnoinvalid||"pesan_itembatcnoinvalid"));
			return;
		}
		if(vIsExpDateInvalid) {
			showToast((uBahasaObj.pesan_itemexpdateinvalid||"pesan_itemexpdateinvalid"));
			return;
		}
		//---END CEK_BATCHNO--/
		//---END VALIDASI--/

		const vDateisMundur = UFunc.DateIsMundur(vTanggalDt);
		const vSuppliernama	= uDatamainObj.nama_supplier||"";

		const vHTMLKonfirm	=
		"<div class='row'>"+
		"<div class='col-sm-12 text-center'><strong>"+(uBahasaObj["menus_konsinyasi"]||"").toUpperCase()+"</strong></div>"+
		"<div class='col-sm-12'><HR class='my-2' style='border:none;border-bottom:1px dotted gray;'/></div>"+
		(uDatamainObj.no_nota||""!==""?
		"<div class='col-sm-5'>"+(uBahasaObj.word_nonota||"word_nonota")+"</div>"+
		"<div class='col-sm-7 fw-bolder' align='right'>"+(uDatamainObj.no_nota||""||"").toUpperCase()+"</div>"+
		""
		:""
		)+
		"<div class='col-sm-5 '>"+(uBahasaObj.caption_tgltransaksi||"caption_tgltransaksi")+"</div>"+
		"<div class='col-sm-7  fw-bolder' align='right'>"+UFunc.TglAngka(uDatamainObj.tgl_konsinyasi)+"</div>"+
		"<div class='col-sm-5'>"+(uBahasaObj.word_supplier||"word_supplier")+"</div>"+
		"<div class='col-sm-7 fw-bolder' align='right'>"+vSuppliernama+"</div>"+

		"<div class='col-sm-12'><HR class='my-2' style='border:none;border-bottom:1px dotted gray;'/></div>"+
		"<div class='col-sm-5'>"+(uBahasaObj.caption_jmlitem||"caption_jmlitem")+"</div>"+
		"<div class='col-sm-7 fw-bolder text-end'>"+
			UFunc.formatAngka(uDatatabelArr.length)+" "+(uBahasaObj.word_item||"word_item")+"</div>"+
		"<div class='col-sm-5'>"+(uBahasaObj.caption_totalnetto||"caption_totalnetto")+"</div>"+
		"<div class='col-sm-7 fw-bolder text-end'>Rp"+UFunc.formatAngka(uDatamainObj.total_konsi )+"</div>"+

		(vDateisMundur?
		"<div class='col-sm-12'><HR class='my-2' style='border:none;border-bottom:1px dotted gray;'/></div>"+
		"<div class='col-sm-12 px-5 font-italic text-warning'>"+
			(uBahasaObj.pesan_dateismundur||"pesan_dateismundur")+"</div>"+
		""
		:""
		)+
		"<div class='col-sm-12 p-3 px-4 fw-bolder text-center text-success'>"+(uBahasaObj.confirm_simpan||"confirm_simpan")+"</div>"+
		"</div>"+
		"";

		if(await(Konfirm(vHTMLKonfirm))) apiSimpanmain();
	}
	const hdlKDownSuppliercaption=(_EV)=>{
		uDatamainObj.id_supplier 	= 0;
		if(_EV.keyCode !== 13) return;
		if((uDatamainObj.nama_supplier||"").trim()==="") { return; }

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uDatasupplierArr.filter((vItems)=>
			vItems.caption.toUpperCase().includes((uDatamainObj.nama_supplier||"").toUpperCase()));
		
		if (vArrCariMaster.length === 1) {
			uDatamainObj.id_supplier 	= (vArrCariMaster[0].value);
			uDatamainObj.nama_supplier 	= (vArrCariMaster[0].caption);
			setEfekview(!uEfekview)

			document.getElementById("inpformitemcaption") &&
				document.getElementById("inpformitemcaption").focus();
		} else {
			hdlKlikSupplierlookup();
		}
	}
	const hdlKlikSupplierlookup=()=>{
		setInpDlglookuptext(uDatamainObj.nama_supplier||"");
		uDatamainObj.nama_supplier	= "";

		const vLookupArr	= uDatasupplierArr.map(vItems=>{
			return {
				id: vItems.value,
				caption: vItems.caption,
			}
		})
		setDlglookupjenis("SUPPLIER")
		setDataDlglookupArr(vLookupArr);
		setDlglookupheader(uBahasaObj.word_lookup+": "+uBahasaObj.word_supplier);
		setDlgLookupshow(true);
	}
	const hdlKlikReset=async()=>{
		if(!uIsFormupdate) {
			apiLoadinit();
			return;
		}

		const vKonfirm	=
			"<strong>"+(uBahasaObj.petunjuk_resetform||"petunjuk_resetform")+"</strong>.."+
			"<BR><BR>"+(uBahasaObj.confirm_reset||"confirm_reset")+
		"";
		if(await Konfirm(vKonfirm)) initResetForm();
	}
	const hdlKlikFormitemlookup=()=>{
		setInpDlglookuptext(uFormitemObj.nama||"");
		uFormitemObj.nama	= "";

		setDlglookupjenis("BARANG")
		setDataDlglookupArr(uDatabarangArr);
		setDlglookupheader(uBahasaObj.caption_lookupitem||"caption_lookupitem");
		setDlgLookupshow(true);
	}
	const hdlKlikBarangRegister=()=>{
		uDlgforminputObj.jenisform 	= "REGISTERBARANG";
		uDlgforminputObj.header = ((uBahasaObj.caption_tambahmaster||"caption_tambahmaster")+
			": "+(uBahasaObj.word_barang||"word_barang").toUpperCase());
		uDlgforminputObj.jenis_id = (uBarangjenisArr[0].value);
		setFormitemObj({});
		setDlgformshow(true);
	}
	const hdlKDownformitem=(_EV)=>{
		initResetFormItem()
		if(_EV.keyCode !== 13) return;
		if((uFormitemObj.nama||"").trim()==="") { return; }

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uDatabarangArr.filter((vItems)=>
			vItems.caption.toUpperCase().includes((uFormitemObj.nama||"").toUpperCase()));
		
		if (vArrCariMaster.length === 1) {
			uFormitemObj.itemid =   (vArrCariMaster[0].id);
			uFormitemObj.nama 	= (vArrCariMaster[0].caption);
			uFormitemObj.qty 	= (0);
			uFormitemObj.satuan = (vArrCariMaster[0].satuan);
			uFormitemObj.h_jual_sat 	= (vArrCariMaster[0].harga_satuan);

			document.getElementById("inpformitemqty") &&
				document.getElementById("inpformitemqty").focus();
		} else {
			hdlKlikFormitemlookup();
		}
	}
	const hdlKlikItemTambah=()=>{
		if(parseInt(uFormitemObj.itemid||"0") <= 0) return;
		if(parseInt(uFormitemObj.qty||"0") <= 0) return;

		//---CEK_ITEM_EXISTS--/
		let vIdx = UFunc.getObjectIndeks(uDatatabelArr,(uFormitemObj.itemid||"0"),"itemid");
		if(vIdx >= 0) {
			showToast(uBahasaObj.pesan_itemisexists||"pesan_itemisexists");
			return;
		}
		//---END CEK_ITEM_EXISTS--/

		//---GET_ID_MASTER--/
		let vStatusBatch;
		vIdx = UFunc.getObjectIndeks(uDatabarangArr,(uFormitemObj.itemid||"0"),"id");
		if(vIdx>=0) {
			vStatusBatch = uDatabarangArr[vIdx].is_batchno;
		}
		//---END GET_ID_MASTER--/

		const vItemObj	= {
			itemid 	: (uFormitemObj.itemid||"0"),
			nama 	: uFormitemObj.nama||"",
			is_batchno 	: vStatusBatch||"TIDAK",
			expdate:null,
			batchno:null,
			qty 	: parseInt(uFormitemObj.qty||"0"),
			h_jual_sat : parseInt(uFormitemObj.h_jual_sat||0),
			hpp_sat : parseInt(uFormitemObj.hpp_sat||0)
		}
		uDatamainObj.items_arr = ([vItemObj,...uDatatabelArr]);

		uFormitemObj.nama = ("");
		initResetFormItem();
		document.getElementById("inpformitemcaption").focus();
	}
	const hdlKlikItemTambahmobile = () => {
		uDlgforminputObj.jenisform 	= "ITEMKONSI";
		uDlgforminputObj.header = "Tambah Item Konsinyasi";
		setDlgformshow(true);
	}
	const hdlKlikTabelhapus=async(_KEYITEM)=>{
		_KEYITEM = parseInt(_KEYITEM);if(_KEYITEM < 0) return;

		const vObjdx	= _KEYITEM;//UFunc.getObjectIndeks(uDatatabelArr,_IDITEM,"itemid");
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_KEYITEM);
		setTabelRowSelect(vRowIdx);

		const vConfirm	= 
			(uBahasaObj.caption_hapusitem||"caption_hapusitem")+": <B>"+uDatatabelArr[vObjdx].nama.toUpperCase()+"</B>"+
			"<BR><BR><B>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) initItemHapus(_KEYITEM);
	}
	const hdlKlikTabeledit=(_KEYITEM,_FIELDEDIT)=>{
		setFormitemObj({});

		const vIdx 		= _KEYITEM;//UFunc.getObjectIndeks(uDatatabelArr,_IDITEM,"itemid");
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_KEYITEM);
		setTabelRowSelect(vRowIdx);

		let vElementID = "inptditembatchno";
		let vValue;
		if(_FIELDEDIT.toUpperCase()==="EXPDATE") {
			vElementID = "inptabeleditexpdate";
			if(vIdx >= 0)
				vValue = (uDatatabelArr[vIdx].expdate);
		} else if(_FIELDEDIT.toUpperCase()==="QTY") {
			vElementID = "inptabeleditqty";
			if(vIdx >= 0)
				vValue = (uDatatabelArr[vIdx].qty);
		} else if(_FIELDEDIT.toUpperCase()==="HJUAL") {
			vElementID = "inptabeledithsatuan";
			if(vIdx >= 0)
				vValue = (uDatatabelArr[vIdx].h_jual_sat);
		} else if(_FIELDEDIT.toUpperCase()==="NILAIKONSI") {
			vElementID = "inptabeleditnkonsi";
			if(vIdx >= 0)
				vValue = (uDatatabelArr[vIdx].hpp_sat);
		} else {
			if(vIdx >= 0)
				vValue = (uDatatabelArr[vIdx].batchno);
		}
		setTabeleditObj({editkey:_KEYITEM,editfield:_FIELDEDIT,editvalue:vValue});
		setEfekview(!uEfekview)

		let vTimeout = setTimeout(function(e) {
			clearTimeout(vTimeout);
			document.getElementById(vElementID) 
				&& document.getElementById(vElementID).focus();
		},300);
	}
	const hdlKlikTabeleditproses=(_KEYITEM,_FIELDEDIT)=>{
		//--PROSES_EDITNYA--/
		const vIdx 		= _KEYITEM;//UFunc.getObjectIndeks(uDatatabelArr,_IDITEM,"itemid");
		if(vIdx < 0) 
			{ initItemDefault(); return; }

		if(_FIELDEDIT.toUpperCase()==="EXPDATE") {
			uDatatabelArr[vIdx].expdate 	= uTabeleditObj.editvalue || "";
		} else if(_FIELDEDIT.toUpperCase()==="QTY") {
			const vValueQTY = parseInt(uTabeleditObj.editvalue) || 0;
			if(vValueQTY <= 0) {
				showToast((uBahasaObj.word_qty||"word_qty")+" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
				return;
			}

			uDatatabelArr[vIdx].qty 	= vValueQTY;
		} else if(_FIELDEDIT.toUpperCase()==="HJUAL") {
			const vValueHarga = parseInt(uTabeleditObj.editvalue) || 0;
			if(vValueHarga < 0) {
				showToast((uBahasaObj.caption_hargasatuan)+" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
				return;
			}

			uDatatabelArr[vIdx].h_jual_sat 	= vValueHarga;
		} else if(_FIELDEDIT.toUpperCase()==="NILAIKONSI") {
			const vNilaiKonsi = parseInt(uTabeleditObj.editvalue) || 0;
			if(vNilaiKonsi < 0) {
				showToast((uBahasaObj.caption_nilaikonsi)+" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
				return;
			}

			uDatatabelArr[vIdx].hpp_sat 	= vNilaiKonsi;
		} else {
			const vValue = (uTabeleditObj.editvalue || "").toUpperCase();
			uDatatabelArr[vIdx].batchno 	= vValue;
		}
		//--END PROSES_EDITNYA--/
		initItemDefault();
	}
	const hdlKDownTabeledit=(_EV,_KEYITEM,_FIELDEDIT)=>{
		if(_EV.which !== 27 && _EV.which !== 13) return;

		_EV.preventDefault();
		if(_EV.which === 27) initItemDefault();
		else if(_EV.which === 13) hdlKlikTabeleditproses(_KEYITEM,_FIELDEDIT);
	}
	const hdlKlikBarangmobilelookup = () => {
		setInpDlglookuptext(uDlgforminputObj.barang_caption||"");

		setDlglookupjenis("ITEMKONSI")
		setDataDlglookupArr(uDatabarangArr);
		setDlglookupheader(uBahasaObj.caption_lookupitem||"caption_lookupitem");
		setDlgLookupshow(true);
	}
	const hdlKDownformbarang = (_EV) => {
		uDlgforminputObj.barang_id 		= 0;
		uDlgforminputObj.barang_satuan 	= undefined;
		uDlgforminputObj.qty 			= undefined;
		uDlgforminputObj.harga_satuan 	= undefined;
		uDlgforminputObj.nilai_konsi 	= undefined;

		if(_EV.keyCode !== 13) return;
		if((uDlgforminputObj.barang_caption||"").trim()==="") { return; }

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uDatabarangArr.filter((vItems)=>
			vItems.caption.toUpperCase().includes((uDlgforminputObj.barang_caption||"").toUpperCase()));
		
		if (vArrCariMaster.length === 1) {
			const vNilaiKonsi = Math.round(
				((parseInt(uDefaultObj.persen_konsi)||100) / 100) * 
					parseInt(vArrCariMaster[0].harga_satuan||0)
			);

			uDlgforminputObj.barang_id 		= (vArrCariMaster[0].id);
			uDlgforminputObj.barang_caption = (vArrCariMaster[0].caption);
			uDlgforminputObj.qty 			= 1;
			uDlgforminputObj.barang_satuan 	= (vArrCariMaster[0].satuan);
			uDlgforminputObj.harga_satuan 	= (vArrCariMaster[0].harga_satuan);
			uDlgforminputObj.nilai_konsi	= vNilaiKonsi;

			document.getElementById("inpformbarangqty") &&
				document.getElementById("inpformbarangqty").focus();

			setEfekview(!uEfekview);
			return;
		}

		hdlKlikBarangmobilelookup();
	}
	const hdlKlikTabeleditmobile = (_KEYITEM) => {
		uDlgforminputObj.jenisform 	= "ITEMKONSI";

		const vIdx 	= parseInt(_KEYITEM);
		uDlgforminputObj.header 		= "Edit Item: "+uDatatabelArr[vIdx].nama;
		uDlgforminputObj.editkey 		= vIdx;
		uDlgforminputObj.barang_caption = uDatatabelArr[vIdx].nama;
		uDlgforminputObj.barang_id 		= uDatatabelArr[vIdx].itemid;
		uDlgforminputObj.qty 			= uDatatabelArr[vIdx].qty;
		uDlgforminputObj.harga_satuan 	= uDatatabelArr[vIdx].h_jual_sat;
		uDlgforminputObj.nilai_konsi 	= uDatatabelArr[vIdx].hpp_sat;

		setDlgformshow(true);
	}
	//---END HANDEL--/

	//--INIT--/
	const initItemHapus=(_KEYITEM)=>{
		_KEYITEM = parseInt(_KEYITEM); if(_KEYITEM < 0) return;

		const vItemArr	= uDatatabelArr;
		uDatamainObj.items_arr = ([]);
		const vObjdx	= _KEYITEM;//UFunc.getObjectIndeks(vItemArr,_IDITEM,"itemid");
		if(vObjdx >= 0) vItemArr.splice(vObjdx,1);
		uDatamainObj.items_arr = (vItemArr);
		setTabelRowSelect(-1);
	}
	const initItemDefault=()=>{
		setTabeleditObj({}); 
		setEfekview(!uEfekview);
	}
	const initResetForm=()=>{
		setDatamainObj({})
		setFormitemObj({});
		setTabeleditObj({});

		setEfekview(!uEfekview)
	}
	const initResetFormItem=()=>{
		uFormitemObj.itemid = ("0");
		uFormitemObj.qty 	= (0);
		uFormitemObj.h_jual_sat 	= (0);
		uFormitemObj.satuan = ("PCS");
		setTabelRowSelect(-1);

		setEfekview(!uEfekview);
	}
	const setFormmainvalue = (_NAMAFIELD,_VALUE) => {
		uDatamainObj[_NAMAFIELD] = _VALUE;
		setEfekview(!uEfekview);
	}
	const setFormitemvalue = (_NAMAFIELD,_VALUE) => {
		uFormitemObj[_NAMAFIELD] = _VALUE;
		setEfekview(!uEfekview);
	}
	const initSimpanregister = () => {
		const vBarangcaption = (uDlgforminputObj.barang_nama||"").trim() +
			((uDlgforminputObj.barang_kode||"").trim()!==""?
				" - "+(uDlgforminputObj.barang_kode||"").toUpperCase() : "")+
		"";
		const vItemObj	= {
			itemid : 0,
			nama : vBarangcaption,
			nama_tabel : uDlgforminputObj.barang_nama||"",
			kode_barang: uDlgforminputObj.barang_kode||"",
			jenis_id : uDlgforminputObj.jenis_id||uBarangjenisArr[0].value,
			is_batchno 	: "TIDAK",
			expdate :null,
			batchno :null,
			qty : 1,
			h_jual_sat : 0,
			hpp_sat : 0
		}
		uDatamainObj.items_arr = ([vItemObj,...uDatatabelArr]);
		hdlToggleDlgform();
	}
	const initSimpanitemkonsi = () => {
		if(parseInt(uDlgforminputObj.barang_id||"0") <= 0) return;
		if(parseInt(uDlgforminputObj.qty||"0") <= 0) return;

		console.log("(Konsinyasimain - initSimpanitemkonsi) uDlgforminputObj.editkey => "+uDlgforminputObj.editkey);
		const vEditkey	= parseInt(uDlgforminputObj.editkey);
		console.log("(Konsinyasimain - initSimpanitemkonsi) vEditkey => "+vEditkey);

		//---CEK_ITEM_EXISTS--/
		let vIdx = UFunc.getObjectIndeks(uDatatabelArr,(uDlgforminputObj.barang_id||"0"),"itemid");
		if(vIdx >= 0 && vEditkey !== vIdx) {
			showToast(uBahasaObj.pesan_itemisexists||"pesan_itemisexists");
			return;
		}
		//---END CEK_ITEM_EXISTS--/

		//---GET_ID_MASTER--/
		let vStatusBatch;
		vIdx = UFunc.getObjectIndeks(uDatabarangArr,(uDlgforminputObj.barang_id||"0"),"id");
		if(vIdx>=0) {
			vStatusBatch = uDatabarangArr[vIdx].is_batchno;
		}
		//---END GET_ID_MASTER--/

		const vItemObj	= {
			itemid : (uDlgforminputObj.barang_id||"0"),
			nama : uDlgforminputObj.barang_caption||"",
			is_batchno 	: vStatusBatch,
			expdate : uDlgforminputObj.expdate,
			batchno : uDlgforminputObj.batchno,
			qty 	: parseInt(uDlgforminputObj.qty||"0"),
			h_jual_sat : parseFloat(uDlgforminputObj.harga_satuan||0),
			hpp_sat : parseFloat(uDlgforminputObj.nilai_konsi||0)
		}
		if(vEditkey >= 0) {
			(uDatamainObj.items_arr||[])[vEditkey] = vItemObj;

			hdlToggleDlgform();
			return;
		}

		uDatamainObj.items_arr = ([vItemObj,...uDatatabelArr]);
		setDlgforminputObj({
			header: uDlgforminputObj.header,
			jenisform: uDlgforminputObj.jenisform,
		});
		showToast(uBahasaObj.pesan_sukses,"SUKSES");
	}

	const contentMain = () => {
		if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)

		return (
		<>
		<CRow className="align-items-stretch">
		<CCol md="6" className="mb-3">
			<CCard className="classcardbg h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
				{uBahasaObj.word_nota}
			</CCardHeader>
			<CCardBody className="">{contentNota()}</CCardBody>
			</CCard>
		</CCol>

		<CCol className="mb-3">
			<CCard className="classcardbg h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
				{uBahasaObj.word_supplier}
			</CCardHeader>
			<CCardBody className="">{contentSupplier()}</CCardBody>
			</CCard>
		</CCol>
		</CRow>

		<CCard className="classcardbg mb-3">{contentTabelitem()}</CCard>

		<CRow className="align-items-stretch">
		<CCol md="6" className="d-none d-md-block"/>
		<CCol className="mb-3">
			<CCard className="classcardbg h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
				{uBahasaObj.caption_totalnetto}
			</CCardHeader>
			<CCardBody>{contentFooter()}</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</>
		)
	}
	const contentNota = () => {
		if(uHTMLError500) return "";

		return (
		<>
		<CRow className="my-2">
		<CCol xs="4" className="text-info">{uBahasaObj.caption_tgltransaksi}</CCol>
		<CCol>
			<CFormInput type="date" size="sm"
				value={uDatamainObj.tgl_konsinyasi||UFunc.DbDate()}
				onChange={(e)=>setFormmainvalue("tgl_konsinyasi",e.target.value)}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"inpnonota")}
				id="inptanggal"/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4">{uBahasaObj.word_nonota}</CCol>
		<CCol className="">
			<CFormInput size="sm"
				maxLength={15}
				className="text-uppercase"
				value={uDatamainObj.no_nota||""}
				onChange={(e)=>setFormmainvalue("no_nota",e.target.value)}
				id="inpnonota"/>
		</CCol>
		</CRow>
		</>
		)
	}
	const contentSupplier=()=>{
		if(uHTMLError500) return "";

		return (
		<CRow className="my-2">
			<CCol xs="12" lg="3" className="text-info">
				{(!uIsSuppliernew) ? (
				<span className="text-info">{uBahasaObj.word_supplier||"word_supplier"}</span>
				) : (
				<span className="text-danger">
					{uBahasaObj.word_supplier||"word_supplier"}
					{" "}
					{uBahasaObj.word_baru||"word_baru"}
				</span>
				)}
			</CCol>
			<CCol>
			{(!uIsSuppliernew) ? (
			<>
				<CInputGroup size="sm">
					<CFormInput size="sm"
						placeholder="--Masukkan Keyword SUPPLIER.."
						value={uDatamainObj.nama_supplier||""}
						onChange={(e)=>setFormmainvalue("nama_supplier",e.target.value)}
						onKeyDown={(e)=>hdlKDownSuppliercaption(e)}
						id="inpsuppliercaption"/>
					<CButton size="sm" color="dark" 
						onClick={()=>hdlKlikSupplierlookup()}
						id="btnsupplierlookup">
					<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
					<CTooltip content="Toggle Supplier Baru">
					<CButton size="sm" color="dark" 
						className="border-start border-start-white"
						onClick={()=>setSuppliernew(true)}
						id="btnsupplierlamatoggle">
					<CIcon icon="cibAddthis"/>
					</CButton>
					</CTooltip>
				</CInputGroup>
			</>
			) : (
			<>
				<CInputGroup size="sm">
					<CFormInput size="sm" maxLength={100}
						value={uDatamainObj.nama_supplier||""}
						onChange={(e)=>setFormmainvalue("nama_supplier",e.target.value)}
						id="inpsupplierbaru"/>
				<CInputGroupText className="p-0 bg-danger">
					<CTooltip content="Toggle Supplier LAMA">
					<CButton size="sm" color="danger"
						onClick={()=>setSuppliernew(false)}
						id="btnsupplierbarutoggle">
					<CIcon icon="cilTouchApp"/>
					</CButton>
					</CTooltip>
				</CInputGroupText>
				</CInputGroup>
			</>
			)}
			</CCol>
		</CRow>
		)
	}
	const contentTabelitem=()=>{
		if(uHTMLError500) return "";

		return (
		<>
		<CCardHeader className="d-flex justify-content-between py-2">
			<div className="d-none d-md-flex" style={{width:"85%"}}>
				<div style={{minWidth:300,width:"40%"}} className="pe-1">
				<CInputGroup size="sm">
				<CFormInput size="sm"
					value={uFormitemObj.nama||""  }
					onKeyDown={(e)=>hdlKDownformitem(e)}
					onChange={(e)=>{setFormitemvalue("nama",e.target.value);}}
					id="inpformitemcaption"/>
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikFormitemlookup()} 
					id="btnformitemlookup">
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>

				<CTooltip content={(uBahasaObj.caption_tambahmaster||"caption_tambahmaster")+": "+(uBahasaObj.word_barang||"word_barang").toUpperCase()}>
				<CButton size="sm" color="dark"
					className="border-start border-start-white"
					onClick={()=>hdlKlikBarangRegister()} 
					id="btnformitemregister">
					<CIcon icon="cibAddthis"/>
				</CButton>
				</CTooltip>
				</CInputGroup>

				<div className="classpetunjuk">
					<small>{uBahasaObj.caption_namabarang||"caption_namabarang"}</small>
				</div>
			</div>

			<div style={{minWidth:100,width:"10%"}} className="pe-1">
				<CInputGroup size="sm">
					<CFormInput type="number"
						value={uFormitemObj.qty||"0"}
						size="sm"
						className="text-end"
						onChange={(e)=>setFormitemvalue("qty",e.target.value)}
						onFocus={(e)=>e.target.select()}
					id="inpformitemqty"/>
				<CInputGroupText className="fw-bolder">
					{(uFormitemObj.satuan||"PCS"||"PCS").toUpperCase()}
				</CInputGroupText>
				</CInputGroup>

				<div className="classpetunjuk">
					<small>{uBahasaObj.word_qty||"QTY"}</small>
				</div>
			</div>

			<div style={{minWidth:125,width:"18%"}} className="pe-1">
				<NumberFormat 
					displayType={'text'} 
					value={uFormitemObj.h_jual_sat||"0"}
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => setFormitemvalue("h_jual_sat",values.value)}
					renderText={values => (
						<CFormInput 
							value={values}
							size="sm"
							className="text-end"
							onChange={(e)=> setFormitemvalue("h_jual_sat",e.target.value)}
							onFocus={(e)=>e.target.select()}
							id="inpitemh_jual_sat"/>
					)} 
					prefix={isMobile?"":"Rp"}/>

				<div className="classpetunjuk">
					<small>{uBahasaObj.caption_hargajual||"caption_hargajual"}</small>
				</div>
			</div>

			<div className="pe-1">
				<CButton 
					size="sm" color="secondary" 
					onClick={hdlKlikItemTambah}
					className="classfontsmaller">
					<CIcon icon="cibAddthis" className="" /> 
				</CButton>
			</div>
			</div>

			<div className="d-md-none">
				<CButton size="sm" color="secondary"
					onClick={()=>hdlKlikItemTambahmobile()}
					id="btnitemtambahmobile">
				<CIcon icon="cibAddthis" className="me-2"/>Tambah Item
				</CButton>
			</div>

			<div className="text-muted classfontsmaller">
				<small>{uBahasaObj.caption_itembarang||"caption_itembarang"}</small>
			</div>
		</CCardHeader>

		<CCardBody className="px-0">
			<table className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead className="text-center">
				<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
				<th width="5%" className="px-1"><small>No</small></th>
				<th className="px-1">
				<small>
					<CRow className="mx-0">
					<CCol md="3" className="text-start">{uBahasaObj.caption_namabarang||"caption_namabarang"}</CCol>
					
					<CCol md="3" className="d-flex justify-content-between">
						<div className="w-50">{uBahasaObj.caption_batchno||"caption_batchno"}</div>
						<div className="w-50">{uBahasaObj.caption_expdate||"caption_expdate"}</div>
					</CCol>
					
					<CCol md="1" className="">{uBahasaObj.word_qty||"QTY"}</CCol>
					
					<CCol md="3" className="d-flex justify-content-between">
						<div className="w-50">{uBahasaObj.caption_hargajual||"caption_hargajual"} (Rp)</div>
						<div className="w-50">{uBahasaObj.caption_nilaikonsi||"caption_nilaikonsi"} (Rp)</div>
					</CCol>
					
					<CCol md="2" className="">{uBahasaObj.word_total||"word_total"}(Rp)</CCol>
					</CRow>
				</small>
				</th>
				</tr>
			</thead>

			<tbody>
			{uDatatabelArr.map((vItems,vKeys)=>{
				const {
					itemid,nama,expdate,batchno,qty,h_jual_sat,hpp_sat,
					is_batchno
				} = vItems;

				const vCaptionUpper		= (nama||"").toUpperCase();
				const vCaptionExpDate 	= (expdate||"")==="" 
					? (<CIcon icon="cilEthernet"/>)
					: UFunc.renderHTML("<small>"+UFunc.TglAngka(expdate||"")+"</small>");
				const vCaptionBatchNo 	= (batchno||"")==="" 
					? (<CIcon icon="cilEthernet"/>)
					: UFunc.renderHTML("<small className='text-uppercase'>"+(batchno||"")+"</small>");

				const vEditkey		= uTabeleditObj.editkey;// || -1;
				const vEditField	= uTabeleditObj.editfield || "";

				return (
				<tr id={"idtr"+vKeys} 
					className={"align-top text-end"+(uTabelRowSelect===(vKeys)?" classrowselect":"")}
					key={vKeys}>
				<td className="px-1 pe-0">{vKeys+1}.</td>
				<td className="px-1">
					<CRow className="mx-1">
					<CCol md="3" className="px-1 text-start d-flex justify-content-between">
						<div>
						<span className="text-info d-none d-lg-inline">{(nama||"")}</span>
						<small className="text-info d-lg-none">{(nama||"")}</small>

						{((is_batchno||"TIDAK")==="YA") && (
						<CBadge color="danger" shape="pill" className="ms-1">
							<small>{uBahasaObj.caption_batchno||"caption_batchno"}</small>
						</CBadge>
						)}
						</div>

						<CTooltip content={(uBahasaObj.caption_hapusitem||"caption_hapusitem")+": "+vCaptionUpper}>
						<CLink 
							className="d-none d-md-block classcursorpointer"
							onClick={()=>hdlKlikTabelhapus(vKeys)}>
							<CIcon className="classikontabel classikonhapus" height={25}/>
						</CLink>
						</CTooltip>

						<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm" className="p-0"
							color="transparent">
							<CIcon icon="cilOptions" className="classikoncoreui" height={23}/>
						</CDropdownToggle>
						<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikTabeleditmobile(vKeys)}>
							<CIcon className="classikonedit ms-0 me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_edititem || "Edit Item")}
						</CDropdownItem>
						<CDropdownItem onClick={()=>hdlKlikTabelhapus(vKeys)}>
							<CIcon className="classikonhapus ms-0 me-2 align-middle" height={25}/>
							{uBahasaObj.caption_hapusitem||"Hapus Item"}
						</CDropdownItem>
						</CDropdownMenu>
						</CDropdown>
					</CCol>

					<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

					<CCol md="3" className="p-0 text-md-center d-none d-md-flex justify-content-between classfontsmaller">
						<div className="w-50 px-1">
						{(vEditkey===vKeys && vEditField==="BATCH") ? (
						<CInputGroup>
							<CFormInput size="sm" maxLength={15}
								value={uTabeleditObj.editvalue||""}
								onChange={(e)=>{uTabeleditObj.editvalue = (e.target.value);setEfekview(!uEfekview)}}
								onKeyDown={(e)=>hdlKDownTabeledit(e,vKeys,vEditField)}
								className="text-uppercase"
								id={"inptditembatchno"}/>
							<CInputGroupText className="p-0 bg-dark">
								<CButton size="sm"
									color="dark"
									onClick={()=>hdlKlikTabeleditproses(vKeys,vEditField)} 
									id="btntabelitembatch">
								<CIcon icon="cilCheckAlt"/>
								</CButton>
							</CInputGroupText>
						</CInputGroup>
						):(
						<CTooltip content="--Klik Untuk Edit..">
						<CLink 
							onClick={()=>hdlKlikTabeledit(vKeys,"BATCH")}
							className="classcursorpointer text-dark text-decoration-none">
							{vCaptionBatchNo}
						</CLink>
						</CTooltip>
						)}
						</div>

						<div className="px-1 w-50">
						{(vEditkey===vKeys && vEditField==="EXPDATE") ? (
						<CInputGroup size="sm">
							<CFormInput size="sm" type="date"
								value={uTabeleditObj.editvalue||""}
								onChange={(e)=>{uTabeleditObj.editvalue = (e.target.value);setEfekview(!uEfekview)}}
								onKeyDown={(e)=>hdlKDownTabeledit(e,vKeys,"EXPDATE")}
								id="inptabeleditexpdate"/>
							<CInputGroupText className="p-0 bg-dark">
								<CButton size="sm"
									color="dark"
									onClick={()=>hdlKlikTabeleditproses(vKeys,"EXPDATE")} 
									id="btntabelitemexpdate">
								<CIcon icon="cilCheckAlt"/>
								</CButton>
							</CInputGroupText>
						</CInputGroup>
						) : (
						<CTooltip content="--Klik Untuk Edit..">
						<CLink 
							onClick={()=>hdlKlikTabeledit(vKeys,"EXPDATE")} 
							className="classcursorpointer text-dark text-decoration-none">
							{vCaptionExpDate}
						</CLink>
						</CTooltip>
						)}
						</div>
					</CCol>

					<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_batchno}</CCol>
					<CCol xs="8" className="px-1 d-md-none">{batchno || "-"}</CCol>

					<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_expdate}</CCol>
					<CCol xs="8" className="px-1 d-md-none">
						{(expdate||"")==="" ? "-" : UFunc.TglAngka(expdate)}
					</CCol>

					<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

					<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_qty}</CCol>
					<CCol xs="8" md="1" className="px-1 px-md-0 text-md-center fw-bolder">
						{(vEditkey===vKeys && vEditField==="QTY") ? (
						<CInputGroup size="sm">
							<CFormInput size="sm" type="number"
								step="1" min="1"
								className="text-end"
								value={uTabeleditObj.editvalue||"0"}
								onChange={(e)=>{uTabeleditObj.editvalue = (e.target.value);setEfekview(!uEfekview)}}
								onKeyDown={(e)=>hdlKDownTabeledit(e,vKeys,"QTY")}
								onFocus={(e)=>e.target.select()}
								id={"inptabeleditqty"}/>
							<CInputGroupText className="p-0 bg-dark">
								<CButton size="sm"
									color="dark"
									onClick={()=>hdlKlikTabeleditproses(vKeys,"QTY")} 
									id="btntabelitemqty">
								<CIcon icon="cilCheckAlt"/>
								</CButton>
							</CInputGroupText>
						</CInputGroup>
						) : (
						<CTooltip content="--Klik Untuk Edit..">
						<CLink 
							onClick={()=>hdlKlikTabeledit(vKeys,"QTY")} 
							className="classcursorpointer text-dark text-decoration-none d-none d-md-block">
							<big>{UFunc.formatAngka(qty||0)}</big>
						</CLink>
						</CTooltip>
						)}

						<span className="d-md-none">{UFunc.formatAngka(qty||0)}</span>
					</CCol>

					<CCol md="3" className="px-0 d-none d-md-flex justify-content-between classfontsmaller">
						<div className="px-1 w-50">
						{(vEditkey===vKeys && vEditField==="HJUAL") ? (
						<CInputGroup>
							<NumberFormat 
								value={uTabeleditObj.editvalue||"0"}
								displayType={"text"} 
								thousandSeparator={"."} 
								decimalSeparator={","}
								onValueChange={(values) => {uTabeleditObj.editvalue = (values.value)}}
								renderText={values => (
									<CFormInput
										value={values}
										onChange={(e)=>{uTabeleditObj.editvalue = (e.target.value);setEfekview(!uEfekview)}}
										onKeyDown={(e)=>hdlKDownTabeledit(e,vKeys,"HJUAL")}
										size="sm"
										className="text-end"
										onFocus={(e)=>e.target.select()}
									id={"inptabeledithsatuan"}/>
								)}
								prefix={""}/>
							<CInputGroupText className="p-0 bg-dark">
								<CButton size="sm"
									color="dark"
									onClick={()=>hdlKlikTabeleditproses(vKeys,"HJUAL")} 
									id="btntabelitemhsatuan">
								<CIcon icon="cilCheckAlt"/>
								</CButton>
							</CInputGroupText>
						</CInputGroup>
						) : (
						<CTooltip content="--Klik Untuk Edit..">
						<CLink 
							onClick={()=>hdlKlikTabeledit(vKeys,"HJUAL")} 
							className="classcursorpointer text-dark text-decoration-none">
							{UFunc.formatAngka(h_jual_sat||0)}
						</CLink>
						</CTooltip>
						)}
						</div>

						<div className="px-1 w-50">
						{(vEditkey===vKeys && vEditField==="NILAIKONSI") ? (
						<CInputGroup>
							<NumberFormat 
								value={uTabeleditObj.editvalue||"0"}
								displayType={"text"} 
								thousandSeparator={"."} 
								decimalSeparator={","}
								onValueChange={(values) => {uTabeleditObj.editvalue = (values.value)}}
								renderText={values => (
									<CFormInput size="sm"
										value={values}
										onChange={(e)=>{uTabeleditObj.editvalue = (e.target.value);setEfekview(!uEfekview)}}
										onKeyDown={(e)=>hdlKDownTabeledit(e,vKeys,"NILAIKONSI")}
										className="text-end"
										onFocus={(e)=>e.target.select()}
									id={"inptabeleditnkonsi"}/>
								)}
								prefix={""}/>
							<CInputGroupText className="p-0 bg-dark">
								<CButton size="sm"
									color="dark"
									onClick={()=>hdlKlikTabeleditproses(vKeys,"NILAIKONSI")} 
									id="btntabelitemnkonsi">
								<CIcon icon="cilCheckAlt"/>
								</CButton>
							</CInputGroupText>
						</CInputGroup>
						) : (
						<CTooltip content="--Klik Untuk Edit..">
						<CLink 
							onClick={()=>hdlKlikTabeledit(vKeys,"NILAIKONSI")} 
							className="classcursorpointer text-success text-decoration-none">
							{UFunc.formatAngka(hpp_sat||0)}
						</CLink>
						</CTooltip>
						)}
						</div>
					</CCol>

					<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_hargajual}</CCol>
					<CCol xs="8" className="px-1 d-md-none">{UFunc.formatAngka(h_jual_sat)}</CCol>

					<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_hargasatuan}</CCol>
					<CCol xs="8" className="px-1 d-md-none text-success">{UFunc.formatAngka(hpp_sat)}</CCol>

					<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

					<CCol xs="4" className="px-1 text-start d-md-none">Total HPP (Rp)</CCol>
					<CCol xs="8" md="2" className="px-1 text-primary">{UFunc.formatAngka(
						(parseInt(hpp_sat)||0) * (parseInt(qty)||0)
					)}
					</CCol>
					</CRow>
				</td>
				</tr>
				)
			})}
			</tbody>
			</table>
		</CCardBody>
		</>
		)
	}
	const contentFooter=()=>{
		return (
		<>
		<CRow className="">
		<CCol xs="4
		">{uBahasaObj.caption_totalnetto||"caption_totalnetto"}</CCol>
		<CCol className="text-end fw-bolder">
			<big className="classfontlarger text-primary">Rp {UFunc.formatAngka(uDatamainObj.total_konsi )}</big>
		</CCol>
		</CRow>
		</>
		)
	}

	const contentDlgform = () => {
		if(!uISDlgformshow) return "";

		if(uDlgforminputObj.jenisform === "REGISTERBARANG") return contentRegisterbarang();
		if(uDlgforminputObj.jenisform === "ITEMKONSI") return contentItemmobile();
		return (<></>);
	}
	const contentRegisterbarang = () => {
		if(uDlgforminputObj.jenisform !== "REGISTERBARANG") return "";

		return (
			<CForm>
			<CRow className="my-2">
				<CCol xs="12">{uBahasaObj.caption_namabarang||"caption_namabarang"}</CCol>
				<CCol>
					<CFormInput maxLength="50"
						size="sm"
						className="text-capitalize"
						value={uDlgforminputObj.barang_nama||""}
						onChange={(e)=>{uDlgforminputObj.barang_nama = (e.target.value); setEfekview(!uEfekview)}}
						id="inpformbarangnama"/>
				</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>
			<div className="mb-3 pb-3 classborderbottom classpetunjuk">
			*) Register Barang Baru, Akan Disimpan setelah Input Transaksi Konsinyasi Barang TERSIMPAN
			</div>  

			<CRow className="my-2">
				<CCol xs="4">{uBahasaObj.caption_kodebarang||"caption_kodebarang"}</CCol>
				<CCol>
					<CFormInput maxLength="15"
						size="sm"
						className="text-uppercase"
						value={uDlgforminputObj.barang_kode||""}
						onChange={(e)=>{uDlgforminputObj.barang_kode = (e.target.value);setEfekview(!uEfekview)}}
						id="inpformbarangkode"/>
				</CCol>
			</CRow>
			<CRow className="my-2">
				<CCol xs="4">{uBahasaObj.caption_jenisbarang||"caption_jenisbarang"}</CCol>
				<CCol>
					<CFormSelect
						size="sm"
						value={uDlgforminputObj.jenis_id||"0"}
						onChange={(e)=>{uDlgforminputObj.jenis_id = (e.target.value);setEfekview(!uEfekview)}}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
						id="inpformbarangjenis">
					{uBarangjenisArr.map((vItems,vKeys)=>{
						return (
						<option value={vItems.value}
							key={vKeys}>
							{vItems.caption}</option>
						)
					})}
					</CFormSelect>
				</CCol>
			</CRow>
			</CForm>
		);
	}
	const contentItemmobile = () => {
		if(uDlgforminputObj.jenisform !== "ITEMKONSI") return "";

		return (
			<CForm>
			<CRow className="my-2">
			<CCol xs="12">{uBahasaObj.caption_namabarang}</CCol>
			<CCol>
				<CInputGroup size="sm">
				<CFormInput size="sm"
					value={uDlgforminputObj.barang_caption||""}
					onChange={(e)=>{uDlgforminputObj.barang_caption=e.target.value;setEfekview(!uEfekview)}}
					onKeyDown={(e)=>hdlKDownformbarang(e)}
					id="inpformbarangcaption"/>
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikBarangmobilelookup()}
					id="btnformbaranglookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol xs="4">Batch No</CCol>
			<CCol>
				<CFormInput size="sm"
					className="text-uppercase"
					value={uDlgforminputObj.batchno||""}
					onChange={(e)=>{uDlgforminputObj.batchno=e.target.value;setEfekview(!uEfekview)}}
					id="inpformbatchno"/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4">Exp.Date</CCol>
			<CCol>
				<CFormInput size="sm" type="date"
					value={uDlgforminputObj.expdate||""}
					onChange={(e)=>{uDlgforminputObj.expdate=e.target.value;setEfekview(!uEfekview)}}
					id="inpformexpdate"/>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol xs="4">{uBahasaObj.word_qty}</CCol>
			<CCol xs="5">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number"
					value={uDlgforminputObj.qty||0}
					onChange={(e)=>{uDlgforminputObj.qty=e.target.value;setEfekview(!uEfekview)}}
					onFocus={(e)=>e.target.select()}
					className="text-end"
					id="inpformbarangqty"/>
				<CInputGroupText className="fw-bolder">{uDlgforminputObj.barang_satuan||"UNIT"}</CInputGroupText>
				</CInputGroup>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol xs="4">{uBahasaObj.caption_hargajual} (Rp)</CCol>
			<CCol>
				<NumberFormat 
					displayType={"text"} 
					value={uDlgforminputObj.harga_satuan||0}
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => uDlgforminputObj.harga_satuan = values.value}
					renderText={values => (
						<CFormInput 
							value={values}
							size="sm"
							className="text-end"
							onChange={(e)=>{uDlgforminputObj.harga_satuan=e.target.value;setEfekview(!uEfekview);}}
							onFocus={(e)=>e.target.select()}
							id="inpformhargajual"/>
					)} 
					prefix={isMobile?"":"Rp"}/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-success">{uBahasaObj.caption_nilaikonsi} (Rp)</CCol>
			<CCol>
				<NumberFormat 
					displayType={'text'} 
					value={uDlgforminputObj.nilai_konsi||0}
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => uDlgforminputObj.nilai_konsi = values.value}
					renderText={values => (
						<CFormInput 
							value={values}
							size="sm"
							className="text-end"
							onChange={(e)=>{uDlgforminputObj.nilai_konsi=e.target.value;setEfekview(!uEfekview);}}
							onFocus={(e)=>e.target.select()}
							onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
							id="inpformnilaikonsi"/>
					)} 
					prefix={isMobile?"":"Rp"}/>
			</CCol>
			</CRow>
			</CForm>
		)
	}
	//--END INIT--/

	//--API--/
	const apiLoadinit=()=>{
		uFuncname = apiLoadinit.name;
		setHTMLError500();

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_konsinyasi/ld_init";

		setLoading(true);
		setIsFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false);return response.json();}})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setIsFormprocess(false);
				let vTmpObj = [];
				if(output_string.supplierobject) {
					vTmpObj = JSON.parse(output_string.supplierobject);
					setDatasupplierArr(vTmpObj);
				}
				if(output_string.itemobject) {
					vTmpObj = JSON.parse(output_string.itemobject);
					setDatabarangArr(vTmpObj);
				}
				if(output_string.barangjenisobject) {
					vTmpObj = JSON.parse(output_string.barangjenisobject);
					setBarangjenisArr(vTmpObj);
				}
			} else if(output_string.info) {
				setIsFormprocess(false);
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Konsinyasimain-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg  = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setIsFormprocess(true);
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Konsinyasimain-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiSimpanmain=()=>{
		uFuncname = apiSimpanmain.name;

		setLoading(true); setIsFormprocess(true);

		const vDataSendStr	= JSON.stringify(uDatamainObj);
		const vDATAS	= JSON.stringify({
			send_datalist : vDataSendStr,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_konsinyasi/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			setIsFormprocess(false);

			if(output_string.sukses) {
				let vPesan	= (uBahasaObj.pesan_sukses || "pesan_sukses");
				if(output_string.pesankhusus) vPesan = output_string.pesankhusus;
				showToast(vPesan,"SUKSES");
				initResetForm();

				document.getElementById("inptanggal").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Konsinyasimain-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setIsFormprocess(true);
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			setIsFormprocess(false);
			console.log("(Konsinyasimain-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	//---EFFECT---/
	React.useEffect(() => {
		document.addEventListener("keydown",hdlKDownDocument);

		return function cleanup() {
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	});
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});

		apiLoadinit();

		return() => {
			hdlToggleDlglookup(); hdlToggleDlgform();
			setDatamainObj({});
			setFormitemObj({});
			setDataDlglookupArr([]);
			setDatabarangArr([]);
			setDatasupplierArr([]);
			setBarangjenisArr([]);//-*/
		}
	},[]);
	React.useEffect(()=>{
		if(!uISDlgformshow) {
			setDlgforminputObj({});
			return;
		}

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			let vELfocus
			if(uDlgforminputObj.jenisform==="REGISTERBARANG")
				vELfocus = document.getElementById("inpformbarangnama");
			else if(uDlgforminputObj.jenisform==="ITEMKONSI")
				vELfocus = document.getElementById("inpformbarangcaption");

			if(!isMobile) vELfocus && vELfocus.focus();
		},300);
	},[uISDlgformshow]);
	React.useEffect(()=>{
		if(!uIsDlglookupshow) {
			setDlglookupjenis("");
			setDataDlglookupArr([]); return;
		}
	},[uIsDlglookupshow]);
	React.useEffect(()=>{
		uDatamainObj.nama_supplier 	= "";
		if(uIsSuppliernew) {
			uDatamainObj.id_supplier = 0;
			document.getElementById("inpsupplierbaru") &&
				document.getElementById("inpsupplierbaru").focus()
		}
		setEfekview(!uEfekview)
	},[uIsSuppliernew]);
	React.useEffect(()=>{
		const vIdx = UFunc.getObjectIndeks(uBarangjenisArr,uDlgforminputObj.jenis_id,"value");
		if(vIdx < 0) { uDlgforminputObj.jenis_caption = (""); return; }
		uDlgforminputObj.jenis_caption = (uBarangjenisArr[vIdx].caption);
	},[uDlgforminputObj.jenis_id]);
	React.useEffect(()=>{ 
		if(parseInt(uFormitemObj.h_jual_sat||0) <= 0)
			{ uFormitemObj.hpp_sat = 0; return; }

		const vNilaiKonsi = Math.round(
			((parseInt(uDefaultObj.persen_konsi)||100) / 100) * parseInt(uFormitemObj.h_jual_sat||0)
		);
		uFormitemObj.hpp_sat = (vNilaiKonsi);
		setEfekview(!uEfekview)
	},[uFormitemObj.h_jual_sat]);
	React.useEffect(()=>{
		let vNilaiBruto = 0;
		uDatatabelArr.forEach((vItem,vKey)=>{
			vNilaiBruto = vNilaiBruto + 
				((parseInt(vItem.qty)||0) * (parseInt(vItem.hpp_sat)||0));
		})
		uDatamainObj.nilai_bruto	= (vNilaiBruto);
		uDatamainObj.items_arr 		= uDatatabelArr || [];
		setEfekview(!uEfekview)		
	},[uDatatabelArr.length,uTabeleditObj]);
	React.useEffect(()=>{
		let vNettoDeposit = (parseInt(uDatamainObj.nilai_bruto)||0);

		const vNetto 	= vNettoDeposit;
		uDatamainObj.total_konsi = (vNetto<0 ? 0 : vNetto);
		setEfekview(!uEfekview)		
	},[uDatamainObj.nilai_bruto]);
	React.useEffect(()=>{
		if(uDatatabelArr.length > 0
			|| (uDatamainObj.no_nota||"").trim() !== ""
			|| (uDatamainObj.nama_supplier||"").trim() !== ""
			|| (uDatamainObj.tgl_konsinyasi||"").trim() !== UFunc.DbDate()
			|| parseInt(uDatamainObj.total_konsi||"0") > 0
			|| parseInt(uDatamainObj.id_supplier||"0") > 0
			) setFormupdate(true);
		else setFormupdate(false);
	},[uDatamainObj.tgl_konsinyasi,
	uDatamainObj.no_nota,
	uDatamainObj.id_supplier,
	uDatamainObj.nama_supplier,
	uDatamainObj.nilai_bruto,
	uDatamainObj.total_konsi,
	uDatatabelArr
	]);
	React.useEffect(()=>{
		if(uDatamainObj.tgl_konsinyasi === undefined) 
			uDatamainObj.tgl_konsinyasi = UFunc.DbDate();
	},[uDatamainObj.tgl_konsinyasi]);
	//---END EFFECT---/

	//console.log("(Konsinyasimain) Checked..");
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-3">
		<CContainer fluid className="px-3 classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={() => hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="me-2 align-middle" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.menus_konsinyasi||"menus_konsinyasi").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderToggler onClick={() => hdlKlikReset()}>
				<CIcon className="classikonreset align-middle" height={30}/>
			</CHeaderToggler>
			<CHeaderNav>
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>


		<div className="body flex-grow-1 px-1">
			<CContainer xxl className="py-0 px-lg-5">
			<CForm className="py-0">{contentMain()}</CForm>
			</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton
				style={{paddingTop: 3,paddingBottom: 3}}
				centered={true}
				iconname="cilCheckAlt"
				disabled={uIsFormprocess}
				onClick={()=>hdlKlikSimpanMain()}
				id="btnSimpanmain">
				Simpan [F9]
			</MyButton>
		</CFooter>
		</div>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleDlglookup()}
			dialogHeader={uDlglookupheader||"dialogHeader"}
			inputValue={uInpDlglookuptext}
			dataItemArr={uDataDlglookupArr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>

		<MyDialogform
			onSimpan={()=>hdlKlikDlgformSimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgforminputObj.header||"uDlglookupheader"}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>
		</>
	)
}

export default Konsinyasimain;
