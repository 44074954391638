import React from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CLink,
	CTooltip,
	CFooter,
	CForm,
	CFormSelect,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CButton,
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderBrand,
	CContainer,
	CSpinner,
	CDropdown,
	CDropdownItem,
	CDropdownToggle,
	CDropdownMenu,
	CImage,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Resizer from "react-image-file-resizer";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import { 
	MyDialoglookup,
	MyDialogform,
	MyDialogview,
	MyCardPasien,
	MyProfileDetil,
	MyPage500,
} from '../../components/index';
import { 
} from '../../containers/index';
import { UFunc,cKeycodebypass } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

let pjson     		= require('../../../package.json');
const Ongoingitem 	= (props) => {
	const uComponentname 	= Ongoingitem.name;
	const {
		setLoading, showToast, prosesExpired, setSessionaktif
	} = props; 
	const { id } 			= useParams(); 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const refFileImg 		= React.useRef();

	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--GLOBAL_VARS--/
	const uIsInitHapus		= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN")&&uIsEOMExecuted 
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uInitDataupdate,setInitDataupdate]= React.useState(false);

	const uInitTabelIDBefore	= parseInt(uTabelSelectObj.initID) || 0;	
	const uInitPageBefore		= parseInt(uTabelSelectObj.initPage) || 1;	

	const uTreatmentID						= parseInt(id) || 0;
	const [uTimeCounter,setTimeCounter]		= React.useState(new Date());
	const [uDatamainObj,setDatamainObj]		= React.useState({});
	const [uDataitemArr,setDataitemArr]		= React.useState(uDatamainObj.items_arr||[]);
	const [uDatatreatmentArr,setDatatreatmentArr]	= React.useState([]);
	const [uDatareminderArr,setDatareminderArr]= React.useState([]);
	const uLayananArr				= JSON.parse(localStorage.getItem("layananlist")||"[]");
	const uDataKasGLArr				= JSON.parse(localStorage.getItem("kasgllist")||"[]");
	const [uFirstload,setFirstload]	= React.useState("YA");
	//const [uLayananArr,setLayananArr]		= React.useState([]);
	const [uDatasupplierArr,setDatasupplierArr]= React.useState([]);
	const [uCardpasienrefresh,setCardpasienrefresh]	= React.useState(false);
	const [uNilaitreatment,setNilaitreatment]= React.useState(0);

	const [uInpItemAddid,setInpItemAddid]		= React.useState(0);
	const [uInpItemAddcaption,setInpItemAddcaption]= React.useState("");
	const [uInpItemAddqty,setInpItemAddqty]		= React.useState(0);
	const [uInpItemAddhsatuan,setInpItemAddhsatuan]= React.useState(0);
	const [uInpItemAddjenis,setInpItemAddjenis]	= React.useState("");
	const [uInpItemAddplain,setInpItemAddplain]	= React.useState("TIDAK");
	const [uItemTotal,setItemTotal]				= React.useState(0);

	const [uInpItemEditlabel,setInpItemEditlabel]= React.useState("");
	const [uInpItemEditid,setInpItemEditid]		= React.useState(0);
	const [uInpItemEditqty,setInpItemEditqty]	= React.useState(0);
	const [uInpItemEdithsatuan,setInpItemEdithsatuan]= React.useState(0);
	const [uItemEditefek,setItemEditefek]		= React.useState(false);

	const [uViewupdate,setViewupdate]			= React.useState(false);
	const [uViewupdate2,setViewupdate2]			= React.useState(false);
	const [uInitProsessObj,setInitProsessObj]	= React.useState({});
	const [uISDoneprocess,setISDoneprocess]		= React.useState(false);
	const [uISItemaddprocess,setItemaddprocess]	= React.useState(false);
	const [uISPerhatianprocess,setPerhatianprocess]= React.useState(false);

	//---FORMDIALOG--/
	const [uFotoprocess,setFotoprocess]			= React.useState(false);
	const [uIsShowview,setShowview]				= React.useState(false);

	const [uIsshowDlgform,setshowDlgform]		= React.useState(false);
	const [uForminit,setForminit]				= React.useState("");
	const [uHeaderDlgform,setHeaderDlgform]		= React.useState("");
	const [uInpRemindertgl,setInpRemindertgl]	= React.useState("");
	const [uInpRemindernote,setInpRemindernote]	= React.useState("");
	const [uInpReminderedittgl,setInpReminderedittgl]	= React.useState("");
	const [uInpRemindereditnote,setInpRemindereditnote]	= React.useState("");
	
	const [uInpLayananbaruid,setInpLayananbaruid]	= React.useState("0");
	const [uInpDoneketerangan,setInpDoneketerangan]	= React.useState("");

	const [uInpPLainsupplierid,setInpPLainsupplierid]= React.useState("0");
	const [uInpPLainsuppliercaption,setInpPLainsuppliercaption]= React.useState("");
	const [uInpPLainlunas,setInpPLainlunas]			= React.useState("HUTANG");
	const [uInpPLainkasid,setInpPLainkasid]			= React.useState("0");
	const [uInpPLainkascaption,setInpPLainkascaption]= React.useState("0");
	const [uInpPLainbayarket,setInpPLainbayarket]	= React.useState("");
	//---END FORMDIALOG--/

	//---LOOKUP--/
	const [uLookupinit,setLookupinit]		= React.useState("");//-->Yg_lookup_supplier_ga_jadi,pake_combo_saja
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uIsLookupShow,setLookupShow]		= React.useState(false);
	const [uLookupDataArr,setLookupDataArr]	= React.useState([]);
	const [uInpLookupKwd,setInpLookupKwd]	= React.useState("");
	//---END LOOKUP--/

	//const vTglnowDT	= new Date();
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlKlikReset = () => {
		if(uHTMLError500) setCardpasienrefresh(!uCardpasienrefresh)
		apiInitawal();
	}
	const hdlKlikSetkhusus = () => { apiSetkhusus(); }
	const hdlKlikDatamain = (_JENISEDIT) => {
		_JENISEDIT = (_JENISEDIT||"").trim();
		if(_JENISEDIT==="") return;

		const vInitview		= uInitProsessObj.view||"";
		if(vInitview.toUpperCase() !== _JENISEDIT.toUpperCase()) {
			uInitProsessObj.view = _JENISEDIT;

			if(_JENISEDIT==="SUHU") {
				uInitProsessObj.value = uDatamainObj.cek_suhu || 0;
				if(isMobile) 
					uInitProsessObj.value = UFunc.formatAngka(uDatamainObj.cek_suhu)
			}
			else if(_JENISEDIT==="BERAT") {
				uInitProsessObj.value = uDatamainObj.berat_badan || 0;
				if(isMobile) 
					uInitProsessObj.value = UFunc.formatAngka(uDatamainObj.berat_badan)
			}
			else if(_JENISEDIT==="TEMPLATE")
				uInitProsessObj.value = uDatamainObj.template || "";
			else if(_JENISEDIT==="ANAMNESA")
				uInitProsessObj.value = uDatamainObj.anamnase || "";
			setViewupdate(!uViewupdate); 

			let vTimeout	= setTimeout(()=>{
				clearTimeout(vTimeout)
				let vElsFocus
				if(_JENISEDIT==="SUHU")
				 	vElsFocus = document.getElementById("inpsuhu");
				else if(_JENISEDIT==="BERAT")
				 	vElsFocus = document.getElementById("inpberat");
				else if(_JENISEDIT==="TEMPLATE")
				 	vElsFocus = document.getElementById("inptemplate");
				else if(_JENISEDIT==="ANAMNESA")
				 	vElsFocus = document.getElementById("inpanamnesa");

				vElsFocus && vElsFocus.focus();
			},350);
			return
		}

		//---PROSES_API--/
		apiProsesmain(_JENISEDIT)
		//---END PROSES_API--/
	}
	const hdlKeyDownDatamain = (_JENISEDIT,_EV) => {
		if(_EV.which === 27) {
 			_EV.preventDefault()
			_EV.stopPropagation()
			uInitProsessObj.view 		= "";
			setViewupdate(!uViewupdate);
			return;
		}
		if(_EV.which === 13) {
			//console.log("(Ongoingitem - hdlKeyDownDatamain) _EV.which = "+_EV.which);
			//console.log("(Ongoingitem - hdlKeyDownDatamain) _JENISEDIT = "+_JENISEDIT);
			if(_JENISEDIT === "TEMPLATE") {
				if(_EV.shiftKey) return;
			}
 			_EV.preventDefault()
			_EV.stopPropagation()
			hdlKlikDatamain(_JENISEDIT);
			return;
		}
	}
	const hdlToggleview = () => {setShowview(false)}
	const hdlKlikFototambah = () => { refFileImg.current.click(); }
	const hdlKlikFotoview = () => {
		const vFotoArr 	= uDatamainObj.foto_arr || [];
		if(vFotoArr.length <= 0) return;

		setShowview(true);
	}
	const hdlChangeFotofake = (_EV) => {
		const vFileUploadedArr 	= Array.from(_EV.target.files);

		//console.log("(Pasienrmdetil - hdlChangeFoto) vFileUploadedArr => "+JSON.stringify(vFileUploadedArr));

		if(vFileUploadedArr.length <= 0) return;
		if(vFileUploadedArr.length > 10) {
			_EV.target.value = '';
			showToast("<b>Limited Upload Foto</b>.."+
				"<br/>Untuk Menjaga Realibilitas Server, Sekali Pengiriman Mohon Untuk Tidak Lebih Dari 10 Foto !"+
				"");
			return;
		}

		//--CEK_VALIDASI_FILE--/
		let vFilebaseArr	= [];
		const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
		vFileUploadedArr.forEach(vItems => {
			const vFileuploaded = vItems;
			if (!allowedTypes.includes(vFileuploaded?.type)) {
				_EV.target.value = '';
				showToast("<b>Only JPEG, PNG, and GIF images are allowed</b>.."+
					"<br/>Ada TIPE File yang Tidak Berformat IMAGE."+
					"<br/><br/>Mohon Diperbaiki !"+
					"");
				return;
			}
		});
		//--END CEK_VALIDASI_FILE--/

		const resizeAndConvertToBase64 = (_FILE,_TYPE) => {
			return new Promise((resolve, reject) => {
				Resizer.imageFileResizer(
					_FILE,
					1300, // New width
					800, // New height
					_TYPE||"JPEG", // Format
					50, // Quality
					0, // Rotation
					(uri) => {
						resolve(uri); // This will be a base64 string
					},
					'base64' // Output type
				);
			});
		};

		const base64Promises = vFileUploadedArr.map((file) => {
			//--JIKA_FILESIZE_LEBIH_DARI_1MB--/
			const vFilesize	= (parseFloat(file.size) || 0) / 1024 / 1024;
			if(vFilesize > 1) {
				const vFiletype	= (file.type||"jpeg").
					replace(/^image\//, "").toUpperCase();

				return resizeAndConvertToBase64(file,vFiletype);
			}
			//--END JIKA_FILESIZE_LEBIH_DARI_1MB--/

			return UFunc.getBase64(file)
		});

		Promise.all(base64Promises).
			then((base64Files) => { 
				//--VALIDASI_TOTAL_FILE_IN_BYTES--//-->LIMIT_5MB--/
				const vTotalresizedSize = base64Files.reduce((acc, img) => acc + (img.length * 3) / 4, 0);
				if((vTotalresizedSize / 1024 / 1024) > 5) {
					_EV.target.value = '';

					showToast("<b>Total Pengiriman Foto Maksimal 5MB</b>.."+
						"<br/>Kemungkinan Ada File yang Berukuran Cukup Besar."+
						"<br/><br/>Mohon Upload Foto melalui beberapa sesi Pengiriman !"+
						"");
					return;
				}
				//--END VALIDASI_TOTAL_FILE--/

				//console.log("(Pasienrmdetil - hdlChangeFoto) base64Files [1] => "+JSON.stringify(base64Files));
				//--PROSES_API_ARRAY--/
				_EV.target.value = '';
				apiProsesfoto(base64Files);//--ARRAY_BASE64--/
				//--END PROSES_API_ARRAY--/
			}).
			catch((error) => {
				console.error('Error converting files to Base64:', error);
			});
	}
	const hdlKlikfotohapus = async(_IDX) => {
		_IDX = parseInt(_IDX) || 0;
		if(_IDX < 0) return;

		const vActionhapus = () => {
			//---API--/
			apiProsesfotohapus(_IDX)
			//---END API--/
		}

		if(await Konfirm(
			(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": <B>FOTO REKAMMEDIS..</B>"+
			"<br/>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<br/>"+
			"<br/>"+
			"<B>"+(uBahasaObj.confirm_yakin||"confirm_yakin")+"</B>")) {
			vActionhapus();
		}
	}
	const hdlToggleFLookup = () => {setLookupShow(false)}
	const hdlKlikItemlookup = () => {
		setLookupinit("ITEM")

		let vTmpArr	= [];
		uDatatreatmentArr.forEach((vItems,vKeys)=>{
			const vTmpObj	= {
				id:vItems.id.toString()+vItems.jenis.charAt(0),
				idx:vItems.id,
				caption:vItems.caption,
				jenis:vItems.jenis,
				harga_satuan:vItems.h_satuan,
				pihaklain: vItems.pihaklain
			};
			vTmpArr			= [...vTmpArr,vTmpObj];
		})
		setLookupDataArr(vTmpArr)
		setInpLookupKwd(uInpItemAddcaption);
		setDialogHeader((uBahasaObj.word_lookup||"word_lookup")+
			": "+(uBahasaObj.caption_itemterapi||"caption_itemterapi"));
		setLookupShow(true);
	}
	const hdlKeyDownItemcaption=(_EV)=>{
		//console.log("_EV = "+_EV.keyCode);
		if(!cKeycodebypass.includes(_EV.keyCode)) {
			setInpItemAddid(0);
			setInpItemAddqty(0);
			setInpItemAddhsatuan(0);
			setInpItemAddjenis("");
			setInpItemAddplain("");
		}

		if(_EV.keyCode !== 13) {
			return;
		}
		if(uInpItemAddcaption.trim()==="") { return; }

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uDatatreatmentArr.filter((vItems)=>
			vItems.caption.toUpperCase().includes(uInpItemAddcaption.toUpperCase())
		);
		
		//console.log("vArrCariMaster =>"+JSON.stringify(vArrCariMaster));
		if (vArrCariMaster.length === 1) {
			setInpItemAddid(vArrCariMaster[0].id);
			setInpItemAddcaption(vArrCariMaster[0].caption);
			setInpItemAddhsatuan(vArrCariMaster[0].h_satuan);
			setInpItemAddjenis(vArrCariMaster[0].jenis);
			setInpItemAddplain(vArrCariMaster[0].pihaklain);
			setInpItemAddqty(1);

			//document.getElementById("inpitemaddcatatan").focus();
			const vElsFocus	= document.getElementById("inpitemaddqty");
			let vTimeout = setTimeout(function(){
				vElsFocus.focus();
				clearTimeout(vTimeout);
			},350);
		} else {
			hdlKlikItemlookup();
		}
	}
	//const hdlBlurItemcaption = () => { if((parseInt(uInpItemAddid)||0) <= 0) setInpItemAddcaption(""); }
	const hdlKlikDlgLookuppilih = (_IDITEM) => {
		let vElsFocus;
		let vArridx;
		if(uLookupinit === "ITEM") {
			initLookupitem(_IDITEM); 
			vElsFocus	= document.getElementById("inpitemaddqty");
		}
		else if(uLookupinit === "PLAINSUPPLIER") {
			vArridx = UFunc.getObjectIndeks(uDatasupplierArr,_IDITEM);
			setInpPLainsupplierid(_IDITEM);
			setInpPLainsuppliercaption(uDatasupplierArr[vArridx].caption);
		
			vElsFocus	= document.getElementById("inppihaklainkascaption");
			if(uInpPLainlunas !== "LUNAS")
				vElsFocus	= document.getElementById("btnDialogSimpan");
		}
		else if(uLookupinit === "PLAINKAS") {
			vArridx = UFunc.getObjectIndeks(uDataKasGLArr,_IDITEM,"value");
			setInpPLainkasid(_IDITEM);
			setInpPLainkascaption(uDataKasGLArr[vArridx].caption);
		
			vElsFocus	= document.getElementById("inppihaklainbayarket");
		}

		hdlToggleFLookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			if(!isMobile) vElsFocus && vElsFocus.focus();
		},350);
	}
	const hdlKlikItemtambah = () => {
		let vElsFocus 	= document.getElementById("inpitemaddcaption");
		if(uInpItemAddcaption.trim() === "") {
			vElsFocus.focus();
			showToast((uBahasaObj.caption_itemterapi||"caption_itemterapi")+" "+
				(uBahasaObj.caption_mustfilled)+"..");return;
		}
		if((parseInt(uInpItemAddid)||0) <= 0) {
			vElsFocus.focus();
			showToast((uBahasaObj.caption_itemterapi||"caption_itemterapi")+" "+
				(uBahasaObj.caption_mustchoose)+"..");return;
		}

		const vJenis= uInpItemAddjenis;//uDatatreatmentArr[vObjidx].jenis || "BARANG";

		vElsFocus 	= document.getElementById("inpitemaddqty");
		const vQTY 	= parseInt(uInpItemAddqty)||0;
		if(vQTY <= 0) {
			vElsFocus.focus();
			showToast((uBahasaObj.word_qty||"Qty")+" "+
				(uBahasaObj.caption_invalid)+"..");return;
		}

		//---ITEM_SUDAH_DIINPUTKAN---/
		vElsFocus 	= document.getElementById("inpitemaddcaption");
		const vFilterArr	= uDataitemArr.filter((vItems,vKeys)=>{
			return (parseInt(vItems.item_id)===parseInt(uInpItemAddid)
				&& (vItems.item_jenis||"").toUpperCase()=== (vJenis||"").toUpperCase()  
				)
		});
		if(vFilterArr.length > 0) {
			vElsFocus.focus();
			showToast((uBahasaObj.pesan_itemisexists||"pesan_itemisexists")+" "+
				"");return;
		}
		//---END ITEM_SUDAH_DIINPUTKAN---/

		//--CEK_BARANG--/
		if(vJenis === "BARANG") {
			const vObjidx	= uDatatreatmentArr.findIndex((vItems,vKeys)=>{
				return (vItems.id.toString() === uInpItemAddid.toString()
					&& vItems.jenis === uInpItemAddjenis
					)
			});

			//--CEK_STOK--/
			const vStoksisa	= parseInt(uDatatreatmentArr[vObjidx].stok) || 0;
			if (vStoksisa < vQTY) {
				vElsFocus.focus();
				showToast((uBahasaObj.word_stok||"word_stok")+" "+
					"<B>"+uDatatreatmentArr[vObjidx].caption.toUpperCase()+"</B> "+
					(uBahasaObj.caption_tidakmencukupi||"caption_tidakmencukupi")+".."+
					"<br/><br/>(<B>"+(uBahasaObj.caption_stoktersisa||"caption_stoktersisa")+": "+UFunc.formatAngka(uDatatreatmentArr[vObjidx].stok||0)+"</B>)"+
					"");return;
			}//-*/
			//--END CEK_STOK--/
		}
		//--END CEK_BARANG--/

		vElsFocus 	= document.getElementById("inpitemaddhsatuan");
		if((parseInt(uInpItemAddhsatuan)||0) < 0) {
			vElsFocus.focus();
			showToast((uBahasaObj.caption_hsatuan||"Harga Satuan")+" "+
				(uBahasaObj.caption_invalid)+"..");return;
		}

		//console.log("(Ongoingitem - hdlKlikItemtambah) uInpItemAddplain => "+uInpItemAddplain)
		if(uInpItemAddplain === "YA") {
			apiPihaklainsupplier(); return
		}

		//---API--/
		apiItemtambah();
		//---END API--/
	}
	const hdlKlikItemedit = (_IDTABEL,_JENISEDIT) => {
		_IDTABEL	= parseInt(_IDTABEL) || 0;
		_JENISEDIT	= (_JENISEDIT||"").toUpperCase();
		
		//console.log("(Ongoingitem - hdlKlikItemedit) _JENISEDIT => "+_JENISEDIT);
		//console.log("(Ongoingitem - hdlKlikItemedit) _IDTABEL => "+_IDTABEL);

		initItemview();
		if(_IDTABEL <= 0) {
			setViewupdate(!uViewupdate); return
		}

		const vObjidx 	= UFunc.getObjectIndeks(uDataitemArr,_IDTABEL);
		if(vObjidx < 0) return;

		const vInitedit	= (uDataitemArr[vObjidx].init_edit || "").toUpperCase(); 
		if(vInitedit===_JENISEDIT)
			uDataitemArr[vObjidx].init_edit = "";
		else
			uDataitemArr[vObjidx].init_edit = _JENISEDIT;

		let vElsFocus; 
		if(_JENISEDIT==="QTY") {
			vElsFocus 	= "inpitemeditqty";
			setInpItemEditqty(uDataitemArr[vObjidx].qty);
		} else if(_JENISEDIT==="HSATUAN") {
			vElsFocus 	= "inpitemedithsatuan";
			setInpItemEdithsatuan(uDataitemArr[vObjidx].h_satuan);
		} else if(_JENISEDIT==="LABELITEM") {
			vElsFocus 	= "inpitemeditlabel";
			setInpItemEditlabel(uDataitemArr[vObjidx].item_cetak);
		}
		setViewupdate(!uViewupdate);

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			if(!isMobile)
				document.getElementById(vElsFocus) && 
					document.getElementById(vElsFocus).focus();
		},350);
	}
	const hdlKeyDownItemedit = (_IDTABEL,_JENISEDIT,_EV) => {
		if(_EV.which === 27) {
 			_EV.preventDefault()
			_EV.stopPropagation()

			initItemview()
			setViewupdate(!uViewupdate)
			return
		}
		if(_EV.which === 13) {
 			_EV.preventDefault()
			_EV.stopPropagation()

			hdlKlikItemeditproses(_IDTABEL,_JENISEDIT)
			return
		}
	}
	const hdlKlikItemhapus = async(_IDTABEL) => {
		//console.log("(Ongoingitem - hdlKlikItemedit) _IDTABEL => "+_IDTABEL);
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL<=0) return;

		const vObjidx	= UFunc.getObjectIndeks(uDataitemArr,_IDTABEL);
		//console.log("(Ongoingitem - hdlKlikItemedit) uDataitemArr => "+JSON.stringify(uDataitemArr));
		//console.log("(Ongoingitem - hdlKlikItemedit) vObjidx => "+vObjidx);
		if(vObjidx < 0) return;

		const vCaptionUpper = ((uDataitemArr[vObjidx].item_caption||"UNDF")).toUpperCase();

		const vConfirm	= 
			(uBahasaObj.caption_hapusitem||"caption_hapusitem")+": <B>"+
			vCaptionUpper+"</B>"+
			"<br/>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<br/><br/><B>"+(uBahasaObj.confirm_yakin||"confirm_yakin")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiItemhapus(_IDTABEL);
	}
	const hdlKlikItemeditproses = (_IDTABEL,_JENISEDIT) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		_JENISEDIT	= (_JENISEDIT||"").toUpperCase();

		//--VALIDASI--/
		if(_JENISEDIT==="QTY") {
			if((parseInt(uInpItemEditqty)||0) <= 0) {
				document.getElementById("inpitemqty") && document.getElementById("inpitemqty").focus();
				showToast((uBahasaObj.word_qty)+" "+(uBahasaObj.caption_invalid)+"..");return;
			}
		} else if(_JENISEDIT==="LABELITEM") {
			if((uInpItemEditlabel||"").trim() === "") {
				document.getElementById("inpitemeditlabel") && document.getElementById("inpitemeditlabel").focus();
				showToast("Label Cetak Item Treatment "+(uBahasaObj.caption_invalid)+"..");return;
			}
		}
		//--END VALIDASI--/

		apiItemedit(_IDTABEL,_JENISEDIT);
	}
	const hdlKlikRemindertambah = () => {
		setHeaderDlgform(uBahasaObj.word_tambah+" "+uBahasaObj.caption_datareminder)
		initReminderview()
		setForminit("REMINDER")
		setshowDlgform(true)
	}
	const hdlKlikPlainsuppllierlookup = () => {
		setLookupinit("PLAINSUPPLIER")

		const vTmpArr	= uDatasupplierArr.map(vItems=>{
			return { id: vItems.id, caption: vItems.caption }
		})
		setLookupDataArr(vTmpArr)
		setInpLookupKwd(uInpPLainsuppliercaption);
		setDialogHeader((uBahasaObj.word_lookup||"word_lookup")+": SUPPLIER PIHAK LAIN");
		setLookupShow(true);
	}
	const hdlKDownPlainsuppliercaption = (_EV) => {
		//console.log("_EV = "+_EV.keyCode);
		if(!cKeycodebypass.includes(_EV.keyCode)) {
			setInpPLainsupplierid(0);
		}

		if(_EV.keyCode !== 13) { return; }
		if(uInpPLainsuppliercaption.trim()==="") { return; }

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uDatasupplierArr.filter((vItems)=>
			vItems.caption.toUpperCase().includes(uInpPLainsuppliercaption.toUpperCase())
		);
		
		//console.log("vArrCariMaster =>"+JSON.stringify(vArrCariMaster));
		if (vArrCariMaster.length === 1) {
			setInpPLainsupplierid(vArrCariMaster[0].id);
			setInpPLainsuppliercaption(vArrCariMaster[0].caption);

			//document.getElementById("inpitemaddcatatan").focus();
			let vElsFocus	= document.getElementById("inppihaklainkascaption");
			if(uInpPLainlunas !== "LUNAS")
				vElsFocus	= document.getElementById("btnDialogSimpan");

			let vTimeout = setTimeout(function(){
				clearTimeout(vTimeout);
				vElsFocus && vElsFocus.focus();
			},350);

			return;
		} 

		hdlKlikPlainsuppllierlookup();
	}
	const hdlKlikPlainkaslookup = () => {
		setLookupinit("PLAINKAS")

		const vTmpArr	= uDataKasGLArr.map(vItems=>{
			return { id: vItems.value, caption: vItems.caption }
		})
		setLookupDataArr(vTmpArr)
		setInpLookupKwd("");
		setDialogHeader((uBahasaObj.word_lookup||"word_lookup")+": ASAL KAS PIHAK LAIN");
		setLookupShow(true);
	}
	const hdlKDownPlainkascaption = (_EV) => {}//--TIDAK_DIPAKAI--/
 	const hdlKlikRemindereditinit = (_IDTABEL,_JENISEDIT) => {
		_IDTABEL	= parseInt(_IDTABEL) || 0;
		_JENISEDIT	= (_JENISEDIT||"").toUpperCase();

		initReminderview();
		if(_IDTABEL <= 0) {
			setViewupdate(!uViewupdate); return
		}

		const vObjidx 	= UFunc.getObjectIndeks(uDatareminderArr,_IDTABEL);
		if(vObjidx < 0) return;

		const vInitedit	= (uDatareminderArr[vObjidx].init_edit || "").toUpperCase(); 
		if(vInitedit===_JENISEDIT)
			uDatareminderArr[vObjidx].init_edit = "";
		else
			uDatareminderArr[vObjidx].init_edit = _JENISEDIT;

		let vElsFocus; 
		if(_JENISEDIT==="TANGGAL") {
			vElsFocus 	= "inpreminderedittgl";
			setInpReminderedittgl(uDatareminderArr[vObjidx].tgl_reminder);
		} else if(_JENISEDIT==="NOTE") {
			vElsFocus 	= "inpremindereditnote";
			setInpRemindereditnote(uDatareminderArr[vObjidx].note_reminder);
		}
		setViewupdate(!uViewupdate);

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			document.getElementById(vElsFocus) && document.getElementById(vElsFocus).focus();
		},350);
	}
	const hdlKeyDownReminderinput = (_IDTABEL,_JENISEDIT,_EV) => {
		if(_EV.which === 27) {
 			_EV.preventDefault()
			_EV.stopPropagation()

			initReminderview()
			setViewupdate(!uViewupdate)
			return
		}
		if(_EV.which === 13) {
 			_EV.preventDefault()
			_EV.stopPropagation()

			hdlKlikRemindereditproses(_IDTABEL,_JENISEDIT)
			return
		}
	}
	const hdlKlikRemindereditproses = (_IDTABEL,_JENISEDIT) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;
		_JENISEDIT	= (_JENISEDIT||"").toUpperCase();

		let vElsFocus
		if(_JENISEDIT==="TANGGAL") {
			vElsFocus = document.getElementById("inpreminderedittgl");
			if((uInpReminderedittgl||"").trim() === "") {
				vElsFocus.focus()
				showToast((uBahasaObj.word_tanggal)+" "+(uBahasaObj.caption_invalid)+"..");return;
			}
			//--CEK_TANGGAL_REMINDER--/
			const vTglcekDT	= UFunc.toDate(uInpReminderedittgl)
			if(UFunc.DateIsMundur(vTglcekDT)) {
				vElsFocus.focus()
				showToast(uBahasaObj.pesan_tglreminderexpired+"..");return
			}
			//--END CEK_TANGGAL_REMINDER--/
		}
		if(_JENISEDIT==="NOTE") {
			vElsFocus = document.getElementById("inpremindereditnote");
			if((uInpRemindereditnote||"").trim() === "") {
				vElsFocus.focus()
				showToast((uBahasaObj.caption_catreminder||"caption_catreminder")+" "+(uBahasaObj.caption_mustfilled)+"..");return;
			}
		}

		//---API--/
		apiReminderedit(_IDTABEL,_JENISEDIT)
		//---END API--/
	}
	const hdlKlikReminderhapus = async(_IDTABEL) => {
		if((parseInt(_IDTABEL)||0)<=0) return;

		const vObjidx	= UFunc.getObjectIndeks(uDatareminderArr,_IDTABEL);
		if(vObjidx < 0) return;

		const vCaptionUpper = (UFunc.TglAngka(uDatareminderArr[vObjidx].tgl_reminder)).toUpperCase();

		const vConfirm	= 
			(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": <B>"+
			vCaptionUpper+"</B>"+
			"<br/>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<br/><br/><B>"+(uBahasaObj.confirm_yakin||"confirm_yakin")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiReminderhapus(_IDTABEL);
	}
	const hdlToggleDlgform = () => {setshowDlgform(false)}
	const hdlKlikSimpanDlgform = () => {
		if(uForminit==="TRANSFER") { initSimpantransfer(); return }
		if(uForminit==="DONE") { initSimpandone(); return }
		if(uForminit==="PIHAKLAIN") { initSimpanpihaklain(); return }

		initSimpanreminder()
	}
	const hdlKlikTransfer = () => {
		setHeaderDlgform((uBahasaObj.word_transfer||"uBahasaObj.word_transfer")+" "+
			(uBahasaObj.caption_jenislayanan))
		initItemview()
		initReminderview()
		setForminit("TRANSFER")
		setshowDlgform(true)
	}
	const hdlKlikDone = () => {
		setHeaderDlgform((uBahasaObj.caption_layanandone)+": "+
			(uDatamainObj.layanan_caption||"").toUpperCase()+
			"")
		initItemview()
		initReminderview()
		setForminit("DONE")
		setshowDlgform(true)
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initItemview = () => {
		uDataitemArr.map((vItems,vKeys)=>{
			vItems.init_edit	= "";
			vItems.init_proses	= false;
		});
	}
	const initTreatmentSUM = () => {
		const vTotaltreatment = uDataitemArr.reduce((vSUMS,vItems)=>{
			return vSUMS + ( (parseFloat(vItems.h_satuan)||0) * (parseInt(vItems.qty)||0) )
		},0);

		setNilaitreatment(vTotaltreatment);
	}
	const initReminderview = () => {
		uDatareminderArr.forEach((vItems,vKeys)=>{
			uDatareminderArr[vKeys].init_edit	= "";
			uDatareminderArr[vKeys].init_proses	= false;
		});
	}
	const initDlgformawal = () => {
		setForminit("")
		setHeaderDlgform("")
		setInpRemindertgl("")
		setInpRemindernote("")

		setInpLayananbaruid("0")
		setInpDoneketerangan("")

		setInpPLainlunas("HUTANG")
		setInpPLainsupplierid(0)
		setInpPLainsuppliercaption("");
		setInpPLainkasid(0);
		setInpPLainkascaption("");
	}
	const initFieldempty = () => {
		setInpItemAddcaption("");
		setInpItemAddjenis("");
		setInpItemAddplain("TIDAK");
		setInpItemAddid(0);
		setInpItemAddqty(0);
		setInpItemAddhsatuan(0);

		setInpItemEditqty(0);
		setInpItemEdithsatuan(0);

		setInpRemindertgl("");
		setInpRemindernote("");
		setInpReminderedittgl("");
		setInpRemindereditnote("");
	}
	const initLookupitem = (_IDITEM) => {
		//-->_IDITEM = IDBARANG/IDJASA + "B"/"J"
		const vIdx = UFunc.getObjectIndeks(uLookupDataArr,_IDITEM,"id"); 
		//console.log("(Ongoingitem - initLookupitem) vIdx => "+vIdx);
		if(vIdx < 0) return;

		setInpItemAddid(uLookupDataArr[vIdx].idx);
		setInpItemAddcaption(uLookupDataArr[vIdx].caption);
		setInpItemAddqty(1);
		setInpItemAddhsatuan(uLookupDataArr[vIdx].harga_satuan);
		setInpItemAddjenis(uLookupDataArr[vIdx].jenis);
		setInpItemAddplain(uLookupDataArr[vIdx].pihaklain);
	}
	const initSimpanreminder = () => {
		let vElsFocus = document.getElementById("inpremindertgl")
		if(uInpRemindertgl==="") {
			vElsFocus.focus()
			showToast(uBahasaObj.word_tanggal+" "+uBahasaObj.caption_invalid+"..");return
		}
		//--CEK_TANGGAL_REMINDER--/
		const vTglcekDT	= UFunc.toDate(uInpRemindertgl)
		if(UFunc.DateIsMundur(vTglcekDT)) {
			vElsFocus.focus()
			showToast(uBahasaObj.pesan_tglreminderexpired+"..");return
		}
		//--END CEK_TANGGAL_REMINDER--/

		vElsFocus = document.getElementById("inpremindernote")
		if(uInpRemindernote.trim() === "") {
			vElsFocus.focus()
			showToast(uBahasaObj.caption_catreminder+" "+uBahasaObj.caption_mustfilled+"..");return
		}

		apiRemindertambah();
	}
	const initSimpantransfer = () => {
		const vLayanan = (uInpLayananbaruid || "").toUpperCase();
		if(vLayanan==="") {
			document.getElementById("inplayananbaruid").focus();
			showToast((uBahasaObj.caption_jenislayanan||"caption_jenislayanan")+" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}

		apiTransferlayanan();
	}
	const initSimpandone = () => { 
		//--CEK_TREATMENT--/
		if(uDataitemArr.length <= 0) {
			showToast(uBahasaObj.pesan_ongoingitemempty); return
		}
		//--END CEK_TREATMENT--/

		apiDone()
	}
	const initSimpanpihaklain = () => {
		let vElsFocus = document.getElementById("inppihaklainlunas")
		if(uInpPLainlunas!=="LUNAS" && uInpPLainlunas!=="HUTANG") {
			vElsFocus && vElsFocus.focus()
			showToast((uBahasaObj.caption_statuslunasplain||"caption_statuslunasplain")+" "+(uBahasaObj.caption_invalid)+".."); return
		}
		vElsFocus = document.getElementById("inppihaklainsupplierid")
		if((parseInt(uInpPLainsupplierid)||0)<=0) {
			vElsFocus && vElsFocus.focus()
			showToast((uBahasaObj.caption_supplierplain||"caption_supplierplain")+" "+(uBahasaObj.caption_mustchoose)+".."); return
		}
		vElsFocus = document.getElementById("inppihaklainkascaption")
		if(uInpPLainlunas==="LUNAS") 
			if((parseInt(uInpPLainkasid)||0)<=0) {
				vElsFocus && vElsFocus.focus()
				showToast("Kas Asal Pembayaran "+(uBahasaObj.caption_mustchoose)+".."); return
			}

		apiItemtambah()
	}
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentanamnesa = () => {
		if(uHTMLError500) return ""

		return (
		<>
		<CCard className="classcardbg mb-3">
		<CCardHeader className="d-flex justify-content-between align-items-center">
			<b>{uBahasaObj.caption_dataanamnesa||"caption_dataanamnesa"}</b>
			<div>
			{!uIsEOMExecuted && (
			<>
			<span className="classblinking">
				<CImage src={pjson.homepage+"api//images/icon_eom.png"} className="classikoncoreui" height={30}/>
			</span>
			<span className="mx-1">&middot;</span>
			</>
			)}

			{(!uISPerhatianprocess) ? (
				<CTooltip content="--Set Perhatian KHUSUS oleh KASIR..">
				<CLink onClick={()=>hdlKlikSetkhusus()} 
					className={"classcursorpointer text-decoration-none classikon classikonwarning"+
					((uDatamainObj.status_perhatian||false)===true?" classblinking":"")}/>
				</CTooltip>
			) : (
				<CSpinner color="dark" size="sm"/>
			)}

			<span className="mx-1">&middot;</span>

			<CTooltip className="d-none d-lg-block" content="--REFRESH Data">
			<CLink onClick={()=>apiInitawal()} className="classcursorpointer classikon classikonreset"/>
			</CTooltip>

			<span className="mx-1">&middot;</span>

			<CDropdown>
			<CDropdownToggle color="transparent" className="p-0 m-0" 
				caret={false}>
				<CIcon icon="cilChevronBottom" className="classikoncoreui d-none d-md-block" height={30}/>
				<CIcon icon="cilOptions" className="classikoncoreui d-md-none" height={30}/>
			</CDropdownToggle>
			<CDropdownMenu className="p-0">
				<CDropdownItem 
					className="py-2 fw-bolder text-success classcursorpointer"
					onClick={()=>hdlKlikTransfer()}>
				<CIcon className="classikontransfer classikoncoreui me-2" height={25}/>
				{uBahasaObj.word_transfer}
				</CDropdownItem>

				{uIsEOMExecuted && (
				<CDropdownItem 
					className="py-2 fw-bolder text-primary classcursorpointer"
					onClick={()=>hdlKlikDone()}>
				<CIcon className="classikontabel classikonchecked me-2" height={20}/>
				{uBahasaObj.word_done}
				</CDropdownItem>
				)}
			</CDropdownMenu>
			</CDropdown>
			</div>
		</CCardHeader>

		<CCardBody className="px-lg-5">
			<CRow className="my-1">
			<CCol xs="4" lg="3" className="">{uBahasaObj.word_keluhan}</CCol>
			<CCol className="text-end text-lg-start">
				<div>{uDatamainObj.keluhan||"-"}</div>
			</CCol>
			</CRow>

			<CRow className="my-1">
			<CCol xs="4" lg="3" className="">{uBahasaObj.word_anamnesa||"word_anamnesa"}</CCol>
			<CCol className="text-end text-lg-start">
			{((uInitProsessObj.view||"")!=="ANAMNESA") ? (
				<CLink className="classcursorpointer text-decoration-none" onClick={()=>hdlKlikDatamain("ANAMNESA")}>
				{(uDatamainObj.anamnase||"")===""?(<CIcon className="classikontabel classikonedit" height={25}/>)
					: uDatamainObj.anamnase }
				</CLink>
			) : (
			<>
				{((uInitProsessObj.isProcess||false)===false) ? (
					<>
					<CInputGroup size="sm">
					<CFormInput size="sm"
						className="text-capitalize"
						value={uInitProsessObj.value}
						onChange={(e)=>{uInitProsessObj.value=e.target.value; setEfekview(!uEfekview)}}
						onKeyDown={(e)=>hdlKeyDownDatamain("ANAMNESA",e)}
						id="inpanamnesa"/>
					<CButton size="sm" color="secondary" onClick={()=>hdlKlikDatamain("ANAMNESA")}>
						<CIcon icon="cilCheckAlt"/>
					</CButton>
					</CInputGroup>
					</>
				) : (
					<CSpinner color="dark" size="sm"/>
				)}
			</>
			)}
			</CCol>
			</CRow>

			<CRow className="my-1">
			<CCol xs="4" lg="3" className="">{uBahasaObj.caption_suhubadan||"caption_suhubadan"}</CCol>
			<CCol xs="4" className="d-lg-none"/>
			<CCol xs="4" lg="3" className="text-end text-lg-start">
			{((uInitProsessObj.view||"")!=="SUHU") ? (
				<CLink className="classcursorpointer text-decoration-none" onClick={()=>hdlKlikDatamain("SUHU")}>
				{UFunc.formatAngka(uDatamainObj.cek_suhu)}&deg; C
				</CLink>
			) : (
			<>
				{((uInitProsessObj.isProcess||false)===false) ? (
					<CInputGroup size="sm">
					<CFormInput size="sm" type={isMobile ? "text" : "number"}
						className="text-end"
						min={0} max={100} step=".5"
						lang="id-ID"
						value={uInitProsessObj.value}
						onChange={(e)=>{
							if(!isMobile) {
								uInitProsessObj.value=e.target.value; 
								setEfekview(!uEfekview);
								return;
							}
							const vCorrectedvalue 	= (e.target.value||"").replace(/[^0-9,]/g, '');
							uInitProsessObj.value 	= vCorrectedvalue;
							setEfekview(!uEfekview);
						}}
						onKeyDown={(e)=>hdlKeyDownDatamain("SUHU",e)}
						onFocus={(e)=>e.target.select()}
						id="inpsuhu"/>
					<CInputGroupText className="fw-bolder">&deg; C</CInputGroupText>
					<span className="mx-1"/>
					<CButton size="sm" color="secondary" onClick={()=>hdlKlikDatamain("SUHU")}>
						<CIcon icon="cilCheckAlt"/>
					</CButton>
					</CInputGroup>
				) : (
					<CSpinner color="dark" size="sm"/>
				)}
			</>
			)}
			</CCol>
			</CRow>

			<CRow className="my-1">
			<CCol xs="4" lg="3" className="">{uBahasaObj.caption_beratbadan||"caption_beratbadan"}</CCol>
			<CCol xs="4" className="d-lg-none"/>
			<CCol xs="4" lg="3" className="text-end text-lg-start">
			{((uInitProsessObj.view||"")!=="BERAT") ? (
				<CLink className="classcursorpointer text-decoration-none" onClick={()=>hdlKlikDatamain("BERAT")}>
					{UFunc.formatAngka(uDatamainObj.berat_badan||0)} KG
				</CLink>
			) : (
			<>
			{((uInitProsessObj.isProcess||false)===false) ? (
				<CInputGroup size="sm">
				<CFormInput size="sm" type={isMobile ? "text" : "number"}
					className="text-end"
					min={0} max={999} step=".1"
					lang="id-ID"
					value={uInitProsessObj.value}
					onChange={(e)=>{
						if(!isMobile) {
							uInitProsessObj.value=e.target.value; 
							setEfekview(!uEfekview);
							return;
						}
						const vCorrectedvalue 	= (e.target.value||"").replace(/[^0-9,]/g, '');
						uInitProsessObj.value 	= vCorrectedvalue;
						setEfekview(!uEfekview);
					}}
					onKeyDown={(e)=>hdlKeyDownDatamain("BERAT",e)}
					onFocus={(e)=>e.target.select()}
					id="inpberat"/> 
				<CInputGroupText className="fw-bolder">KG</CInputGroupText>
				<span className="mx-1"/>
				<CButton size="sm" color="secondary" onClick={()=>hdlKlikDatamain("BERAT")}>
					<CIcon icon="cilCheckAlt"/>
				</CButton>
				</CInputGroup>
			) : (
				<CSpinner color="dark" size="sm"/>
			)}
			</>
			)}
			</CCol>
			</CRow>
		</CCardBody>
		</CCard>
		</>
		)
	}
	const contenttemplate = () => {
		if(uHTMLError500) return ""

		return (
		<>
		<CCard className="mb-3 classcardbg">
		<CCardHeader className="d-flex justify-content-between align-items-center">
			<b>{uBahasaObj.caption_notetemplate||"caption_notetemplate"}</b>
			{((uInitProsessObj.view||"")!=="TEMPLATE") && (
			<CTooltip content="--Edit Template">
			<CLink onClick={()=>hdlKlikDatamain("TEMPLATE")} className="classcursorpointer classikon classikonedit align-center"/>
			</CTooltip>
			)}
		</CCardHeader>

		<CCardBody className="px-lg-5">
			{((uInitProsessObj.view||"")!=="TEMPLATE") ? (
			<div>{UFunc.renderHTML(uDatamainObj.template||"-")}</div>
			) : (
			<>
				<CInputGroup size="sm" className="align-items-start">
				<CFormTextarea size="sm"
					rows={5}
					value={UFunc.br2nl(uInitProsessObj.value)}
					onChange={(e)=>{uInitProsessObj.value=UFunc.nl2br(e.target.value); setEfekview(!uEfekview)}}
					onKeyDown={(e)=>hdlKeyDownDatamain("TEMPLATE",e)}
					id="inptemplate"/>
				{((uInitProsessObj.isProcess||false)===false) ? (
					<CButton size="sm" color="secondary" onClick={()=>hdlKlikDatamain("TEMPLATE")}>
					<CIcon icon="cilCheckAlt"/>
					</CButton>
				) : (
					<CInputGroupText className="px-2 bg-transparent">
					<CSpinner color="dark"/>
					</CInputGroupText>
				)}
				</CInputGroup>
				<div className="classpetunjuk px-5">*) {uBahasaObj.petunjuk_textarea}</div>
			</>
			)}
		</CCardBody>
		</CCard>
		</>
		)
	}
	const contentfoto = () => {
		if(uHTMLError500) return "";

		const vDatafotoArr	= uDatamainObj.foto_arr || [];
		return (
		<CCard className="mb-3 classcardbg">
		<CCardHeader className="d-flex justify-content-between align-items-center">
			<b>{uBahasaObj.caption_fotoperiksa||"caption_fotoperiksa"}</b>
			<CTooltip content={"--"+(uBahasaObj.caption_previewfoto||"caption_previewfoto")}>
			<CLink onClick={()=>hdlKlikFotoview()} className="classcursorpointer classikon classikonfoto"/>
			</CTooltip>
		</CCardHeader>

		<CCardBody>
			<div className="p-2 d-flex justify-content-start align-items-center" 
				style={{flexFlow:"row wrap"}}>
			{vDatafotoArr.map((vItems,vKeys)=>{
				return (
				<div key={vKeys} className={"classbordersquare me-2 p-1"}>
					<div className="mx-auto"
						style={{
							backgroundSize:"cover",
							backgroundColor:"transparent",
							backgroundImage:"url("+vItems.url_image+")",
							backgroundPosition:"center center",
							backgroundRepeat:"no-repeat",
							//background: "transparent url("+vItems.url_image+") center center no-repeat",
							height:65,width:65}}>
						<div 
							style={{
								cursor:"pointer",
								height:65,width:65
							}}
							className="classactionfoto"
							onClick={()=>{hdlKlikfotohapus(vKeys)}}/>
					</div>
				</div>
				)
			})}
			{(uFotoprocess) ? (
				<div className="me-2 d-flex justify-content-center align-items-center"
					style={{height:70,width:70}}>
				<CSpinner />
				</div>
			) : (
				<div className="classbordersquare d-flex justify-content-center align-items-center" 
					onClick={(e)=>hdlKlikFototambah(e)}
					style={{cursor:"pointer",height:70,width:70}}>
				<CIcon className="classikontambah4" height={30}/>
				</div>
			)}
			<input
				type="file"
				accept="image/png, image/jpeg, image/gif, capture=camera"
				ref={refFileImg}
				onChange={(e)=>hdlChangeFotofake(e)}
				className="d-none"
				multiple={true}
				id="inpfileimg"/>
			</div>
		</CCardBody>
		</CCard>
		)//>
	}
	const contenttreatment = () => {
		if(uHTMLError500) return "";

		const vSUMitem	= uDataitemArr.reduce((vSUMS,vItems)=>{
			return vSUMS + (parseInt(vItems.qty)||0)
		},0)

		return (
		<CCard className="mb-3 classcardbg" style={{flex: "1 0 0"}}>
		<CCardHeader className="d-flex justify-content-between align-items-center">
			<b>{uBahasaObj.caption_itemtreatment||"caption_itemtreatment"}</b>
			<div>
			<b className="text-primary">({UFunc.formatAngka(vSUMitem)})</b>
			<span className="mx-2">&middot;</span>
			<span className="text-success">Rp{UFunc.formatAngka(uNilaitreatment)}</span>
			</div>
		</CCardHeader>

		<CCardBody className="px-0">
			<CForm className="mb-2 pb-1 d-flex classborderbottom">
				<span className="ms-1">&nbsp;</span>
				<div style={{width:"45%",minWidth:175}} className="">
				<CInputGroup size="sm">
				<CFormInput size="sm"
					placeholder={"--"+uBahasaObj.petunjuk_keyword+".."}
					value={uInpItemAddcaption}
					onKeyDown={(e)=>hdlKeyDownItemcaption(e)}
					onChange={(e)=>setInpItemAddcaption(e.target.value)}
					id="inpitemaddcaption"/>
				<CButton size="sm" color="dark" 
					onClick={()=>hdlKlikItemlookup()}>
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				</CInputGroup>
				
				<div className="classpetunjuk">
					<small>{uBahasaObj.caption_itemterapi||"caption_itemterapi"}</small>
				</div>
				</div>

				<span className="me-1 d-lg-none"/>
				<span className="mx-1 d-none d-lg-inline">&middot;</span>

				<div style={{width:"10%",minWidth:55}} 
					className="">
					<CFormInput size="sm" type="number"
						onFocus={(e)=>e.target.select()}
						value={uInpItemAddqty}
						onChange={(e)=>setInpItemAddqty(e.target.value)}
						className="text-end"
						id="inpitemaddqty"/>
					<div className="classpetunjuk">
						<small>{uBahasaObj.word_qty}</small>
					</div>
				</div>

				<span className="me-1 d-lg-none"/>
				<span className="mx-1 d-none d-lg-inline">&middot;</span>

				<div style={{width:"18%",minWidth:100}} 
					className="">
					<NumberFormat 
						value={uInpItemAddhsatuan}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						allowNegative={false}
						onValueChange={(values) => setInpItemAddhsatuan(values.value)}
						renderText={values => (
							<CFormInput size="sm" 
								value={values}
								onFocus={(e)=>e.target.select()}
								onChange={(e)=>setInpItemAddhsatuan(e.target.value)}
								className="text-end"
								id="inpitemaddhsatuan"/>
						)} 
					prefix={isMobile?"":"Rp"}/>
					<div className="classpetunjuk">
						<small>{uBahasaObj.caption_hsatuan}</small>
					</div>
				</div>

				<span className="me-1 d-lg-none"/>
				<span className="mx-1 d-none d-lg-inline">&middot;</span>

				<div className="">
					{(uISItemaddprocess) ? (
						<CSpinner size="sm" color="dark"/>
					) : (
						<CButton size="sm" color="secondary"
							onClick={()=>hdlKlikItemtambah()}
							id="btnitemtambah">
							<CIcon icon="cibAddthis"/>
						</CButton>
					)}
				</div>
			</CForm>

			<table className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead>
			<tr className="text-center classfontsmaller align-top">
			<th width="5%" className="py-0 px-1"><small>No</small></th>
			<th className="py-0 px-1">
				<small>
				<CRow className="m-0">
				<CCol md="5" className="px-0 text-start">{uBahasaObj.word_item||"word_item"}</CCol>
				<CCol md="1" className="px-0">&middot;</CCol>
				<CCol md="2" className="px-0">{uBahasaObj.caption_hsatuan||"caption_hsatuan"} (Rp)</CCol>
				<CCol md="2" className="px-0">{uBahasaObj.word_qty||"word_qty"}</CCol>
				<CCol md="2" className="px-0">{uBahasaObj.word_total||"word_total"} (Rp)</CCol>
				</CRow>
				</small>
			</th>
			</tr>
			</thead>

			<tbody>
			{uDataitemArr.map((vItems,vKeys)=>{
				const {
					id,item_id,item_caption,qty,satuan,h_satuan,item_cetak,
					pihaklain_jenis,pihaklain_lunas,pihaklain_supplierid,pihaklain_suppliercaption,
					pihaklain_kasid,pihaklain_kascaption,pihaklain_ket,
					init_edit,init_proses
				} = vItems

				const vInitedit		= init_edit||""
				const vInitproses	= init_proses||false
				const vTotalcaption	= UFunc.formatAngka(h_satuan * qty)

				//console.log("(Ongoingitem - contenttreatment) vItems => ["+vKeys+"] "+JSON.stringify(vItems))
				return (
				<tr className={"align-top"} key={vKeys}>
				<td className="px-0 text-end">{vKeys+1}.</td>
				<td className="px-1 text-end">
				<CRow className="mx-0">
				<CCol md="5" className="px-1 text-start">
					{(vInitedit!=="LABELITEM") ? (
					<>
						<CTooltip className="d-none d-lg-block" content="--Klik utk Ganti Label Item Terapi">
						<CLink className="classcursorpointer text-info"
							onClick={()=>hdlKlikItemedit(id,"LABELITEM")}>
							{item_cetak}<small className="ms-1 d-none">({id})</small>
						</CLink>
						</CTooltip>
						
						{((pihaklain_jenis||"TIDAK") === "YA"
							|| (item_cetak||"").toUpperCase() !== (item_caption||"").toUpperCase() 
						) && (
						<ul className="my-0 ps-3">
							{(item_cetak||"").toUpperCase() !== (item_caption||"").toUpperCase() && (
							<li className="classfontsmaller">
								<small>Nama ORI: <b>{vItems.item_caption}</b></small>
							</li>
							)}

							{(pihaklain_jenis||"TIDAK")==="YA" && (
							<li className="classfontsmaller">
								<span className="fst-italic">{pihaklain_suppliercaption}</span>
								<span className="mx-1">&middot;</span>
								{(pihaklain_lunas||"HUTANG")==="LUNAS"
									? (<b>(LUNAS)</b>) : (<b className="text-danger">(BELUM LUNAS)</b>)}
								
								{(pihaklain_lunas||"HUTANG")==="LUNAS" && (
								<div>
									<small>{pihaklain_kascaption}</small>
									{(pihaklain_ket||"")!=="" && (
									<>
										<small className="mx-1">&middot;</small>
										<small className="fst-italic">{pihaklain_ket}</small>
									</>
									)}
								</div>
								)}
							</li>
							)}
						</ul>
						)}
					</>
					) : (
					<>
						{(vInitproses===false) ? (
						<CInputGroup size="sm">
						<CFormInput size="sm" maxLength={100}
							value={uInpItemEditlabel}
							onChange={(e)=>setInpItemEditlabel(e.target.value)}
							onKeyDown={(e)=>hdlKeyDownItemedit(id,"LABELITEM",e)}
							id="inpitemeditlabel"/>
						<CButton size="sm" color="dark"
							onClick={()=>hdlKlikItemeditproses(id,"LABELITEM")}
							id="btnitemeditlabel">
							<CIcon icon="cilCheckAlt"/>
						</CButton>
						</CInputGroup>
						) : (
						<CSpinner color="dark" size="sm"/>
						)}
					</>
					)}

				</CCol>

				<CCol md="1" className="px-1 text-center">
					{(vInitedit!=="HAPUS" || vInitproses===false) ? (
					<CLink
						onClick={()=>hdlKlikItemhapus(id)} 
						className="classcursorpointer classikontabel classikonhapus"/>
					) : (
						<CSpinner color="dark" size="sm"/>
					)}
				</CCol>
				<CCol md="2" className="px-1">
					{(vInitedit!=="HSATUAN") ? (
					<CLink className="classcursorpointer text-decoration-none"
						onClick={()=>hdlKlikItemedit(id,"HSATUAN")}>{UFunc.formatAngka(h_satuan)}</CLink>
					) : (
					<>
						{(vInitproses===false) ? (
						<CInputGroup size="sm">
						<NumberFormat 
							value={uInpItemEdithsatuan}
							displayType={'text'} 
							thousandSeparator={"."} 
							decimalSeparator={","}
							allowNegative={false}
							onValueChange={(values) => setInpItemEdithsatuan(values.value)}
							renderText={values => (
								<CFormInput size="sm" 
									value={values}
									onKeyDown={(e)=>hdlKeyDownItemedit(id,"HSATUAN",e)}
									onFocus={(e)=>e.target.select()}
									onChange={(e)=>setInpItemEdithsatuan(e.target.value)}
									className="text-end"
									id="inpitemedithsatuan"/>
							)} 
						prefix={isMobile ? "" : "Rp"}/>
						<CButton size="sm" color="secondary" 
							onClick={()=>hdlKlikItemeditproses(id,"HSATUAN")}>
							<CIcon icon="cilCheckAlt"/>
						</CButton>
						</CInputGroup>
					) : (
						<CSpinner color="dark" size="sm"/>
					)}
				</>
				)}
				</CCol>
				<CCol md="2" className="px-1 text-center fw-bolder">
				{(vInitedit!=="QTY") ? (
				<CLink className="classcursorpointer text-decoration-none"
					onClick={()=>hdlKlikItemedit(id,"QTY")}>{UFunc.formatAngka(qty)} {satuan}</CLink>
				) : (
				<>
					{(vInitproses===false) ? (
					<CInputGroup size="sm" className="px-md-3">
					<CFormInput size="sm" type="number"
						className="text-end"
						min={0}
						value={uInpItemEditqty}
						onKeyDown={(e)=>hdlKeyDownItemedit(id,"QTY",e)}
						onFocus={(e)=>e.target.select()}
						onChange={(e)=>setInpItemEditqty(e.target.value)}
						id="inpitemeditqty"/>
					<CButton size="sm" color="secondary" 
						onClick={()=>hdlKlikItemeditproses(id,"QTY")}>
						<CIcon icon="cilCheckAlt"/>
					</CButton>
					</CInputGroup>
					) : (
						<CSpinner color="dark" size="sm"/>
					)}
				</>
				)}
				</CCol>
				<CCol md="2" className="px-1 text-end fw-bolder">{vTotalcaption}</CCol>
				</CRow>
				</td>
				</tr>
				);
			})}
			</tbody>
			</table>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentreminder = () => {
		if(uHTMLError500) return ""

		return (
		<CCard className="classcardbg">
		<CCardHeader className="d-flex justify-content-between align-items-center">
			<div>
				<b>{uBahasaObj.caption_datareminder||"caption_datareminder"}</b> 
				<span className="mx-1">&middot;</span>
				<b>({UFunc.formatAngka(uDatareminderArr.length)})</b>
			</div>
			<CLink className="classcursorpointer classikon classikontambah"
					onClick={()=>hdlKlikRemindertambah()}/>
		</CCardHeader>

		<CCardBody>
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
		<tr className="text-center classfontsmaller align-top">
		<th width="5%" className="p-0 px-1"><small>No</small></th>
		<th className="p-0 px-1">
			<small>
			<CRow className="mx-0">
			<CCol md="3" className="px-0 text-start">{uBahasaObj.word_tanggal||"Tanggal"}</CCol>
			<CCol md="1" className="px-0">&middot;</CCol>
			<CCol md="8" className="px-0 text-start">{uBahasaObj.caption_catreminder||"caption_catreminder"}</CCol>
			</CRow>
			</small>
		</th>
		</tr>
		</thead>
		<tbody>
		{uDatareminderArr.map((vItems,vKeys)=>{
			const {
				id,tgl_reminder,note_reminder,init_edit,init_proses
			} = vItems

			const vInitedit		= init_edit||""
			const vInitproses	= init_proses||false

			return (
			<tr className="align-top" key={vKeys}>
			<td className="px-0 text-end">{vKeys+1}.</td>
			<td className="px-1 text-end">
			<CRow className="mx-0">
				<CCol md="3" className="px-1 text-md-start fw-bolder">
				{(vInitedit!=="TANGGAL" && !(vInitedit==="TANGGAL" && vInitproses===true)) && (
					<CLink className="classcursorpointer text-decoration-none"
						onClick={()=>hdlKlikRemindereditinit(id,"TANGGAL")}>{UFunc.TglAngka(tgl_reminder)}</CLink>
				)}
				{(vInitedit==="TANGGAL" && vInitproses===false) && (
					<CInputGroup size="sm">
					<CFormInput size="sm" type="date"
						value={uInpReminderedittgl}
						onKeyDown={(e)=>hdlKeyDownReminderinput(id,"TANGGAL",e)}
						onChange={(e)=>setInpReminderedittgl(e.target.value)}
						id="inpreminderedittgl"/>
					<CButton size="sm" color="secondary"
						onClick={()=>hdlKlikRemindereditproses(id,"TANGGAL")}>
						<CIcon icon="cilCheckAlt"/>
					</CButton>
					</CInputGroup>
				)}
				{(vInitedit==="TANGGAL" && vInitproses===true) && (
					<CSpinner size="sm" color="dark"/>
				)}
				</CCol>
				
				<CCol md="1" className="px-0 text-md-center">
				{(vInitedit!=="HAPUS" || vInitproses===false) && (
				<CLink 
					onClick={()=>hdlKlikReminderhapus(id)}
					className="classcursorpointer classikontabel classikonhapus"/>
				)}
				{(vInitedit==="HAPUS" && vInitproses===true) && (
					<CSpinner size="sm" color="dark"/>
				)}
				</CCol>

				<CCol md="8" className="px-1 text-md-start">
				{(vInitedit!=="NOTE" && !(vInitedit==="NOTE" && vInitproses===true)) && (
					<CLink className="classcursorpointer text-decoration-none fst-italic text-capitalize"
						onClick={()=>hdlKlikRemindereditinit(id,"NOTE")}>
					{note_reminder||"-"}
					</CLink>
				)}

				{(vInitedit==="NOTE" && vInitproses===false) && (
					<CInputGroup size="sm">
					<CFormInput size="sm" type="text" maxLength={255}
						className="text-capitalize"
						value={uInpRemindereditnote}
						onKeyDown={(e)=>hdlKeyDownReminderinput(id,"NOTE",e)}
						onChange={(e)=>setInpRemindereditnote(e.target.value)}
						id="inpremindereditnote"/>
					<CButton size="sm" color="secondary"
						onClick={()=>hdlKlikRemindereditproses(id,"NOTE")}>
						<CIcon icon="cilCheckAlt"/>
					</CButton>
					</CInputGroup>
				)}
				{(vInitedit==="NOTE" && vInitproses===true) && (
					<CSpinner size="sm" color="dark"/>
				)}
				</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentview = () => {
		if(!uIsShowview) return (<></>)

		const vFotoArr 	= uDatamainObj.foto_arr || [];
		let vFotodlgArr	= []
		vFotoArr.map(vItems=>{
			const vTmpObj = {
				original:vItems.url_image,
				thumbnail:vItems.url_image,
				thumbnailHeight:85,
				thumbnailWidth:85,
			}
			vFotodlgArr.push(vTmpObj);
		})

		return (
		<ImageGallery 
			items={vFotodlgArr}/>
		)//>
	}
	const contentDlgform = () => {
		if(!uIsshowDlgform) return (<></>)//>

		if(uForminit==="TRANSFER") return contentDlgtransfer()
		if(uForminit==="DONE") return contentDlgdone()
		if(uForminit==="PIHAKLAIN") return contentDlgplain()
		if(uForminit==="REMINDER") return contentDlgreminder()

		return (<></>)//>
	}
	const contentDlgreminder = () => {
		if(uForminit!=="REMINDER") return (<></>)//>

		return (
		<CForm>
		<CRow className="mx-0">
			<CCol xs="4">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
			<CCol xs="8" lg="6">
			<CFormInput size="sm" type="date"
				value={uInpRemindertgl}
				onChange={(e)=>setInpRemindertgl(e.target.value)}
				id="inpremindertgl"/>
			</CCol>
		</CRow>
		<CRow className="mx-0">
			<CCol xs="12">{uBahasaObj.caption_catreminder||"caption_catreminder"}</CCol>
			<CCol xs="12" className="my-1">
			<CFormInput size="sm" maxLength="255"
				value={uInpRemindernote}
				className="text-capitalize"
				onChange={(e)=>setInpRemindernote(e.target.value)}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpremindernote"/>
			</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentDlgplain = () => {
		if(uForminit !== "PIHAKLAIN") return (<></>)//>

		return (
		<CForm>
		<CRow className="mx-0 my-1">
			<CCol xs="12" className="">{uBahasaObj.caption_statuslunasplain||"caption_statuslunasplain"}</CCol>
			<CCol className="">
				<CFormSelect size="sm"
					value={uInpPLainlunas}
					onChange={(e)=>setInpPLainlunas(e.target.value)}
					id="inppihaklainlunas">
					<option value="LUNAS">LUNAS</option>
					<option value="HUTANG">BELUM LUNAS</option>
				</CFormSelect>
			</CCol>
		</CRow>

		<CRow className="mx-md-0 my-1">
			<CCol md="12" className="">{uBahasaObj.caption_supplierplain||"caption_supplierplain"}</CCol>
			<CCol className="">
				<CInputGroup size="sm">
				<CFormInput size="sm"
					placeholder="--Keyword Supplier Pihak Lain.."
					value={uInpPLainsuppliercaption}
					onChange={(e)=>{setInpPLainsuppliercaption(e.target.value)}}
					onKeyDown={(e)=>hdlKDownPlainsuppliercaption(e)}
					id="inppihaklainsuppliercaption"/>
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikPlainsuppllierlookup()}
					id="btnpihaklainsupplierlookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>
			</CCol>
			<CCol className="d-none">
				<CFormSelect size="sm"
					value={uInpPLainsupplierid}
					onChange={(e)=>setInpPLainsupplierid(e.target.value)}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inppihaklainsupplierid">
				<option value="0">{"--"+(uBahasaObj.caption_pilihsupplier||"caption_pilihsupplier")}</option>
				{uDatasupplierArr.map((vItems,vKeys)=>{
					return (
					<option value={vItems.id} key={vKeys}>{vItems.caption.toUpperCase()}</option>
					)
				})}
				</CFormSelect>
			</CCol>
		</CRow>

		{uInpPLainlunas === "LUNAS" && (
		<>
			<div className="my-3 classborderbottom"/>

			<CRow className="mx-md-0 my-1">
			<CCol xs="12" className="">{uBahasaObj.caption_kaspembayaran||"Asal Kas Pembayaran"}</CCol>
			<CCol className="">
				<CInputGroup size="sm">
				<CFormInput size="sm" readOnly={true}
					placeholder="--Pilih ASAL KAS.."
					value={uInpPLainkascaption}
					onChange={(e)=>{setInpPLainkascaption(e.target.value)}}
					onClick={()=>hdlKlikPlainkaslookup()}
					id="inppihaklainkascaption"/>
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikPlainkaslookup()}
					id="btnpihaklainkaslookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>
			</CCol>
			</CRow>

			<CRow className="mx-md-0 my-1">
			<CCol xs="12" className="">{uBahasaObj.caption_ketpembayaran||"Ket.Pembayaran"}</CCol>
			<CCol className="">
				<CFormInput size="sm" maxLength={100}
					value={uInpPLainbayarket}
					onChange={(e)=>setInpPLainbayarket(e.target.value)}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inppihaklainbayarket"/>
			</CCol>
			</CRow>
		</>
		)}
		</CForm>
		)//>
	}
	const contentDlgtransfer = () => {
		if(uForminit !== "TRANSFER") return (<></>)//>

		const vLayananvalue		= uDatamainObj.layanan_value || "";
		const vLayananTrfArr	= uLayananArr.filter((vItems,vKeys)=>{
			 return (vItems.id||"0").toString() !== (uDatamainObj.layanan_id||"0").toString()
		})

		return (
		<CForm className="mx-md-5">
		<CRow className="mx-0 my-1">
			<CCol md="12" className="px-1">
			{(uBahasaObj.caption_layananbefore||"caption_layananbefore")}:{" "}
			<strong>{uBahasaObj["layanan_"+vLayananvalue.toLowerCase()]}</strong>
			</CCol>
		</CRow>
		<CRow className="mx-0 my-2">
			<CCol md="12" className="px-1 classborderbottom"/>
		</CRow>
		<CRow className="mx-0 my-1">
			<CCol md="12" className="px-1">{(uBahasaObj.caption_transferke||"caption_transferke")}</CCol>
			<CCol md="12"  className="px-1">
				<CFormSelect size="sm"
					className="text-uppercase"
					value={uInpLayananbaruid}
					onChange={(e)=>setInpLayananbaruid(e.target.value)}
					id="inplayananbaruid">
				{vLayananTrfArr.map((vItems,vKeys)=>{
					return (
					<option value={vItems.id} key={vKeys}>{vItems.caption_layanan}</option>
					);
				})}
				</CFormSelect>
			</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentDlgdone = () => {
		if(uForminit !== "DONE") return (<></>)//>

		return (
		<CForm className="mx-0">
		<CRow className="mx-0 my-1">
			<CCol md="12" className="px-1">
			{(uBahasaObj.word_pasien||"Pasien")}:{" "}
			<strong>{uDatamainObj.pasien_caption.toUpperCase()}</strong>
			<div className="mt-2 fst-italic">{UFunc.renderHTML(uBahasaObj.pesan_warnlayananselesai)}</div>
			</CCol>
		</CRow>
		<CRow className="mx-0 my-2">
			<CCol md="12" className="px-1 classborderbottom"/>
		</CRow>
		<CRow className="mx-0 my-1">
			<CCol md="12" className="px-1">{(uBahasaObj.caption_catatankasir||"caption_catatankasir")}</CCol>
			<CCol md="12"  className="px-1">
				<CFormInput size="sm" type="text"
					maxLength="150"
					className="text-capitalize"
					value={uInpDoneketerangan}
					onChange={(e)=>setInpDoneketerangan(e.target.value)}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpdoneketerangan"/>
			</CCol>
		</CRow>
		<CRow className="mx-0 my-3">
			<CCol md="12" className="px-1 fw-bolder">{uBahasaObj.confirm_yakin}</CCol>
		</CRow>
		</CForm>
		)//>
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	/*/--NOTE--/
	--> send_treatmentid	= tabel main id (tongoing.id)
	--> send_tabelid		= tabel child id (tneo_terapiitem.id)
	--> send_itemid			= tabel item id (id_barang / id_jasa)
	//--END NOTE--*/
	const apiInitawal = () => {
		uFuncname = apiInitawal.name;

		setHTMLError500()
		setDatamainObj({})
		setDatatreatmentArr([])
		setDatareminderArr([])
		setDatasupplierArr([])
		setNilaitreatment(0)
		initFieldempty()
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiInitawal) LEWAT ");
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_treatmentid : uTreatmentID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/ld_dataform";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoading(false);
			if(output_string.tampil) {
				//console.log("("+uComponentname+"-"+uFuncname+") dataitemlist : "+(output_string.dataitemlist));
				setDatamainObj(JSON.parse(output_string.datamainlist||"{}"));
				setDatatreatmentArr(JSON.parse(output_string.dataitemlist||"[]"));
				setDatareminderArr(JSON.parse(output_string.datareminder||"[]"))
				//setLayananArr(JSON.parse(output_string.layananlist || JSON.stringify(uLayananArr)));
				if(uFirstload === "YA") {
					setCardpasienrefresh(!uCardpasienrefresh);
	    			setFirstload("TIDAK");
	    		}

			} else if(output_string.info) {
				if(output_string.dataexpired) {
					showToast(output_string.info);
				} else {
					setHTMLError500(output_string.info);
					uNavigate("/ongoing",{replace:true}); return;
				}
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<span class='text-danger'>"+vMsg+"</span>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return;
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsesmain = (_JENISEDIT) => {
		uFuncname 	= apiProsesmain.name;

		let vValue	= uInitProsessObj.value;
		let vElsUpdate = document.getElementById("inpanamnesa");
		if(_JENISEDIT==="SUHU") {
		 	vElsUpdate  = document.getElementById("inpsuhu");
		 	if(isMobile)
			 	vValue 	= parseFloat(vValue.replace(",","."))||0;
		}
		else if(_JENISEDIT==="BERAT") {
		 	vElsUpdate = document.getElementById("inpberat");
		 	if(isMobile)
			 	vValue 	= parseFloat(vValue.replace(",","."))||0;
		}
		else if(_JENISEDIT==="TEMPLATE")
		 	vElsUpdate = document.getElementById("inptemplate");

		uInitProsessObj.isProcess = true;
		setViewupdate2(!uViewupdate2);

		/*//---TEST_FRONTEND--/
		let vTimeout	= setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//---END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_treatmentid : uTreatmentID,
			send_value: vValue,
			send_jenis : _JENISEDIT,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/pr_datamain";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			if(output_string.sukses) {
				uInitProsessObj.isProcess 	= false;

				if(_JENISEDIT==="SUHU")
					uDatamainObj.cek_suhu 		= vValue
				else if(_JENISEDIT==="BERAT")
					uDatamainObj.berat_badan 	= vValue
				else if(_JENISEDIT==="TEMPLATE")
					uDatamainObj.template 		= (vValue)
				else uDatamainObj.anamnase 		= UFunc.capitalize(vValue)

				setInitProsessObj({});
				//setViewupdate(!uViewupdate);
			} else {
				uInitProsessObj.isProcess 	= false;
				setViewupdate(!uViewupdate);

				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {

					console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true);prosesExpired(); return;
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			uInitProsessObj.isProcess 	= false;
			setViewupdate(!uViewupdate);

			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesfoto = (_BASE64ARR) => {
		uFuncname 	= apiProsesfoto.name
		_BASE64ARR	= _BASE64ARR || [];

		const vFotoArr	= uDatamainObj.foto_arr || []
		setFotoprocess(true)

		/*//---TEST_FRONTEND--/
		let vTimeout	= setTimeout(()=>{
			clearTimeout(vTimeout)
			setFotoprocess(false)

			setViewupdate(!uViewupdate);
		},2000); return
		//---END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_treatmentid : uTreatmentID,
			send_fotobase64: JSON.stringify(_BASE64ARR),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/pr_fototambah";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setFotoprocess(false)
			if(output_string.sukses) {
				const vNewoutputArr	= JSON.parse(output_string.dataoutputs || "[]");
				//-->FORMAT: { id:x, ongoing_id: ,url_image: urls}

				uDatamainObj.foto_arr	= [...vFotoArr,...vNewoutputArr];
				setViewupdate(!uViewupdate)
			} else {
				setViewupdate(!uViewupdate);

				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Ongoingitem - apiProsesfoto) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true);prosesExpired(); return
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			setFotoprocess(false)

			console.log("(Ongoingitem - apiProsesfoto) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesfotohapus = (_IDX) => {
		uFuncname 	= apiProsesfotohapus.name
		_IDX 		= parseInt(_IDX)||0
		if(_IDX < 0) return 

		setFotoprocess(true);
		let vFotoArr		= uDatamainObj.foto_arr || []
		const vFotoID		= vFotoArr[_IDX].id

		/*//---TEST_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") vFotoArr : "+JSON.stringify(vFotoArr));
		let vTimeout	= setTimeout(()=>{
			clearTimeout(vTimeout)
		},2000); return
		//---END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_fotoid: vFotoID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/pr_fotohapus";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setFotoprocess(false)
			if(output_string.sukses) {
				vFotoArr.splice(_IDX,1)

				setFotoprocess(false)
				uDatamainObj.foto_arr = vFotoArr
				setViewupdate(!uViewupdate)
			} else {
				setViewupdate(!uViewupdate)

				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {

					console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true);prosesExpired(); return
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			setFotoprocess(false)
			setViewupdate(!uViewupdate);

			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiItemtambah = () => {
		uFuncname 		= apiItemtambah.name;

		const vObjidx	= uDatatreatmentArr.findIndex((vItems,vKeys)=>{
			return (vItems.id.toString() === uInpItemAddid.toString()
				&& vItems.jenis === uInpItemAddjenis
				)
		});
		const vJenis	= uInpItemAddjenis;//-*/
		const vSatuan	= uDatatreatmentArr[vObjidx].satuan || "PCS";
		const vPihaklain= uDatatreatmentArr[vObjidx].pihaklain || "TIDAK";
		const vSupplierIdx	= UFunc.getObjectIndeks(uDatasupplierArr,uInpPLainsupplierid)
		const vSuppliercaption = vSupplierIdx >= 0 ? uDatasupplierArr[vSupplierIdx].caption : "UNDF"
		const vEls 		= document.getElementById("btnDialogSimpan")
		setItemaddprocess(true);
		if(vPihaklain === "YA") 
			{ vEls && (vEls.disabled = true); setLoading(true); }

		/*//---TEST_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") vPihaklain => "+vPihaklain);
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2000); return
		//---END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_treatmentid : uTreatmentID,
			send_itemid : uInpItemAddid,
			send_itemcaption: uInpItemAddcaption,
			send_itemqty : uInpItemAddqty,
			send_itemhsatuan : uInpItemAddhsatuan,
			send_itemjenis: vJenis,
			send_itempihaklain: vPihaklain,
			send_itempihaklainlunas: uInpPLainlunas,
			send_itempihaklainsupplierid: uInpPLainsupplierid,
			send_itempihaklainkasid: uInpPLainkasid,
			send_itempihaklainketbayar: uInpPLainbayarket,
			send_itemsatuan: vSatuan,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/pr_itemtambah";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setItemaddprocess(false);
			setLoading(false);
			vEls && (vEls.disabled = false);
			if(output_string.sukses) {
				const vNewID		= output_string.tabelid || "255";
				const vNewCaption 	= UFunc.capitalize(uInpItemAddcaption);

				const vTmpObj	= {
					id:vNewID,
					item_id:uInpItemAddid,
					item_caption:vNewCaption,
					item_cetak:vNewCaption,
					item_jenis: vJenis,
					qty:uInpItemAddqty,
					h_satuan:uInpItemAddhsatuan,
					satuan: vSatuan,
					pihaklain_jenis: vPihaklain,
					pihaklain_lunas: uInpPLainlunas,
					pihaklain_supplierid: uInpPLainsupplierid,
					pihaklain_suppliercaption: vSuppliercaption,
					pihaklain_kasid: uInpPLainkasid,
					pihaklain_ket: UFunc.capitalize(uInpPLainbayarket),
				}
				setDataitemArr([vTmpObj,...uDataitemArr]);
				uDatamainObj.items_arr = [vTmpObj,...uDataitemArr];
				if(vPihaklain === "YA") setshowDlgform(false)

				initFieldempty();
				document.getElementById("inpitemaddcaption").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			setLoading(false)
			setItemaddprocess(false)
			vEls && (vEls.disabled = false)
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error)
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR")
		});
	}
	const apiItemhapus = (_IDTABEL) => {
		uFuncname 		= apiItemhapus.name;
		_IDTABEL		= parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		const vObjidx	= UFunc.getObjectIndeks(uDataitemArr,_IDTABEL);

		uDataitemArr[vObjidx].init_edit 	= "HAPUS";
		uDataitemArr[vObjidx].init_proses	= true;
		uDatamainObj.items_arr 				= uDataitemArr;
		setViewupdate(!uViewupdate);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") LEWAT ");

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout)
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_treatmentid : uTreatmentID,
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/pr_itemhapus";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			if(output_string.sukses) {
				uDataitemArr.splice(vObjidx,1);
				uDatamainObj.items_arr = uDataitemArr;
				setViewupdate2(!uViewupdate2);
			} else {
				uDataitemArr[vObjidx].init_edit 	= "";
				uDataitemArr[vObjidx].init_proses	= false;
				uDatamainObj.items_arr 				= uDataitemArr;
				setViewupdate2(!uViewupdate2);

				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired(); return
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			uDataitemArr[vObjidx].init_edit 	= "";
			uDataitemArr[vObjidx].init_proses	= false;
			uDatamainObj.items_arr 				= uDataitemArr;
			setViewupdate2(!uViewupdate2);

			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiItemedit = (_IDTABEL,_JENISEDIT) => {
		uFuncname 	= apiItemedit.name;

		_JENISEDIT	= (_JENISEDIT||"").toUpperCase();
		_IDTABEL 	= parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0 || _JENISEDIT==="") return;

		//console.log("("+uComponentname+"-"+uFuncname+") uDataitemArr => "+JSON.stringify(uDataitemArr));
		const vObjidx	= UFunc.getObjectIndeks(uDataitemArr,_IDTABEL);
		const vJenis	= uDataitemArr[vObjidx].item_jenis || "BARANG";
		let vValue		= "";
		if(_JENISEDIT === "HSATUAN")
			vValue = uInpItemEdithsatuan;
		else if(_JENISEDIT === "LABELITEM")
			vValue = uInpItemEditlabel;
		else vValue = uInpItemEditqty;

		uDataitemArr[vObjidx].init_proses = true;
		uDatamainObj.items_arr = uDataitemArr;
		setViewupdate(!uViewupdate);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") LEWAT 1");
		let vTimeout = setInterval(() => {
			clearInterval(vTimeout);
			setViewupdate2(!uViewupdate2);
		},1500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_itemjenis: vJenis,
			send_value: vValue,
			send_jenis : _JENISEDIT,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/pr_itemedit";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			if(output_string.sukses) {
				if(_JENISEDIT === "HSATUAN")
					uDataitemArr[vObjidx].h_satuan = uInpItemEdithsatuan;
				else if(_JENISEDIT === "LABELITEM")
					uDataitemArr[vObjidx].item_cetak = uInpItemEditlabel;
				else uDataitemArr[vObjidx].qty = uInpItemEditqty;

				//console.log("("+uComponentname+" - "+uFuncname+") LEWAT SUKSES");
				initItemview()
				uDatamainObj.items_arr = uDataitemArr
				setItemEditefek(true);
			} else {
				uDataitemArr[vObjidx].init_proses 	= false
				uDatamainObj.items_arr 				= uDataitemArr
				setViewupdate(!uViewupdate)

				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					prosesExpired(); return
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			uDataitemArr[vObjidx].init_proses 	= false;
			uDatamainObj.items_arr 				= uDataitemArr;
			setViewupdate(!uViewupdate);

			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiPihaklainsupplier = () => {
		uFuncname 		= apiPihaklainsupplier.name;

		if(uDatasupplierArr.length > 0) {
			setHeaderDlgform(uBahasaObj.caption_transaksipihaklain||"Transaksi Pihak Lain")
			setForminit("PIHAKLAIN")
			setshowDlgform(true)
			return
		}

		setLoading(true)

		/*//---TEST_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			setLoading(false)
		},2000); return
		//---END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_treatmentid : uTreatmentID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/ld_supplier";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoading(false)
			if(output_string.tampil) {
				//console.log("("+uComponentname+"-"+uFuncname+") output_string.dataobject : "+output_string.dataobject)

				setDatasupplierArr(JSON.parse(output_string.dataobject || "[]"))
				setHeaderDlgform(uBahasaObj.caption_transaksipihaklain||"Transaksi Pihak Lain")
				setForminit("PIHAKLAIN")
				setshowDlgform(true)
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			setLoading(false)
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error)
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR")
		});
	}
	const apiRemindertambah = () => {
		uFuncname 		= apiRemindertambah.name;

		//document.getElementById("btnDialogSimpan").disabled = true
		const vEls 		= document.getElementById("btnDialogSimpan")
		vEls && (vEls.disabled = true)
		setLoading(true)

		/*//---TEST_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2000); return
		//---END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_remindertgl : uInpRemindertgl,
			send_remindernote : uInpRemindernote,
			send_pasienid : uDatamainObj.pasien_id,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_reminder/pr_ongoingsimpan";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			vEls && (vEls.disabled = false)
			setLoading(false);
			if(output_string.sukses) {
				const vTmpObj	= {
					id: output_string.tabelid || "0",
					tgl_reminder: uInpRemindertgl,
					note_reminder: uInpRemindernote,
				}
				setDatareminderArr([vTmpObj,...uDatareminderArr])

				hdlToggleDlgform(); return;

				/*initDlgformawal()
				if(!isMobile) {
					document.getElementById("inpremindertgl") &&
						document.getElementById("inpremindertgl").focus()
				}//-*/
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			vEls && (vEls.disabled = false)
			setLoading(false)
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error)
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR")
		});
	}
	const apiReminderedit = (_IDTABEL,_JENISEDIT) => {
		uFuncname 	= apiReminderedit.name;

		_JENISEDIT	= (_JENISEDIT||"").toUpperCase();
		_IDTABEL 	= parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0 || _JENISEDIT==="") return;

		//console.log("("+uComponentname+"-"+uFuncname+") uDataitemArr => "+JSON.stringify(uDataitemArr));
		const vObjidx	= UFunc.getObjectIndeks(uDatareminderArr,_IDTABEL);
		let vValue		= "";
		if(_JENISEDIT === "NOTE")
			vValue = uInpRemindereditnote;
		else vValue = uInpReminderedittgl;

		uDatareminderArr[vObjidx].init_proses = true;
		setViewupdate(!uViewupdate);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") LEWAT 1");
		let vTimeout = setInterval(() => {
			clearInterval(vTimeout);
		},1500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_value: vValue,
			send_jenis : _JENISEDIT,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_reminder/pr_ongoingedit";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			if(output_string.sukses) {
				if(_JENISEDIT === "NOTE")
					uDatareminderArr[vObjidx].note_reminder = uInpRemindereditnote;
				else uDatareminderArr[vObjidx].tgl_reminder = uInpReminderedittgl;

				initReminderview();
				setViewupdate2(!uViewupdate2);
			} else {
				uDatareminderArr[vObjidx].init_proses 	= false
				setViewupdate(!uViewupdate)

				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired(); return
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			uDatareminderArr[vObjidx].init_proses 	= false;
			setViewupdate(!uViewupdate);

			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiReminderhapus = (_IDTABEL) => {
		uFuncname 		= apiItemhapus.name;
		_IDTABEL		= parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		const vObjidx	= UFunc.getObjectIndeks(uDatareminderArr,_IDTABEL);

		uDatareminderArr[vObjidx].init_edit 	= "HAPUS";
		uDatareminderArr[vObjidx].init_proses	= true;
		setViewupdate(!uViewupdate);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") LEWAT ");
		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout)
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_reminder/pr_ongoinghapus";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			if(output_string.sukses) {
				uDatareminderArr.splice(vObjidx,1);
				setViewupdate2(!uViewupdate2);
			} else {
				uDatareminderArr[vObjidx].init_edit 	= "";
				uDatareminderArr[vObjidx].init_proses	= false;
				setViewupdate2(!uViewupdate2);

				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired(); return
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			uDatareminderArr[vObjidx].init_edit 	= "";
			uDatareminderArr[vObjidx].init_proses	= false;
			setViewupdate2(!uViewupdate2);

			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSetkhusus = () => {
		uFuncname 		= apiSetkhusus.name;

		setPerhatianprocess(true);

		/*//---TEST_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2000); return
		//---END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_treatmentid : uTreatmentID,
			send_setkhusus : (uDatamainObj.status_perhatian||false)===false?"YA":"TIDAK",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/pr_setkhusus";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setPerhatianprocess(false);
			if(output_string.sukses) {
				uDatamainObj.status_perhatian = !(uDatamainObj.status_perhatian||false)
				setViewupdate(!uViewupdate)
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			setPerhatianprocess(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error)
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR")
		});
	}
	const apiTransferlayanan = () => {
		uFuncname 		= apiTransferlayanan.name;

		const vEls 		= document.getElementById("btnDialogSimpan")
		const vLayananbaruArr = uLayananArr.filter(vItems=>
			vItems.id.toString() === uInpLayananbaruid.toString())

		vEls && (vEls.disabled 	= true)
		uDatamainObj.layanan_id = undefined;
		setLoading(true)

		/*//---TEST_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uInpLayananbaruid => "+uInpLayananbaruid);
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout); setLoading(false)
			hdlToggleDlgform()
		},2000); return
		//---END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTreatmentID,
			send_layananbaruid : uInpLayananbaruid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_ongoing/pr_transfer";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			vEls && (vEls.disabled = false)
			setLoading(false);
			if(output_string.sukses) {
				uDatamainObj.layanan_id = uInpLayananbaruid;
				hdlToggleDlgform()
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return;
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			vEls && (vEls.disabled = false)
			setLoading(false)
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error)
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR")
		});
	}
	const apiDone = () => {
		uFuncname 		= apiTransferlayanan.name;

		const vEls 		= document.getElementById("btnDialogSimpan")
		vEls && (vEls.disabled = true)
		setLoading(true)

		/*//---TEST_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uInpLayananbaruid => "+uInpLayananbaruid);
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			hdlToggleDlgform()
		},2000); return
		//---END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTreatmentID,
			send_doneketerangan : uInpDoneketerangan,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_ongoing/pr_done";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			vEls && (vEls.disabled = false)
			if(output_string.sukses) {
				hdlToggleDlgform()
				uNavigate("/ongoing",{replace:true}); return;
			} else if(output_string.expired) {
				prosesExpired(); return;
			} else {
				setLoading(false);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} 
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			vEls && (vEls.disabled = false)
			setLoading(false)
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error)
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR")
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	//React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		const vThisurl	= window.location.pathname + window.location.search;
		uDispatch({type: "set", gRoutebefore: vThisurl});
		uDispatch({type: "set", gStatePasien: vThisurl});

    	apiInitawal();

		let vTimer	= setInterval(()=>{ setTimeCounter(new Date()); },60000);

    	return ()=>{
    		setFirstload("YA");
    		clearInterval(vTimer);
			hdlToggleFLookup(); hdlToggleDlgform(); hdlToggleview();
    		setDatamainObj({}); setDataitemArr([]); setInitProsessObj({});
    		setDatatreatmentArr([]); setLookupDataArr([]); setDatasupplierArr([])
    	}
	},[]);
	React.useEffect(()=>{ setDataitemArr(uDatamainObj.items_arr||[]) },[uDatamainObj]);
	React.useEffect(()=>{
	 	if(uDatamainObj.layanan_id === undefined) {
	 		return;
	 	}
	 	const vArridx = UFunc.getObjectIndeks(uLayananArr,uDatamainObj.layanan_id);
	 	if(vArridx < 0) return;

	 	uDatamainObj.layanan_color = uLayananArr[vArridx].color_layanan;
	 	uDatamainObj.layanan_caption= uLayananArr[vArridx].caption_layanan;
	 	setEfekview(!uEfekview);
	},[uDatamainObj,uDatamainObj.layanan_id]);
	React.useEffect(()=>{
		if(parseInt(uInpLayananbaruid||"0") > 0) return;
	 	if(uDatamainObj.layanan_id === undefined) return;

		const vLayananTrfArr	= uLayananArr.filter((vItems,vKeys)=>{
			 return vItems.id.toString() !== uDatamainObj.layanan_id.toString()
		});

		setInpLayananbaruid(vLayananTrfArr[0].id);
	},[uInpLayananbaruid,uDatamainObj.layanan_id]);
	React.useEffect(()=>{ 
		if(uDataitemArr.length <= 0) 
			{ setNilaitreatment(0); return }

		uDataitemArr.map(vItems=>{
			if((vItems.pihaklain_lunas||"HUTANG")==="LUNAS") {
				const vArridx = UFunc.getObjectIndeks(uDataKasGLArr,vItems.pihaklain_kasid,"value");
				vItems.pihaklain_kascaption = (uDataKasGLArr[vArridx]||{}).caption;
			}
		})

		initTreatmentSUM();
	},[uDataitemArr,
	uDataitemArr.length]);
	React.useEffect(()=>{
		if(uInpPLainlunas !== "LUNAS") return;

		setInpPLainkasid("0");
		setInpPLainkascaption("");
		setInpPLainbayarket("");
	},[uInpPLainlunas]);
	React.useEffect(()=>{
		if(!uItemEditefek) return;

		initTreatmentSUM();
		setItemEditefek(false); 
	},[uItemEditefek]);
	React.useEffect(()=>{
		if(!uIsLookupShow) { 
			setLookupinit(""); 
			setLookupDataArr([]); 
			setInpLookupKwd("");
			return;
		}
	},[uIsLookupShow]);
	React.useEffect(()=>{
		if(!uIsshowDlgform) { initDlgformawal(); return; }

		let vTimeout	= setTimeout(()=>{
			clearTimeout(vTimeout)

			let vElsFocus = document.getElementById("inpremindertgl")
			if(uForminit === "TRANSFER")
				vElsFocus = document.getElementById("inplayananbaruid")
			else if(uForminit === "DONE")
				vElsFocus = document.getElementById("inpdoneketerangan")
			else if(uForminit === "PIHAKLAIN")
				vElsFocus = document.getElementById("inppihaklainlunas")

			vElsFocus && (vElsFocus.focus())
		},350)
	},[uIsshowDlgform]);
	React.useEffect(()=>{
		//console.log("("+uComponentname+"-[uInitPageBefore]) uInitTabelIDBefore = "+uInitTabelIDBefore);
		if(uInitTabelIDBefore <= 0) return;

		uDispatch({type: "set", gInitTabelSelected: {}}); 
	},[uInitTabelIDBefore]);
	//---END EFFECT--/

	//console.log("(Ongoingitem) Checked..");//---KALO_GA_ADA_YG_PAKE_cComponentName
	//console.log("(Ongoingitem) uDatamainObj => "+JSON.stringify(uDatamainObj));

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHTMLError500) return (
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-3">
		<CContainer fluid className="px-3 classheadermain"
			style={{height:64}}>
			<CHeaderToggler className="" onClick={() =>uNavigate(-1)}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
			<b>{uBahasaObj.layanan_caption||"Pemeriksaan"}</b>
			</CHeaderBrand>

			<CHeaderNav className="align-items-center">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>

		<div className="p-0 m-0 border-top w-100"
			style={{height:10,backgroundColor:((uDatamainObj.layanan_color||"")!==""?"#"+uDatamainObj.layanan_color:"white")+""}}>
			<CImage src={pjson.homepage+"api/images/img_spacer.png"} height={1}/>
		</div>
		</CHeader>

		<div className="body flex-grow-1 px-1 px-md-2 px-lg-5">
			<CContainer xxl className="">
				<MyPage500 content={uHTMLError500} 
					isWithrefresh={true}
					hdlReset={()=>hdlKlikReset()}
					{...props}/>
			</CContainer>
		</div>
		</div>
	)//>

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-3">
		<CContainer fluid className="classheadermain"
			style={{height:64}}>
			<CHeaderToggler className="px-0 me-1" onClick={() =>uNavigate("/ongoing",{replace:true})}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
			{(uDatamainObj.layanan_caption) ? (
			<>
			<div className="d-none d-md-inline">
				<span>{uBahasaObj.layanan_caption||"Treatment"}</span>:
				<span className="me-1"/>
			</div>
			<b>{uDatamainObj.layanan_caption}</b>
			</>
			) : (<small className="classblinking text-danger fst-italic mx-2">Loading..</small>)}

			</CHeaderBrand>

			<CHeaderNav className="align-items-center">
				<div className="d-none d-md-block classfontsmaller">
				<small>
					<span className="mx-1">&middot;</span>
					{UFunc.HariAngka()}{" "}
					{UFunc.leadZero(uTimeCounter.getHours())+":"+
						UFunc.leadZero(uTimeCounter.getMinutes())+
					""}

					<span className="d-none d-md-inline">
						<span className="mx-1">&middot;</span>
						[{UFunc.leadZero(uTreatmentID,6)}]
					</span>
					<span className="mx-1">&middot;</span>
				</small>
				</div>

				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>

		<div className="p-0 m-0 border-top w-100"
			style={{height:10,backgroundColor:((uDatamainObj.layanan_color||"")!==""?"#"+uDatamainObj.layanan_color:"white")+""}}>
			<CImage src={pjson.homepage+"api/images/img_spacer.png"} height={1}/>
		</div>
		</CHeader>

		<div className="body flex-grow-1 px-1 px-md-2">
			<CContainer fluid className="px-0 px-lg-2">
			<div className="d-md-none">
			<CCard>
			<CCardHeader>Oops,Sorry</CCardHeader>
			<CCardBody>
				<p>Fitur Ini TIDAK DAPAT ditampilkan dengan Perangkat HP/Cellular!</p>
				<p>Mohon Gunakan LAPTOP, PC, atau TABLET. Atau Ubah orientasi Tampilan menjadi <b>Landscape</b></p>
			</CCardBody>
			</CCard>
			</div>

			<div className="d-none d-md-block">
			<CRow className="mx-0 mb-3">
			<CCol md="4" lg="3" className="p-0 pe-2">
				<MyCardPasien pasienID={uDatamainObj.pasien_id}
					dataRefresh={uCardpasienrefresh}
					isFamilyview={false}
					initLoader={uComponentname.toUpperCase()}
					{...props}/>
			</CCol>

			<CCol md="8" lg="9" className="p-0 ps-2" style={{display:"flex",flexDirection:"column"}}>
				<>{contentanamnesa()}</>
				<>{contenttemplate()}</>
				<>{contentfoto()}</>
				<>{contenttreatment()}</>
				<>{contentreminder()}</>
			</CCol>
			</CRow>
			</div>
			</CContainer>
		</div>
		</div>

		<MyDialoglookup
			show={uIsLookupShow}
			toggle={()=>hdlToggleFLookup()}
			dialogHeader={uDialogHeader||"dialogHeader"}
			inputValue={uInpLookupKwd}
			dataItemArr={uLookupDataArr} 
			onKlikPilihItem={hdlKlikDlgLookuppilih}
			id="iddialoglookup"/>

		<MyDialogview
			options={{centered:false,bgColor:"black",size:"xl"}}
			renderContent={contentview()}
			dialogHeader={uDialogHeader}
			toggle={hdlToggleview}
			show={uIsShowview}/>

		<MyDialogform
			options={{centered:false}}
			onSimpan={()=>hdlKlikSimpanDlgform()}
			show={uIsshowDlgform} 
			dialogHeader={uHeaderDlgform}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>
		</>
	)//>
}
	
export default Ongoingitem;
