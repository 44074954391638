import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CRow,
	CCol,
	CCardBody,
	CCardFooter,
	CTooltip,
	CLink,
	CForm,CFormInput,CFormTextarea,
	CListGroup,
	CListGroupItem,
	CSpinner,
	CBadge,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu, CDropdownDivider,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {
	MyDialogview,
	MyDialogform,
	MyFormHapus,
	MyPagination,
	MyPage500,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson		= require('../../package.json');
const Belibarang= (props) => {
	const uComponentname 	= Belibarang.name;
	const {
		prosesExpired,setLoading,showToast,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  	= useNavigate();
	const uDispatch		= useDispatch();
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uActiveroute  = useSelector(state => state.activeRoute);
	const uHTMLstyleTabel=useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj		= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted= useSelector(state => state.gIsEOMExecuted);
	const uEfekapp		= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	//---VAR_GLOBALS--/
	const uSettingObj 	= useSelector(state => state.gListUserSetting);
	const uIsAllowedhapus= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && uIsEOMExecuted
	const [uFirstload,setFirstload]			= React.useState("YA");
	const uMaxData		= isMobile ? (uSettingObj.jml_mobile || 20) : (uSettingObj.jml_laporan || 100);

	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--DLG_HAPUS--/
	const [uISDlghapusshow,setDlghapusshow]	= React.useState(false);
	const [uDlghapusinput,setDlghapusinput]	= React.useState("");
	const [uDlghapusObj,setDlghapusObj]		= React.useState({});
	//--END DLG_HAPUS--/

	//--DLG_VIEW--/
	const [uISDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uHeaderDlgview,setHeaderDlgview]	= React.useState("");
	const [uDlgviewObj,setDlgviewObj]		= React.useState({});
	//--END DLG_VIEW--/

	//--DLG_FORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	//--END DLG_FORM--/

	const [uElBtnDlgsimpan,setElBtnDlgsimpan]= React.useState(document.getElementById("btnDialogSimpan"));
	const uElJmldata	= document.getElementById("idjmldata");
	//---END VAR_GLOBALS--/

	//---HANDLE--/
	const hdlToggleDlgview = () => { setDlgviewshow(false); }
	const hdlToggleForm = () => { setDlghapusshow(false); }
	const hdlKlikHapus =(_TABELIDX) => {
		_TABELIDX = (_TABELIDX ||"").trim();if(_TABELIDX === "") return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_TABELIDX,"tabel_idx");
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_TABELIDX);
		setTabelRowSelect(vRowIdx);
		
		uDlghapusObj.dlg_header = ((uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+
			(UFunc.TglAngka(uDatamainArr[vArridx].tanggal)+" "+(uDatamainArr[vArridx].supplier || "")+
				((uDatamainArr[vArridx].no_nota||"")!==""?" ("+uDatamainArr[vArridx].no_nota+")" : "")+
				"").toUpperCase());

		uDlghapusObj.tabeldata 	= uDatamainArr[vArridx].sorting_data||"A";
		uDlghapusObj.tabel_id 	= parseInt(uDatamainArr[vArridx].id)||0;
		uDlghapusObj.tabel_idx 	= _TABELIDX;//parseInt(uDatamainArr[vArridx].id)||0;
		setDlghapusshow(true);
	}
	const hdlKlikdetil = (_TABELIDX) => {
		_TABELIDX = (_TABELIDX ||"").trim();if(_TABELIDX === "") return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_TABELIDX,"tabel_idx");
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_TABELIDX);
		setTabelRowSelect(vRowIdx);

		const vCaptionUpper	= (
			uDatamainArr[vArridx].supplier_caption+
			((uDatamainArr[vArridx].no_nota||"")!==""?" ("+uDatamainArr[vArridx].no_nota+")":"")
		).toUpperCase();
		setHeaderDlgview(uBahasaObj.caption_lihatdetil+": "+vCaptionUpper);

		if((uDatamainArr[vArridx].sorting_data||"B") === "A"
			&& (uDatamainArr[vArridx].items_arr || []).length <= 0
		) {
			const vTmpitemArr = JSON.parse(uDatamainArr[vArridx].itemarr_str||"[]");
			uDatamainArr[vArridx].items_arr = vTmpitemArr.map(vItemtabel => {
				return {
					id: vItemtabel.itemid,
					barang_caption: vItemtabel.nama,
					no_batch: vItemtabel.batchno,
					exp_date: vItemtabel.expdate,
					h_satuan: vItemtabel.hsatuan,
					qty: vItemtabel.qty,
				}
			});
		}

		const vItemsArr			= uDatamainArr[vArridx].items_arr || [];
		uDlgviewObj.tabel_idx 	= _TABELIDX;
		if(vItemsArr.length > 0) {
			uDlgviewObj.items_arr	= vItemsArr;
			setDlgviewshow(true); return
		}

		apiLoaditem();
	}
	const hdlKlikValidasi = (_TABELIDX) => {
		_TABELIDX = (_TABELIDX ||"").trim();if(_TABELIDX === "") return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_TABELIDX,"tabel_idx");
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_TABELIDX);
		setTabelRowSelect(vRowIdx);

		if((uDatamainArr[vArridx].sorting_data||"B")!=="A") {
			showToast("UNDEFINED..","ERROR"); return;
		}

		const vCaptionUpper	= (
			uDatamainArr[vArridx].supplier_caption+
			((uDatamainArr[vArridx].no_nota||"")!==""?" ("+uDatamainArr[vArridx].no_nota+")":"")
		).toUpperCase();

		if((uDatamainArr[vArridx].sorting_data||"B") === "A"
			&& (uDatamainArr[vArridx].items_arr || []).length <= 0
		) {
			const vTmpitemArr = JSON.parse(uDatamainArr[vArridx].itemarr_str||"[]");
			uDatamainArr[vArridx].items_arr = vTmpitemArr.map(vItemtabel => {
				return {
					id: vItemtabel.itemid,
					barang_caption: vItemtabel.nama,
					no_batch: vItemtabel.batchno,
					exp_date: vItemtabel.expdate,
					h_satuan: vItemtabel.hsatuan,
					qty: vItemtabel.qty,
				}
			});
		}
		uDlgformObj.tabel_idx	= _TABELIDX;
		uDlgformObj.dlg_header 	= "Checking Validasi: "+vCaptionUpper;
		uDlgformObj.data_obj	= uDatamainArr[vArridx] || {};
		uDlgformObj.status_validasi	= "KOREKSI";
		uDlgformObj.catatan_validasi= uDatamainArr[vArridx].catatan_validasi;

		setDlgformshow(true);
	}
	const hdlKlikKoreksi = (_TABELIDX) => {
		_TABELIDX = (_TABELIDX ||"").trim();if(_TABELIDX === "") return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_TABELIDX,"tabel_idx");
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_TABELIDX);
		setTabelRowSelect(vRowIdx);

		if((uDatamainArr[vArridx].sorting_data||"B")!=="A") {
			showToast("UNDEFINED..","ERROR"); return;
		}

		uNavigate("/subkeuangan/belibarang/formmain/"+uDatamainArr[vArridx].id);
	}
	const hdlDlgformsimpan = async() => {
		//--VALIDASI--/
		let vElFocus = document.getElementById("inpcatatanvalidasi");
		if(uDlgformObj.status_validasi === "KOREKSI") {
			if((uDlgformObj.catatan_validasi||"").trim()==="") {
				vElFocus && vElFocus.focus();
				showToast("<b>Catatan Validasi Harus Diisi</b>.."+
					""); return;
			}

			apiProsesvalidasi();
			return;
		}
		//--END VALIDASI--/

		if(await Konfirm(
			"<b>Data Pembelian Akan DIAPPROVE</b>.."+
			"<br/>Apakah Anda Yakin ?"+
			"")) apiProsesvalidasi();
	}
	const hdlKlikDlghapussimpan = () => {
		if((uDlghapusinput||"").trim() === "") {
			document.getElementById("inpkethapus") &&
				document.getElementById("inpkethapus").focus();
			showToast((uBahasaObj.pesan_penghapusanharusdiisi||"pesan_penghapusanharusdiisi")); 
			return;
		}

		apiProseshapus();
	}
	//---END HANDLE--/

	//---Init_LOAD--/
	const initJmldata = () => {
		const vKeyword = uKeywordObj.kwd_belibarang || "";
		if (vKeyword.length > 2) 
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
	}
	const initFormAwal = () => {
		setTabelIDPilih(0);
		setDlghapusinput("");
		setDlghapusObj({});
	}

	const contentMain = () => {
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_belibarang || "");
		const vKeywordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems=> 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))
		//const vDataviewArr	= isMobile ? uDatamainArr.slice(0,(vMax+1)) : uDatamainArr.slice(vMin,(vMax+1));
		
		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
		<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
		<th width="5%" className="px-1">No</th>
		<th className="px-1">
			<CRow className="mx-0">
			<CCol md="2" className="text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
			<CCol md="4" lg="5" className="d-flex justify-content-between">
				<div className="text-start">{uBahasaObj.word_supplier||"word_supplier"}</div>
				<div className="text-center" style={{width:"30%"}}>{uBahasaObj.word_nonota||"No.Nota"}</div>
			</CCol>

			<CCol md="2" lg="1" className="px-1">{uBahasaObj.word_total||"word_total"} (Rp)</CCol>
			<CCol md="3" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
			<CCol md="1" className="">&middot;</CCol>
			</CRow>
		</th>
		</tr>
		<tr className="d-md-none">
			<th width={30} className="p-0"/>
			<th className="p-0"/>
		</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,tanggal,tgl_input,supplier_caption,userinput,terbayar,diskon,b_transport,
				items,no_nota,nilai,init_expired,init_loading,reklas_caption,status_jurnal,
				nilai_retur_terpakai,
				status_validasi,tabel_idx
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (UFunc.HariAngka(tanggal)+" "+supplier_caption+
				((no_nota||"")!=""?" ("+no_nota+")":"")
				).toUpperCase();

			const vTanggal	= vKeyword==="" ? UFunc.TglAngka(tanggal) 
				: (UFunc.TglAngka(tanggal)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vSupplier= vKeyword==="" ? (supplier_caption||"") 
				: (supplier_caption||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vReklascaption= vKeyword==="" ? (reklas_caption||"UNDF") 
				: (reklas_caption||"UNDF").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput= vKeyword==="" ? UFunc.Usercaptionfilter(userinput||"") 
				: UFunc.Usercaptionfilter(userinput||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vNonota= vKeyword==="" ? (no_nota||"-") 
				: (no_nota||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglInput	= vKeyword==="" ? UFunc.WaktuAngka(tgl_input) 
				: (UFunc.WaktuAngka(tgl_input)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");

			const vColorstatustembak= (status_jurnal||"")==="TEMBAK" ? "text-primary" : "";

			let vColorstatusinput;
			if((status_validasi||"APPROVED")==="VALIDASI") vColorstatusinput = "info";
			else if((status_validasi||"APPROVED")==="KOREKSI") vColorstatusinput = "warning";
			else vColorstatusinput = "success";

			return (
			<tr id={"idtr"+tabel_idx} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>
				<td className="text-end px-1">	
				<CRow className="mx-0">
				<CCol xs="8" md="2" className="px-1 text-md-start d-none d-md-block">
					<div>
						<span className="">{UFunc.renderHTML(vTanggal)}</span>
						{(uTokenObj.userhak === "FREEROLE") && (
							<span className="classfontsmaller"><small> &middot; ({tabel_idx})</small></span>
						)}
					</div>

					<div className="classfontsmaller text-danger">
					<small>[
						{((tgl_input||"")!=="") && (
						<>
							{UFunc.renderHTML(vTglInput)}
							<span className="mx-1">&middot;</span>
						</>
						)}	
						{UFunc.renderHTML(vUserinput)}
					]</small>
					</div>
				</CCol>

				<CCol md="4" lg="5" className="px-0 text-start">
					<div className="d-flex justify-content-between align-items-start">
						<div className="px-2">
						<div className="text-info">{UFunc.renderHTML(vSupplier)}</div>

						{((status_jurnal||"")!=="" && uTokenObj.userhak==="FREEROLE") && (
						<div className={"d-none d-md-block classfontsmaller "+vColorstatustembak}>
							<small>&middot; S.Jurnal: <b>{status_jurnal}</b> &middot;</small>
						</div>
						)}
						</div>

						{(init_loading||false)===true ? (
						<CSpinner color="dark" className="d-md-none px-1" size="sm"/>
						) : (
						<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm" className="p-0"
							color="transparent">
							<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikdetil(tabel_idx)}>
							<CIcon className="classikondetil me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_lihatdetil)}
						</CDropdownItem>

						{(status_validasi||"APPROVED") !== "APPROVED" && (
						<>
							{(uIsAllowedhapus && uIsEOMExecuted && (init_expired||"YA")==="TIDAK") && (
							<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikValidasi(tabel_idx)}>
								<CIcon icon="cilListHighPriority" className="classikoncoreui mx-0 me-2" height={25}/>
								Validasi Supervisor
							</CDropdownItem>
							)}

							<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikKoreksi(tabel_idx)}>
								<CIcon className="classikonedit me-2 align-middle" height={25}/>
								KOREKSI Pembelian
							</CDropdownItem>
						</>
						)}

						{(uIsAllowedhapus && uIsEOMExecuted && (init_expired||"YA")==="TIDAK") && (
						<>
							<CDropdownDivider/>
							<CDropdownItem onClick={()=>hdlKlikHapus(tabel_idx)}>
								<CIcon className="classikonhapus me-2 align-middle" height={25}/>
								{(uBahasaObj.caption_hapusdata)}
							</CDropdownItem>
						</>
						)}
						</CDropdownMenu>
						</CDropdown>
						)}

						<div className="px-2 text-center d-none d-md-block" 
							style={{width:"30%"}}>
						<small>{UFunc.renderHTML(vNonota)}</small>
						</div>
					</div>
				</CCol>

				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol xs="8" className="px-0 text-md-start d-md-none">
					<span className="">{UFunc.renderHTML(vTanggal)}</span>
					{(uTokenObj.userhak === "FREEROLE") && (
						<span className="classfontsmaller"><small> &middot; ({id})</small></span>
					)}
				</CCol>

				{(uTokenObj.userhak==="FREEROLE") && (
				<>
				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.caption_statusjurnal||"Status Jurnal"}</CCol>
				<CCol xs="8" className={"px-1 d-md-none classfontsmaller "+vColorstatustembak}>
					<b>{status_jurnal||"-"}</b>
				</CCol>
				</>
				)}

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_nonota}</CCol>
				<CCol xs="8" md="2" className="px-1 d-md-none text-md-start text-uppercase">
					<small>{UFunc.renderHTML(vNonota)}</small>
				</CCol>

				<CCol xs="3" className="px-1 text-start d-md-none classfontsmaller">{uBahasaObj.caption_inputby||"User"}</CCol>
				<CCol xs="9" className="px-1 d-md-none text-danger classfontsmaller">
					<small>
					[{(tgl_input||"")!=="" && (<>{UFunc.renderHTML(vTglInput)} &middot; </>)}{UFunc.renderHTML(vUserinput)}]
					</small>
				</CCol>
					
				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.word_total||"word_total"} (Rp)</CCol>
				<CCol xs="7" md="2" lg="1" className="px-1 px-md-0 text-success classfontsmaller">
					{UFunc.formatAngka(nilai)}
				</CCol>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_statusinput||"Status Input"}</CCol>
				<CCol xs="7" md="2" lg="1" className="px-1 d-md-none">
					<CBadge className={(status_validasi||"APPROVED")==="KOREKSI"?"classblinking":""} 
						color={vColorstatusinput}>{(status_validasi||"APPROVED")}</CBadge>
				</CCol>

				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

				{(parseFloat(nilai_retur_terpakai)||0) > 0 && (
				<>
				<CCol xs="6" className="px-1 text-start d-md-none">{uBahasaObj.caption_depositsupplier||"Deposit Supplier"} (Rp)</CCol>
				<CCol xs="6" className="px-1 d-md-none text-success">
					{UFunc.formatAngka(nilai_retur_terpakai)}
				</CCol>
				</>
				)}

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.word_terbayar||"Terbayar"} (Rp)</CCol>
				<CCol xs="7" className="px-1 d-md-none text-primary">
					{UFunc.formatAngka(terbayar)}
				</CCol>

				{((reklas_caption||"")!=="" || (parseFloat(terbayar)||0) > 0) && (
				<>
				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_kaspembayaran||"Kas Pembayaran"}</CCol>
				<CCol xs="7" className="px-1 d-md-none">
					<b className="text-uppercase">{UFunc.renderHTML(vReklascaption)}</b>
				</CCol>
				</>
				)}

				<CCol md="3" className="px-1 ps-2 d-none d-md-block text-md-start classfontsmaller">
					<div className="d-flex justify-content-between classborderbottom pb-2 mb-2">
						<span>Status Input</span>
						<CBadge className={(status_validasi||"APPROVED")==="KOREKSI"?"classblinking":""} 
							color={vColorstatusinput}>{(status_validasi||"APPROVED")}</CBadge>
					</div>

					{(parseFloat(nilai_retur_terpakai)||0) > 0 && (
					<div className="d-flex justify-content-between classborderbottom pb-2 mb-2">
						<span>Deposit Supplier (Rp)</span>
						<span className="text-success">{UFunc.formatAngka(nilai_retur_terpakai)}</span>
					</div>
					)}
					
					<div className="d-flex justify-content-between classborderbottom pb-2 mb-2">
						<span>Terbayar (Rp)</span>
						<span className="text-primary">{UFunc.formatAngka(terbayar)}</span>
					</div>

					{((reklas_caption||"")!=="" || (parseFloat(terbayar)||0) > 0) && (
					<div className="d-flex justify-content-between classborderbottom pb-2 mb-2">
						<span>Kas Pembayaran</span>
						<b className="text-uppercase text-end">{UFunc.renderHTML(vReklascaption)}</b>
					</div>
					)}
				</CCol>

				<CCol md="1" className="px-0 text-center d-none d-md-block">
				{(init_loading||false)===true ? (
					<CSpinner color="dark" size="sm"/>
				) : (
					<>
					<CTooltip content={"--"+(uBahasaObj.caption_lihatdetil||"caption_lihatdetil")+": "+vCaptionUpper}>
					<CLink 
						className="classcursorpointer classikontabel classikondetil mx-0"
						onClick={()=>hdlKlikdetil(tabel_idx)} />
					</CTooltip>

					{(status_validasi||"APPROVED") !== "APPROVED" ? (
					<CDropdown>
						<CDropdownToggle caret={false} size="sm" className="p-0 mx-0" color="transparent">
							<CIcon icon="cilChevronBottom" className="classikoncoreui" height={25}/>
						</CDropdownToggle>

						<CDropdownMenu>
						{(uIsAllowedhapus && uIsEOMExecuted && (init_expired||"YA")==="TIDAK") && (
						<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikValidasi(tabel_idx)}>
							<CIcon icon="cilListHighPriority" className="classikoncoreui mx-0 me-2" height={25}/>
							Validasi Supervisor
						</CDropdownItem>
						)}

						<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikKoreksi(tabel_idx)}>
							<CIcon className="classikonedit me-2 align-middle" height={25}/>
							KOREKSI Pembelian
						</CDropdownItem>
	
						{(uIsAllowedhapus && uIsEOMExecuted && (init_expired||"YA")==="TIDAK")  && (
						<>
							<CDropdownDivider/>
							<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikHapus(tabel_idx)}>
								<CIcon className="classikonhapus me-2 align-middle" height={25}/>
								{(uBahasaObj.caption_hapusdata)}
							</CDropdownItem>
						</>
						)}
						</CDropdownMenu>
					</CDropdown>
					) : (
					<>
						{(uIsAllowedhapus && uIsEOMExecuted && (init_expired||"YA")==="TIDAK")  && (
						<CTooltip className="d-none d-lg-block" content={"--Hapus Data: "+vCaptionUpper}>
						<CLink className="classcursorpointer classikontabel classikonhapus" onClick={()=>hdlKlikHapus(tabel_idx)}/>
						</CTooltip>
						)}
					</>
					)}
					</>
				)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)//>

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,uDlgviewObj.tabel_idx,"tabel_idx");
		if(vArridx < 0) return (<></>)//>

		const vDataitemsArr	= uDlgviewObj.items_arr||[];
		if(vDataitemsArr.length <= 0) return (<></>)//>

		const vSubtotal = vDataitemsArr.reduce((vSUM,vItems)=> vSUM + (
			(parseFloat(vItems.h_satuan)||0) * (parseInt(vItems.qty)||0)
		),0);
		
		return (
		<>
		<CRow className="align-items-stretch">
		<CCol lg="6" className="mb-3">
			<CCard className="classcardbg h-100">
			<CCardHeader className="py-0 pt-1 text-muted classfontsmaller"><small>Data Nota</small></CCardHeader>
			<CCardBody>
				<small>
				<div className="d-flex justify-content-between my-1">
				<div className="px-1">Tgl Pembelian</div>
				<div className="px-1 text-end">{UFunc.HariAngka(uDatamainArr[vArridx].tanggal)}</div>
				</div>
				
				<div className="d-flex justify-content-between my-1">
				<div className="px-1">Supplier</div>
				<div className="px-1 text-end text-info">{uDatamainArr[vArridx].supplier_caption}</div>
				</div>
				
				<div className="d-flex justify-content-between my-1">
				<div className="px-1">No.Nota</div>
				<div className="px-1 text-end">{uDatamainArr[vArridx].no_nota}</div>
				</div>
				
				<div className="d-flex justify-content-between my-1">
				<div className="px-1">Kas Pembayaran</div>
				<div className="px-1 text-end">{uDatamainArr[vArridx].reklas_caption||"-"}</div>
				</div>
				</small>
			</CCardBody>
			</CCard>
		</CCol>

		<CCol className="mb-3">
			<CCard className="classcardbg h-100">
			<CCardHeader className="py-0 pt-1 text-muted classfontsmaller"><small>Pembayaran</small></CCardHeader>
			<CCardBody>
				<small>
				<div className={"d-flex justify-content-between my-1"+((uDatamainArr[vArridx].sorting_data||"B")==="B"?" text-muted classfontsmaller":"")}>
				<div className="px-1">Diskon (Rp)</div>
				<div className="px-1 text-end">{UFunc.formatAngka(uDatamainArr[vArridx].diskon)}</div>
				</div>
				
				{(uDatamainArr[vArridx].sorting_data||"B")==="B" && (
				<div className="my-2 classborderbottom"/>
				)}

				<div className="d-flex justify-content-between my-1">
				<div className="px-1">B.Transport (Rp)</div>
				<div className="px-1 text-end">{UFunc.formatAngka(uDatamainArr[vArridx].b_transport)}</div>
				</div>

				<div className="d-flex justify-content-between my-1">
				<div className="px-1">Total Tagihan (Rp)</div>
				<div className="px-1 text-end fw-bolder">{UFunc.formatAngka(uDatamainArr[vArridx].nilai)}</div>
				</div>

				<div className="my-2 classborderbottom"/>

				{(parseFloat(uDatamainArr[vArridx].nilai_retur_terpakai)||0) > 0 && (
				<div className="d-flex justify-content-between my-1">
					<div className="px-1">Deposit Supplier (Rp)</div>
					<div className="px-1 text-end text-success">{UFunc.formatAngka(uDatamainArr[vArridx].nilai_retur_terpakai)}</div>
				</div>
				)}
				
				<div className="d-flex justify-content-between my-1">
				<div className="px-1">Terbayar &middot; Payment I (Rp)</div>
				<div className="px-1 text-end text-primary">{UFunc.formatAngka(uDatamainArr[vArridx].terbayar)}</div>
				</div>
				</small>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>

		<CRow>
		<CCol>
			<CCard className="classcardbg">
			<CCardHeader className="py-0 pt-1 text-muted classfontsmaller"><small>Item Pembelian</small></CCardHeader>
			<CCardBody className="px-0">
			<small>
			<table className="table table-border" id="idtabeldetil">
			<thead className="d-none">
			<tr>
				<th width={35} className="px-1">No</th>
				<th className="px-1">&nbsp;</th>
			</tr>
			</thead>
			<tbody>
			{vDataitemsArr.map((vItems,vKeys)=>{
				const {
					id,barang_caption,no_batch,exp_date,h_satuan,qty,satuan
				} = vItems;

				return (
				<tr className="align-top text-end" key={vKeys}>
					<td className="px-1 pe-0">{vKeys+1}.</td>
					<td className="px-1">
						<CRow className="mx-1">
						<CCol lg="5" className="text-start px-1 pe-0 text-capitalize">
							{barang_caption||"Undefined Barang"}
						</CCol>
						
						<CCol xs="12" className="px-1 classborderbottom d-lg-none my-2"/>

						<CCol xs="8" lg="5" className="px-0 d-none d-lg-flex justify-content-between">
							<small style={{width:"55%"}} className="text-lg-start px-1">
								{no_batch||""}
								
								{((no_batch||"")==="" && (exp_date||"")==="") 
									? (<>-</>)
									: (<span className="mx-1">&middot;</span>)}

								{(exp_date||"")!=="" && "("+UFunc.TglAngka(exp_date)+")"}
							</small>

							<div style={{width:"45%"}} className="px-1">
								<b className="text-primary">{UFunc.formatAngka(qty)}</b>
								<small>{satuan||"UNIT"}</small>
								<small className="mx-1">x</small>
								Rp<small className="fw-bolder">{UFunc.formatAngka(h_satuan)}</small>
							</div>
						</CCol>

						<CCol xs="4" className="px-1 text-start d-lg-none">{uBahasaObj.caption_batchno}</CCol>
						<CCol xs="8" className="px-1 d-lg-none">
							<small>
								{no_batch||""}
								
								{((no_batch||"")==="" && (exp_date||"")==="") 
									? (<>-</>)
									: (<span className="mx-1">&middot;</span>)}

								{(exp_date||"")!=="" && "("+UFunc.TglAngka(exp_date)+")"}
							</small>
						</CCol>
						
						<CCol xs="4" className="px-1 text-start d-lg-none">{uBahasaObj.caption_hsatuan}</CCol>
						<CCol xs="8" className="px-1 d-lg-none">
							<b className="text-primary">{UFunc.formatAngka(qty)}</b>
							<small>{satuan||"UNIT"}</small>
							<span className="mx-1">x</span>
							<span className="fw-bolder">Rp{UFunc.formatAngka(h_satuan)}</span>
						</CCol>

						<CCol xs="12" className="px-1 classborderbottom d-lg-none my-2"/>

						<CCol xs="4" className="px-1 text-start d-lg-none">{uBahasaObj.word_total}</CCol>
						<CCol xs="8" lg="2" className="px-1">
							<span className="text-success">{UFunc.formatAngka(qty * h_satuan)}</span>
						</CCol>
						</CRow>
					</td>
				</tr>
				)
			})}
			</tbody>
			<tfoot>
			<tr className="text-end bg-light">
				<td className="px-1"/>
				<td className="px-1">
				<CRow className="mx-1">
					<CCol className="px-1">JUMLAH</CCol>
					<CCol className="px-1 fw-bolder text-success">
						{UFunc.formatAngka(vSubtotal)}
					</CCol>
				</CRow>
				</td>
			</tr>
			</tfoot>
			</table>
			</small>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		const vDatanotaObj	= uDlgformObj.data_obj || {};
		const vItemsArr 	= vDatanotaObj.items_arr || [];

		return (
		<>
		<CRow className="align-items-stretch">
			<CCol lg="6" className="mb-3">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller"><small>Data Nota</small></CCardHeader>
			<CCardBody className="classfontsmaller py-1">
			<small>
				<div className="d-flex justify-content-between my-1">
					<span>Tgl Beli</span>
					<b className="text-end">{UFunc.TglAngka(vDatanotaObj.tanggal)}</b>
				</div>
				<div className="d-flex justify-content-between my-1">
					<span>No.Nota</span>
					<b className="text-end">{(vDatanotaObj.no_nota||"-")}</b>
				</div>
				<div className="d-flex justify-content-between my-1">
					<span>Supplier</span>
					<b className="text-end">{(vDatanotaObj.supplier_caption)}</b>
				</div>
				<div className="d-flex justify-content-between my-1">
					<span>Akun KAS</span>
					<b className="text-end">{(vDatanotaObj.reklas_caption || "-")}</b>
				</div>

				<div className="my-2 classborderbottom"/>

				<div className="d-flex justify-content-between my-1">
					<span>N.Transaksi (Rp)</span>
					<b className="text-end">{UFunc.formatAngka(vDatanotaObj.nilai_bruto)}</b>
				</div>

				<div className="d-flex justify-content-between my-1">
					<span>Diskon (Rp)</span>
					<span className="text-end text-danger">{UFunc.formatAngka(vDatanotaObj.diskon)}</span>
				</div>

				<div className="d-flex justify-content-between my-1">
					<span>B.Transport (Rp)</span>
					<span className="text-end text-danger">{UFunc.formatAngka(vDatanotaObj.b_transport)}</span>
				</div>

				{(parseFloat(vDatanotaObj.nilai_retur_terpakai)||0) > 0 && (
				<div className="d-flex justify-content-between my-1">
					<span>Deposit Supplier (Rp)</span>
					<b className="text-end text-success">{UFunc.formatAngka(vDatanotaObj.nilai_retur_terpakai)}</b>
				</div>
				)}

				<div className="d-flex justify-content-between my-1">
					<span>Terbayar I (Rp)</span>
					<b className="text-end text-primary">{UFunc.formatAngka(vDatanotaObj.terbayar)}</b>
				</div>

				<div className="d-flex justify-content-between my-1">
					<span>Sisa Hutang (Rp)</span>
					<b className="text-end text-danger">{UFunc.formatAngka(
						(parseFloat(vDatanotaObj.nilai)||0) 
							- (parseFloat(vDatanotaObj.nilai_retur_terpakai)||0)
							- (parseFloat(vDatanotaObj.terbayar)||0))
					}</b>
				</div>

				<div className="my-2 classborderbottom"/>

				<div className="d-flex justify-content-between my-1">
					<span>Tgl Input</span>
					<span className="text-end" style={{color:"maroon"}}>
						{UFunc.WaktuAngka(vDatanotaObj.tgl_input_real)}
						<span className="mx-1">&middot;</span>
						[{UFunc.Usercaptionfilter(vDatanotaObj.userinput||"UNDF")}]
					</span>
				</div>

				<div className="d-flex justify-content-between my-1">
					<span>Koreksi Terakhir</span>
					<span className="text-end" style={{color:"maroon"}}>
						{UFunc.WaktuAngka(vDatanotaObj.tgl_koreksi_real)}
						<span className="mx-1">&middot;</span>
						[{UFunc.Usercaptionfilter(vDatanotaObj.userupdate||(vDatanotaObj.userinput||"UNDF"))}]
					</span>
				</div>
			</small>
			</CCardBody>
			</CCard>
			</CCol>

			<CCol className="mb-3">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller"><small>Data Item</small></CCardHeader>
			<CCardBody className="classfontsmaller py-1 px-0">
			<small>
				<table className="table table-borderless">
				<thead>
					<tr>
					<th width={30} className="p-0"/>
					<th className="p-0"/>
					<th width="23%" className="p-0"/>
					</tr>
				</thead>

				<tbody>
				{vItemsArr.map((vItems,vKeys)=>{

					return (
					<tr className="align-top classborderbottom" key={vKeys}>
					<td className="px-1 text-end">{(vKeys+1)}.</td>
					<td className="px-1 text-start">
						<div className="fst-italic">
						{vItems.barang_caption || "Undf Barang"}
						<span className="mx-1">&middot;</span>
						(<b className="text-primary">
							{UFunc.formatAngka(vItems.qty)}</b>{vItems.satuan||"PCS"}
							<span className="mx-1">x</span>
							<span className="text-success">{UFunc.formatAngka(vItems.h_satuan)}</span>
						)
						</div>

						{(vItems.no_batch||"").trim() !== "" && (
							<div>
							Batch: {vItems.no_batch}
							<b className="ms-1">({UFunc.TglAngka(vItems.exp_date)})</b>
							</div>
						)}
					</td>

					<td className="px-1 text-end fw-bolder">
						{UFunc.formatAngka(
							(parseInt(vItems.qty)||0) * (parseFloat(vItems.h_satuan)||0)
						)}
					</td>
					</tr>
					)
				})}
				</tbody>
				</table>
			</small>
			</CCardBody>
			</CCard>
			</CCol>
		</CRow>

		<CForm>
		<CCard className="">
		<CCardHeader>
			<input type="radio" name="inpchkstatus" 
				value="KOREKSI"
				checked={uDlgformObj.status_validasi === "KOREKSI"}
				onChange={(e)=>{uDlgformObj.status_validasi=e.target.value; setEfekview(!uEfekview)}}
				id="inpchkstatuskoreksi"/>
			<label className="ms-1 align-top classcursorpointer fw-bolder" for="inpchkstatuskoreksi">
			Data Masih HARUS DIKOREKSI oleh ADMIN, dengan Catatan Seperti Dibawah Ini:
			</label>
		</CCardHeader>
		<CCardBody className={"px-lg-5"+(uDlgformObj.status_validasi!=="KOREKSI"?" text-muted bg-light":"")}>
			<CFormTextarea size="sm"
				rows={3}
				disabled={uDlgformObj.status_validasi!=="KOREKSI"}
				value={UFunc.br2nl(uDlgformObj.catatan_validasi||"")}
				onChange={(e)=>{uDlgformObj.catatan_validasi=UFunc.nl2br(e.target.value);setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpcatatanvalidasi"/>
			<div className="classpetunjuk">
			*) Ganti Baris Tekan [CTRL] + [SHIFT]
			</div>
		</CCardBody>
		</CCard>

		<CCard className="mt-3">
		<CCardHeader>
			<input type="radio" name="inpchkstatus"
				value="APPROVED"
				checked={uDlgformObj.status_validasi === "APPROVED"}
				onChange={(e)=>{uDlgformObj.status_validasi=e.target.value; setEfekview(!uEfekview)}}
				id="inpchkstatusvalidasi"/>
			<label className="ms-1 align-top classcursorpointer fw-bolder" for="inpchkstatusvalidasi">
			Data SUDAH DIKOREKSI Supervisor dan berstatus APPROVED.
			</label>
		</CCardHeader>
		<CCardBody className={"px-lg-5"+(uDlgformObj.status_validasi!=="APPROVED"?" text-muted bg-light":"")}>
			<i>Proses APRROVE ini Hanya dilakukan SEKALI dan TIDAK Dapati DIULANGI.</i>.
		</CCardBody>
		</CCard>
		</CForm>
		</>
		)//>
	}
	//---END Init_LOAD--/

	//---API_FUNC--/
	const apiLoaddata = (_PAGE) => {
		uFuncname = apiLoaddata.name;
		_PAGE		= parseInt(_PAGE) || 1;
		setHTMLError500();

		const vKeyword = uKeywordObj.kwd_belibarang || "";
		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_belibarang/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoading(false);
			if(output_string.tampil) {
				//console.log("("+uComponentname+"-"+uFuncname+") dataobject : "+output_string.dataobject);
				let vTmpArr = JSON.parse(output_string.dataobject||"[]");
				vTmpArr.map(vItems=>{ vItems.pg = _PAGE; });

				setDatamainArr([...vDatamainArr,...vTmpArr]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpArr.length);

				setFirstload("TIDAK");

				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaditem = () => {
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,uDlgviewObj.tabel_idx,"tabel_idx");
		uDatamainArr[vArridx].init_loading	= true;
		setEfekview(!uEfekview);

		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") _IDTABEL : "+_IDTABEL);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uDatamainArr[vArridx].id,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_belibarang/ld_item";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			uDatamainArr[vArridx].init_loading	= false;

			if(output_string.tampil) {
 				const vItemsArr 				= JSON.parse(output_string.dataitems||"[]");
 				uDatamainArr[vArridx].items_arr 	= vItemsArr;
				uDlgviewObj.items_arr	= vItemsArr;
				setDlgviewshow(true);
			} else {
				setEfekview(!uEfekview);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired(); return
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].init_loading	= false;
			setEfekview(!uEfekview);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = () => {
		if((uDlghapusObj.tabel_idx||"") === "") {showToast("[0] ERROR !"); return;}
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,uDlghapusObj.tabel_idx,"tabel_idx");

		const vElbtnsimpan	= document.getElementById("btnDialogSimpan");
		vElbtnsimpan && (vElbtnsimpan.disabled = true);
		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uDatamainArr[vArridx].id,
			send_tabelhapus : (uDatamainArr[vArridx].sorting_data||"A")==="A"?"tpembelian_tmp":"tpembelian",
			send_kethapus : uDlghapusinput,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_belibarang/pr_hapus";

		setLoading(true);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoading(false);
			vElbtnsimpan && (vElbtnsimpan.disabled = false);

			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				//setEfekview(!uEfekview);
				hdlToggleForm();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Belibarang - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				uElBtnDlgsimpan.disabled = true;
				setLoading(true);
				prosesExpired(); return;
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			vElbtnsimpan && (vElbtnsimpan.disabled = false);
			setLoading(false);
			console.log("(Belibarang - apiProseshapus) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesvalidasi = () => {
		if((uDlgformObj.tabel_idx||"") === "") {showToast("[0] ERROR !"); return;}
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,uDlgformObj.tabel_idx,"tabel_idx");

		const vElbtnsimpan	= document.getElementById("btnDialogSimpan");
		vElbtnsimpan && (vElbtnsimpan.disabled = true);

		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uDatamainArr[vArridx].id,
			send_statusvalidasi	: uDlgformObj.status_validasi,
			send_catatanvalidasi : uDlgformObj.catatan_validasi,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_belibarang/pr_validasi";

		setLoading(true);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoading(false);
			vElbtnsimpan && (vElbtnsimpan.disabled = false);

			if(output_string.sukses) {
				if(uDlgformObj.status_validasi === "KOREKSI") {
					uDatamainArr[vArridx].status_validasi 	= uDlgformObj.status_validasi;
					uDatamainArr[vArridx].catatan_validasi 	= uDlgformObj.catatan_validasi;
				}
				else {
					const vTabelid 	= parseInt(output_string.tabelid || "255");
					uDatamainArr[vArridx].status_validasi = uDlgformObj.status_validasi;
					uDatamainArr[vArridx].id 			= vTabelid;
					uDatamainArr[vArridx].tabel_idx 	= "B"+vTabelid;
					uDatamainArr[vArridx].sorting_data 	= "B";
					uDatamainArr[vArridx].itemarr_str 	= undefined;
					uDatamainArr[vArridx].items_arr 	= undefined;
					uDatamainArr[vArridx].tgl_input 	= UFunc.DbDatetime();
					uDatamainArr[vArridx].tgl_input_real= UFunc.DbDatetime();
				}

				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Belibarang - apiProsesvalidasi) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				uElBtnDlgsimpan.disabled = true;
				setLoading(true);
				prosesExpired(); return;
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			vElbtnsimpan && (vElbtnsimpan.disabled = false);
			setLoading(false);
			console.log("(Belibarang - apiProsesvalidasi) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();
		
		return()=>{ 
			uDispatch({type: "set", gInitHeaderAction: {}});
			hdlToggleForm(); hdlToggleDlgview(); setDlgformshow(false);
			setDatamainArr([]); 
		}
	},[]);
	React.useEffect(()=>{
		if(!uElJmldata) return;
		initJmldata()
	},[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData]);
	React.useEffect(()=>{
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.uHandelTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uIsEOMExecuted) return;

		uNavigate("/subkeuangan/belibarang/formmain/0");
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!uISDlghapusshow) {
			initFormAwal();
			return;
		}

		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			const vElFocus = document.getElementById("inpketerangan");
			if(!isMobile) vElFocus && vElFocus.focus();
		},350);
	},[uISDlghapusshow]);
	React.useEffect(()=>{
		if(!uISDlgviewshow) {
			setDlgviewObj({}); return;
		}
	},[uISDlgviewshow]);
	React.useEffect(()=>{
		if(!uISDlgformshow) 
			{ setDlgformObj({}); return }

		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			const vElFocus = document.getElementById("inpketerangan");
			if(!isMobile) vElFocus && vElFocus.focus();
		},350);
	},[uISDlgformshow]);
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>

	return (
		<>
		<CCard className="classcardbg mb-3">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-md-none fw-bolder">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_belibarang || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
				<span className="d-none d-lg-block">{uBahasaObj.caption_rekapinput||"Rekap Input"}</span>
			</small>

			<div className="d-lg-none"/>
			<div className="text-muted classfontsmaller"><small>{UFunc.formatAngka(uTimeElapsed)}ms</small></div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}
				></MyPagination>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uISDlgviewshow} 
			dialogHeader={uHeaderDlgview}
			toggle={hdlToggleDlgview}
			renderContent={contentDlgview()}/>

		<MyDialogform
			options={{size:"xl",centered:false}}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.dlg_header}
			toggle={()=>setDlgformshow(false)}
			onSimpan={hdlDlgformsimpan}
			renderContent={contentDlgform()}/>

		<MyFormHapus
			value={uDlghapusinput}
			onCHvalue={(e)=>setDlghapusinput(e.target.value)}
			onSimpan={()=>hdlKlikDlghapussimpan()}
			show={uISDlghapusshow} 
			dialogHeader={uDlghapusObj.dlg_header||""}
			toggle={hdlToggleForm}/>
		</>
	)//>
}
	
export default Belibarang;