import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,CFormInput,CInputGroup,CInputGroupText,
	CButton,
	CImage,
	CCard,CCardHeader,CCardFooter,CCardBody,
	CLink,CTooltip,
	CDropdown,CDropdownItem,CDropdownToggle,CDropdownMenu,CDropdownDivider,
	CSpinner,
} from '@coreui/react';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogview,
	MyPagination,
	MyPage500,
	MyFormHapus,
} from '../components/index'
import { UFunc, cKeycodebypass } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 	= require('../../package.json')
const Returbeli = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uSettingObj	 		= useSelector(state => state.gListUserSetting);
	const uIsEOMExecuted		= useSelector(state => state.gIsEOMExecuted);
	const [uEfekview,setEfekview]= React.useState(false);

	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uSettingObj.jml_mobile || 20) : (uSettingObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderactionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderactionObj.isHeaderView || false;
	const uHeaderTambah 		= uHeaderactionObj.isHeaderTambah || false;

	//---PAGINATION_DIATUR_BACKEND--/
	const uTabelSelectObj 		= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 		= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//---END PAGINATION_DIATUR_BACKEND--*/

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uFirstload,setFirstload]			= React.useState("YA");
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uDataposakunArr,setDataposakunArr]= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	//const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);

	//--DIALOG_FORM--/
	//--END DIALOG_FORM--*/

	//--DIALOG_VIEW--/
	const [uISDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uDlgviewObj,setDlgviewObj]		= React.useState({});
	//--END DIALOG_VIEW--*/

	//--DLG_HAPUS--/
	const [uISDlghapusshow,setDlghapusshow]	= React.useState(false);
	const [uDlghapusinput,setDlghapusinput]	= React.useState("");
	const [uDlghapusObj,setDlghapusObj]		= React.useState({});
	//--END DLG_HAPUS--/

	const uElJmldata			= document.getElementById("idjmldata");

	//---HANDLE--/
	const hdlKlikLihatdetil = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL); 
		const vCaptionupper = UFunc.Usercaptionfilter(uDatamainArr[vArridx].supplier_caption,3).toUpperCase();
		initSelectrow(_IDTABEL);

		uDlgviewObj.dlg_header 	= "Lihat Detil: "+vCaptionupper;
		uDlgviewObj.tabelid 	= _IDTABEL;
		if((uDatamainArr[vArridx].items_arr||[]).length > 0) {
			uDlgviewObj.detil_obj 	= uDatamainArr[vArridx] || {};
			setDlgviewshow(true);
			return;
		}

		apiLoaddetil();
	}
	const hdlKlikHapus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL); 
		const vCaptionupper = UFunc.Usercaptionfilter(uDatamainArr[vArridx].supplier_caption,3).toUpperCase();
		initSelectrow(_IDTABEL);

		uDlghapusObj.tabelid 		= _IDTABEL;
		uDlghapusObj.dlg_header 	= "Hapus Retur: "+vCaptionupper;

		setDlghapusshow(true);
	}
	const hdlKlikDlghapussimpan = () => {
		if((uDlghapusinput||"").trim() === "") {
			document.getElementById("inpkethapus") &&
				document.getElementById("inpkethapus").focus();
			showToast((uBahasaObj.pesan_penghapusanharusdiisi||"pesan_penghapusanharusdiisi")); 
			return;
		}

		apiProseshapus();
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		const vKeyword = uKeywordObj.kwd_returbeli || "";
		if (vKeyword.length < 3) {
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
			return;
		} //-*/

		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initSelectrow = (_IDTABEL) => {
		setTabelRowSelect(-1);
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_returbeli || "");
		const vKeywordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		//--PAGINATION_DIATUR_BACKEND--/
		const vDataviewArr	= uDatamainArr.filter(vItems=> parseInt(vItems.pg||1)===parseInt(uPageActive||1))
		//--END PAGINATION_DIATUR_BACKEND--*/

		/*//--PAGINATION_DIATUR_FRONTEND--/
		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));
		//--END PAGINATION_DIATUR_FRONTEND--*/

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width={35} className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="3" lg="2" className="text-start">{uBahasaObj.caption_tglretur||"Tgl Retur"}</CCol>
				<CCol md="3" className="text-start">{uBahasaObj.word_supplier||"Supplier"}</CCol>
				<CCol md="2">{uBahasaObj.caption_totalretur||"Total Retur"}<small className="d-block">(Rp)</small></CCol>
				<CCol md="3" lg="4" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
				<CCol md="1" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>
			<tr className="d-md-none classfontsmaller">
			<th width={35} className="py-0"/>
			<th className="py-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,supplier_caption,supplier_id,total_retur,supplier_norek,
				usercaption,tgl_input,supplier_telp,supplier_hp,tgl_retur,
				status_expired,is_processing,status_jurnal,
			} = vItems;

			vNumber++;
			const vCaptionupper	= UFunc.Usercaptionfilter((supplier_caption||"Undf"),3).toUpperCase();

			const vTanggal	= vKeyword==="" ? UFunc.TglAngka(tgl_retur) 
				: (UFunc.TglAngka(tgl_retur)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vSuppliercaption	= vKeyword==="" ? (supplier_caption||"Undf") 
				: (supplier_caption||"Undf").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vSuppliernorek	= vKeyword==="" ? (supplier_norek||"-") 
				: (supplier_norek||"-").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vSupplierhp	= vKeyword==="" ? (supplier_hp||"") 
				: (supplier_hp||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vSuppliertelp	= vKeyword==="" ? (supplier_telp||"-") 
				: (supplier_telp||"-").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter(usercaption,2) 
				: UFunc.Usercaptionfilter(usercaption,2).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vTglInput	= vKeyword==="" ? UFunc.WaktuAngka(tgl_input) 
				: (UFunc.WaktuAngka(tgl_input)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");

			const vISTglinputdiff	= UFunc.TglAngka(tgl_retur) !== UFunc.TglAngka(tgl_input);
			const vISNotexpired		= (status_expired||"YA") === "TIDAK";

			return (
			<tr id={"idtr"+id} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}
				key={vKeys}>
			<td className="text-end px-1 pe-0">{vNumber}.</td>
			<td className="text-end px-1">
				<CRow className="mx-0">
				<CCol md="3" lg="2" className="px-1 text-start d-none d-md-block">
					<div className="">
						{UFunc.renderHTML(vTanggal)}
						{uTokenObj.userhak==="FREEROLE" && (
						<>
							<small className="mx-1">&middot;</small>
							<span className="classfontsmaller"><small>({id})</small></span>
						</>
						)}
					</div>
					<div className="text-danger classfontsmaller d-none d-md-block">
					<small>[
						{vISTglinputdiff && (
						<>
							{UFunc.renderHTML(vTglInput)}
							<span className="mx-1">&middot;</span>
						</>
						)}
						<span>{UFunc.renderHTML(vUserinput.toUpperCase())}</span>
					]</small>
					</div>

					<div className="d-none">supplier_hp = {vSupplierhp}</div>
				</CCol>

				<CCol xs="12" md="3" className="px-1 text-start">
					<div className=" d-flex justify-content-between">
					<div className="text-info text-capitalize">{UFunc.renderHTML(vSuppliercaption)}</div>

					{(is_processing||false)===true ? (
						<CSpinner color="primary" className="d-md-none" size="sm"/>
					) : (
					<CDropdown className="d-md-none">
					<CDropdownToggle color="transparent" caret={false} className="p-0">
						<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
					</CDropdownToggle>

					<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikLihatdetil(id)}>
						<CIcon className="classikondetil align-middle mx-0 me-2" height={25}/>
						{uBahasaObj.caption_lihatdetil||"Lihat Detil"}
						</CDropdownItem>
						
						{(uIsAllowedhapus && vISNotexpired && uIsEOMExecuted) && (
						<>
							<CDropdownDivider/>
							<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
							{uBahasaObj.caption_hapusdata||"Hapus Data"}
							</CDropdownItem>
						</>
						)}
					</CDropdownMenu>
					</CDropdown>
					)}
					</div>

					{(uTokenObj.userhak==="FREEROLE" && (status_jurnal||"")!=="") && (
					<div className="classfontsmaller">
					<small>&middot; S.Jurnal:<b className="ms-1">{status_jurnal}</b> &middot;</small>
					</div> 
					)}
				</CCol>

				<CCol xs="12" className="px-1 d-md-none classborderbottom my-3"/>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_tglretur||"Tgl Retur"}</CCol>
				<CCol xs="6" md="2" className="px-1 d-md-none">
					{UFunc.renderHTML(vTanggal)}
					{uTokenObj.userhak==="FREEROLE" && (
					<>
						<small className="mx-1">&middot;</small>
						<span className="classfontsmaller"><small>({id})</small></span>
					</>
					)}
				</CCol>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_totalretur||"Total Retur"} (Rp)</CCol>
				<CCol xs="6" md="2" className="px-1 text-success">
					{UFunc.formatAngka(total_retur)}
				</CCol>

				<CCol md="3" lg="4" className="px-1 d-none d-md-block classfontsmaller">
					<small>
					<div className="d-flex justify-content-between">
						<div>Telp/HP</div>
						<b>{UFunc.renderHTML(vSuppliertelp)}</b>
					</div>

					{((supplier_norek||"")!=="") && (
					<>
					<div className="my-2 classborderbottom"/>
					<div className="d-flex justify-content-between">
						<div>No.Rek</div>
						<b>{UFunc.renderHTML(vSuppliernorek)}</b>
					</div>
					</>)}
					</small>
				</CCol>

				<CCol xs="12" className="px-1 d-md-none classborderbottom my-3"/>

				<CCol xs="4" className="px-1 d-md-none text-start classfontsmaller">{uBahasaObj.caption_norek||"No.Rek"}</CCol>
				<CCol xs="8" className="px-1 d-md-none classfontsmaller">
					<b>{UFunc.renderHTML(vSuppliernorek)}</b>
				</CCol>

				<CCol xs="3" className="px-1 d-md-none text-start classfontsmaller">{uBahasaObj.caption_telphp||"Telp/HP"}</CCol>
				<CCol xs="9" className="px-1 d-md-none classfontsmaller">
					<b>{UFunc.renderHTML(vSuppliertelp)}</b>
				</CCol>

				{(uTokenObj.userhak==="FREEROLE") && (
				<>
				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.caption_statusjurnal||"Status Jurnal"}</CCol>
				<CCol xs="8" className={"px-1 d-md-none classfontsmaller"}>
					<b>{status_jurnal||"-"}</b>
				</CCol>
				</>
				)}

				<CCol xs="4" className="px-1 d-md-none text-start classfontsmaller">{uBahasaObj.caption_inputby||"Input By"}</CCol>
				<CCol xs="8" className="px-1 d-md-none text-md-start classfontsmaller text-danger">
					<small>[
						{vISTglinputdiff && (
						<>
							{UFunc.renderHTML(vTglInput)}
							<span className="mx-1">&middot;</span>
						</>
						)}
						<span>{UFunc.renderHTML(vUserinput.toUpperCase())}</span>
					]</small>
				</CCol>
				
				<CCol md="1" className="px-0 text-md-center d-none d-md-block">
					{(is_processing||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<>
						<CTooltip className="d-none d-md-block" content={"--"+(uBahasaObj.caption_lihatdetil||"Lihat Detil")+": "+vCaptionupper}>
						<CLink onClick={()=>hdlKlikLihatdetil(id)} className="classcursorpointer classikontabel classikondetil"/>
						</CTooltip>

						{(uIsAllowedhapus && vISNotexpired && uIsEOMExecuted) && (
						<CTooltip className="d-none d-md-block" content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionupper}>
						<CLink onClick={()=>hdlKlikHapus(id)} className="classcursorpointer classikontabel classikonhapus"/>
						</CTooltip>
						)}
					</>
					)}
				</CCol>
				</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)//>

		const vDataviewObj	= uDlgviewObj.detil_obj || {}; 
		const vTotals		= (vDataviewObj.items_arr||[]).reduce((vSUM,vItems)=>
			vSUM + ((parseFloat(vItems.h_satuan)||0) * (parseInt(vItems.qty)||1))
			, 0);

		return (
		<>
		<CCard>
			<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
				<small>Data Retur</small>
			</CCardHeader>
			<CCardBody>
			<div className="d-flex justify-content-between my-1">
				<div>Tgl Retur</div>
				<b>{UFunc.TglAngka(vDataviewObj.tgl_retur)}</b>
			</div>

			<div className="d-flex justify-content-between my-1">
				<div>Supplier</div>
				<b>{(vDataviewObj.supplier_caption || "UNDF Supplier")}</b>
			</div>

			<div className="d-flex justify-content-between my-1">
				<div>Total Retur</div>
				<b className="text-success">{UFunc.formatAngka(vDataviewObj.total_retur)}</b>
			</div>
			</CCardBody>
		</CCard>

		<CCard className="mt-3">
			<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
				<small>Item Retur</small>
			</CCardHeader>
			<CCardBody className="px-0 classfontsmaller">
			<table className="table table-borderless table-striped" id="idtabelitem">
			<thead>
				<tr className="d-none classfontsmaller">
				<th width={35} className="p-0"/>
				<th className="p-0"/>
				<th width="22%" className="p-0"/>
				<th width="25%" className="p-0"/>
				<th width="20%" className="p-0"/>
				</tr>
			</thead>

			<tbody>
			{(vDataviewObj.items_arr||[]).map((vItems,vKeys)=>{
				const {
					id,barang_id,barang_caption,h_satuan,qty,
					no_batch,exp_date
				} = vItems;

				return (
				<tr className="align-top" key={vKeys}>
					<td className="px-1 text-end">{vKeys+1}.</td>
					<td className="px-1">{barang_caption||"Undf Barang"}</td>
					<td className="px-1">
						<small>
						{(no_batch||"")!=="" && (<b>{no_batch}</b>)}
						
						{((no_batch||"")!=="" && (exp_date||"")!=="") && 
							(<span className="mx-1">&middot;</span>)}

						{(exp_date||"")!=="" && (<span>{UFunc.TglAngka(exp_date)}</span>)}
						</small>
					</td>
					<td className="px-1 text-end">
						<b className="text-primary">{UFunc.formatAngka((parseInt(qty)||1))}</b>PCS
						<span className="mx-1">x</span>
						Rp<b>{UFunc.formatAngka((parseFloat(h_satuan)||0))}</b>

					</td>
					<td className="px-1 text-end text-success">
						{UFunc.formatAngka((parseFloat(h_satuan)||0) * (parseInt(qty)||1))}
					</td>
				</tr>
				)//>
			})}
			</tbody>

			<tfoot className="border-top fw-bolder">
				<tr className="align-top">
				<td colSpan={4} align="center">JUMLAH</td>
				<td align="right">{UFunc.formatAngka(vTotals)}</td>
				</tr>
			</tfoot>
			</table>
			</CCardBody>
		</CCard>
		</>
		)//>
	}
	//---END CONTENT--/

	//---API--/
	const apiLoaddata = (_PAGE) => {
		setHTMLError500();

		const vKeyword 		= uKeywordObj.kwd_returbeli || "";
		//--PAGINATION_DIATUR_BACKEND--/
		_PAGE		= parseInt(_PAGE)||1;
		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		//--END PAGINATION_DIATUR_BACKEND--*/

		/*//--PAGINATION_DIATUR_FRONTEND--/
		setJmlData(0);
		setDatamainArr([]);
		setTabelRowSelect(-1);
		setPageActive(1);
		//--END PAGINATION_DIATUR_FRONTEND--*/
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg	: _PAGE,//--PAGINATION_DIATUR_BACKEND-
			send_maxdata	: uMaxData,//--PAGINATION_DIATUR_BACKEND-
			send_loadjml	: vHandelview,//--PAGINATION_DIATUR_BACKEND-
			//send_firstload	: uFirstload,//--PAGINATION_DIATUR_FRONTEND
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_returbeli/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			if(output_string.tampil) {
				console.log("(Returbeli - apiLoaddata) output_string.totaldata : "+output_string.totaldata);
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]")
				//--PAGINATION_DIATUR_BACKEND--/
				vLoadArr.map(vItems=>vItems.pg = _PAGE);
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vLoadArr.length);

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}
				//--END PAGINATION_DIATUR_BACKEND--*/

				/*//--PAGINATION_DIATUR_FRONTEND--/
				setDatamainArr(vLoadArr);
				//--END PAGINATION_DIATUR_FRONTEND--*/

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Returbeli-apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Returbeli-apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaddetil = () => {
		const vTabelid	= parseInt(uDlgviewObj.tabelid) || 0;
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,vTabelid);
		uDatamainArr[vArridx].is_processing = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_returbeli/ld_detil";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].is_processing = false;
			if(output_string.tampil) {
				//console.log("(Returbeli - apiLoaddetil) vTabelid : "+vTabelid);
				const vTmpArr	= JSON.parse(output_string.dataitem || "[]");
				uDatamainArr[vArridx].items_arr = vTmpArr;
				uDlgviewObj.detil_obj	= uDatamainArr[vArridx] || {};

				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Returbeli-apiLoaddetil) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_processing = false;
			console.log("(Returbeli-apiLoaddetil) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = () => {
		const vTabelid	= parseInt(uDlghapusObj.tabelid) || 0;
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,vTabelid);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
				
			uDatamainArr.splice(vArridx,1);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelid,
			send_kethapus : uDlghapusinput,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_returbeli/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				initSelectrow();
				setDlghapusshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Returbeli-apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Returbeli-apiProseshapus) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		//if(!uIsInitbefore) 
			apiLoaddata();

		return () => {
			setFirstload("YA");
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDatamainArr([]); setDataposakunArr([]);
			setDlgviewshow(false); setDlghapusshow(false); 
		}
	},[])
	//--PAGINATION_DIATUR_BACKEND--/
	React.useEffect(()=>{
		//console.log("("+uComponentname+"-[uTabelSelectObj]) uTabelSelectObj = "+(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit); apiLoaddata(vPageinit);
	},[uIsInitbefore]);
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		const vInitID	= uTabelSelectObj.initID;
		initSelectrow(vInitID);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Returbeli - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 
	},[uInitbeforeprocess]);
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		//setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]); 
	//--END PAGINATION_DIATUR_BACKEND--*/

	/*//--PAGINATION_DIATUR_FRONTEND--/
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length);
	},[uDatamainArr,uDatamainArr.length])
	//--END PAGINATION_DIATUR_FRONTEND--*/
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderactionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderactionObj});

		//--PAGINATION_DIATUR_BACKEND--/
		setJmlData(0);
		setDatamainArr([]);
		setTabelRowSelect(-1);
		setPageActive(1);
		//--END PAGINATION_DIATUR_BACKEND--*/

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHeaderTambah) return;

		uHeaderactionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderactionObj});

		if(uHTMLError500) return;

		uNavigate("/subpurchase/returbeli/forminput");
	},[uHeaderTambah]);
	React.useEffect(()=>{
		if(!uISDlgviewshow) 
			{ setDlgviewObj({}); return; }
	},[uISDlgviewshow]);
	React.useEffect(()=>{
		if(!uISDlghapusshow) 
			{ setDlghapusObj({}); setDlghapusinput(""); return; }
	},[uISDlghapusshow]);
	
	//console.log("Returbeli - uDatamainArr => "+JSON.stringify(uDatamainArr));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return "" }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )//>

	return (
	<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-md-none fw-bolder">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_returbeli || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
				<span className="d-none d-lg-block">{uBahasaObj.caption_rekapinput||"Rekap Input"}</span>
			</small>
			
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogview
			options={{centered:true}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewObj.dlg_header}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}
			id="iddialogview"/>

		<MyFormHapus
			value={uDlghapusinput}
			onCHvalue={(e)=>setDlghapusinput(e.target.value)}
			onSimpan={()=>hdlKlikDlghapussimpan()}
			show={uISDlghapusshow} 
			dialogHeader={uDlghapusObj.dlg_header||""}
			toggle={()=>setDlghapusshow(false)}/>
	</>
	)//>
}	

export default Returbeli