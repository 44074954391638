import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CLink,
	CCollapse,
	CTooltip,
	CForm,
	CFormInput,
	CImage,
	CSpinner,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu, CDropdownDivider,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPage500,
	MyPagination,
	MyDialogview,
	MyFormHapus,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson		= require('../../package.json');
const Copynota 	= (props) => {
	const uComponentname 	= Copynota.name;
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--GLOBAL_VARS--/
	const uIsAllowedhapus	= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && uIsEOMExecuted;
	const uMaxData			= isMobile ? (uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const [uEfekview2,setEfekview2] 		= React.useState(false);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uJmlData,setJmlData]				= React.useState(0);
	const [uJmlHal,setJmlHal]				= React.useState(1);
	const [uPageActive,setPageActive]		= React.useState(0);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uFirstload,setFirstload]			= React.useState("YA");

	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uItemloadingID,setItemloadingID]	= React.useState(0);
	const uElJmldata						= document.getElementById("idjmldata");

	//---FORMDIALOG--/
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uIsFhapusShow,setFhapusShow]		= React.useState(false);
	const [uInpKethapus,setInpKethapus]		= React.useState("");
	const [uElBtnSimpanhapus,setElBtnSimpanhapus]= React.useState(document.getElementById("btnDialogSimpan"));
	//---END FORMDIALOG--/

	//---VIEWDIALOG--/
	const [uHeaderDlgview,setHeaderDlgview]	= React.useState("")
	const [uIsShowDlgview,setShowDlgview]	= React.useState(false)
	//---END VIEWDIALOG--/

	//--CETAK_VARS--/
	const uUrllogoDefault					= pjson.homepage+"/images/header_nota.png";
	const uHTMLstylenota					= useSelector(state => state.gHTMLstyleNota);
	const [uDataidentitas,setDataidentitas]	= React.useState({});
	const [uDatasetting,setDatasetting]		= React.useState({});
	const [uDataCetakObj,setDataCetakObj]	= React.useState(null);
	const uSettingcetakObj					= JSON.parse(localStorage.getItem("settingnota") || "{}");
	const uLebarKertas						= parseInt(uSettingcetakObj.lebarkertas)||0;
	const uMarginKiri						= parseInt(uSettingcetakObj.marginkiri)||1;
	const uFontsize							= parseInt(uSettingcetakObj.fontsize)||12;
	const uFontstyle						= uSettingcetakObj.fontstyle || "Courier, Courier New, Sans Serif";
	const uFrameExport 						= document.getElementById("ifmcontentstoprint");
	const [uFinishingBayar,setFinishingBayar] = React.useState(false);
	//--END CETAK_VARS--/
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlToggleFHapus=()=>{setFhapusShow(false);}
	const hdlToggleDlgview=()=>{setShowDlgview(false);}
	const hdlKlikFHapusSimpan=()=>{
		if((uInpKethapus||"").trim() === "") {
			showToast((uBahasaObj.pesan_penghapusanharusdiisi||"pesan_penghapusanharusdiisi")); 
			document.getElementById("inpketerangan").focus();
			return;
		}

		apiProseshapus();
	}
	const hdlKlikViewdetil=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL)
		const vObjidx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vCaptionUpper = (uDatamainArr[vObjidx].nonota+
			((uDatamainArr[vObjidx].customer||"")!==""
				? " ("+uDatamainArr[vObjidx].customer+")"
				: "")
			)

		setHeaderDlgview(uBahasaObj.caption_lihatdetil+": "+vCaptionUpper)
		apiLoaditem(_IDTABEL);
	}
	const hdlKlikItemhapus=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vObjidx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setTabelIDPilih(_IDTABEL);
		
		setDialogHeader((uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+
			(//UFunc.HariAngka(uDatamainArr[vObjidx].tanggal)+" "+
			(uDatamainArr[vObjidx].nonota || "")+
			(uDatamainArr[vObjidx].customer!==""?" ("+uDatamainArr[vObjidx].customer+")" : "")+
			"").toUpperCase()
		);
		setFhapusShow(true);
	}
	const hdlKlikItemprint=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vObjidx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		apiLoadnota(_IDTABEL);
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initJmldata=()=>{
		const vKeyword = uKeywordObj.kwd_copynota || "";
		if (vKeyword.length >= 3) 
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
	}
	const initFHapusAwal=()=>{
		setTabelIDPilih(0);
		setInpKethapus("");
	}
	const initCetaknota=()=>{
		//console.log("uDataCetakObj = "+JSON.stringify(uDataCetakObj||{}));
		if(uDataCetakObj === null) return "";

		let vHTMLs 	= uHTMLstylenota;

		vHTMLs 		= vHTMLs.replace(/_LEBARKERTAS_/g, (uLebarKertas<=0?"auto":uLebarKertas+"mm"));//-->PAKESATUAN
		vHTMLs 		= vHTMLs.replace(/_MARGINKIRI_/g,uMarginKiri);
		vHTMLs 		= vHTMLs.replace(/_FONTSIZE_/g,uFontsize);
		vHTMLs 		= vHTMLs.replace(/_FONTFAMILY_/g,uFontstyle);

		const vIsWithlogo	= (uDatasetting.view_image_nota||"TIDAK")==="YA"?true:false;
		const vURLlogo		= uDatasetting.url_image_nota||"";//getBase64Image(document.getElementById("idimglogo"));
		const vCopycaption 	= `
		<tr valign="top">
			<td colSpan="4"><HR></td>
		</tr>
		<tr valign="top">
			<td colSpan="4" align="center">--- COPY ---</td>
		</tr>
		`;

		const vMainbody	= UFunc.Rendernota(uDataCetakObj);
		vHTMLs 		+=  vMainbody;
		vHTMLs 		= vHTMLs.replace(/_TRBODYTAGLINE_/g,"_TRBODYTAGLINE_"+vCopycaption);

		//---LOGO--/
		let vLogoheader	= "";
		if(vIsWithlogo) 
			vLogoheader = `
			<tr valign="top">
			<td colSpan="4" align="center">
				<img src="`+vURLlogo+`" height="25mm" style="height:25mm" />
			</td>
			</tr>
			`;
		//---END LOGO--/

		//---TELP--/
		let vTelpPerusahaan	= "";
		if((uDataidentitas.telp || "") !== "")
			vTelpPerusahaan += `
			<tr valign="top">
			<td colSpan="4" align="center">`+
				//(uBahasaObj.caption_telphp||"caption_telphp")+". "+
				UFunc.capitalize(uDataidentitas.telp || "")+
			`</td>
			</tr>
			`;
		//---END TELP--/

		//--TAGLINE--/
		const vTagline	=  uDataidentitas.slogan !== "" 
			? `
			<tr valign="top">
			<td colSpan="4" align="center">`+
				UFunc.capitalize(uDataidentitas.slogan || "")+
			`</td>
			</tr>
			` : ``;
		//--END TAGLINE--*/

		const vFooternota = (uDatasetting.footer_nota||"").trim() !=="" ?
			`
			<tr>
			<td colSpan="4"><HR></td>
			</tr>
			<tr>
			<td colSpan="4" align="center">`+(uDatasetting.footer_nota||"")+`</td>
			</tr>
			` : ``;

		vHTMLs 		= vHTMLs.replace(/_TRBODYLOGO_/g,vLogoheader);
		vHTMLs 		= vHTMLs.replace(/_NAMAPERUSAHAAN_/g,(uDataidentitas.nama_perusahaan || "").toUpperCase());
		vHTMLs 		= vHTMLs.replace(/_ALAMATPERUSAHAAN_/g,UFunc.capitalize(uDataidentitas.alamat || ""));
		vHTMLs 		= vHTMLs.replace(/_TRBODYTELP_/g,vTelpPerusahaan);
		vHTMLs 		= vHTMLs.replace(/_FOOTERNOTA_/g,vFooternota);
		vHTMLs 		= vHTMLs.replace(/_TRBODYTAGLINE_/g,vTagline);

		return vHTMLs;
	}
	const prosesCetaknota=()=>{
		var vContent 	= initCetaknota();
		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();

		setDataCetakObj(null);
		setFinishingBayar(false);
	}
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_copynota || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		//const vDataviewArr	= isMobile ? uDatamainArr.slice(0,(vMax+1)) : uDatamainArr.slice(vMin,(vMax+1));
		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))
		
		//console.log(uComponentname+" - contentMain 1 : "+JSON.stringify(vDataviewArr));
		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="2" className="text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol md="2" lg="3" className="text-start">{uBahasaObj.word_nota||"word_nota"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_jenispayment||"Metode Bayar"}</CCol>
				<CCol md="3" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="2" lg="1" className="">{uBahasaObj.word_total||"word_total"} <small>(Rp)</small></CCol>
				<CCol md="1" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>

			<tr className="d-md-none classfontsmaller">
			<th width={25} className="p-0"/>
			<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,tanggal,tgl_input,customer,usercaption,terbayar,diskon,kas,tabelref,
				items,nonota,nilai,dp_awal,b_kartu,ket_kartu,init_expired,status_jurnal,
				is_loading
			} = vItems;

			vNumber++;
			const vCaptionUpper	= //UFunc.HariAngka(tanggal)+" "+
				((nonota||"")+
				((customer||"")!=""?" ("+customer+")":"")
				).toUpperCase();

			const vTglinputtxt = (tgl_input || "")==="0000-00-00 00:00:00" 
				? "" : (tgl_input || "");

			const vTanggal	= vKeyword==="" ? UFunc.TglAngka(tanggal) 
				: (UFunc.TglAngka(tanggal)).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vCustomer= vKeyword==="" ? (customer||"-") 
				: (customer||"-").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vUsername= vKeyword==="" ? UFunc.Usercaptionfilter(usercaption||"").toUpperCase() 
				: UFunc.Usercaptionfilter(usercaption||"").toUpperCase().replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vNonota= vKeyword==="" ? (nonota||"") 
				: (nonota||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vKetkartu= vKeyword==="" ? (ket_kartu||"") 
				: (ket_kartu||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vKas= vKeyword==="" ? (kas||"UNDF") 
				: (kas||"UNDF").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vTglInput	= vKeyword==="" ? UFunc.WaktuAngka(vTglinputtxt) 
				: (UFunc.WaktuAngka(vTglinputtxt)).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");

			const vJenispiutang	= tabelref.toUpperCase()===("tpiutang_bayar").toUpperCase()
				? (uBahasaObj.word_piutang || "PIUTANG")
				: (tabelref.toUpperCase()===("tpendapatan").toUpperCase() 
					? (uBahasaObj.word_pendapatan || "Pendapatan") : uBahasaObj.menus_kasirklinik );
						

			const vItemsarr	= items || [];
			const vBruto	= parseInt(nilai) || 0;
			const vDPAwal	= parseInt(dp_awal) || 0;
			const vDiskon	= parseInt(diskon) || 0;
			const vJmlBayar	= parseInt(terbayar) || 0;
			const vNetto	= vBruto - vDiskon - vDPAwal;
			const vInitCollapse	= false;

			//console.log("vItemsarr = "+JSON.stringify(vItemsarr));

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{(vNumber)}.</td>
				<td className="px-1">	
				<CRow className="mx-0">
				<CCol xs="12" md="2" className="px-1 text-start d-flex justify-content-between">
					<div>
						<span className="">{UFunc.renderHTML(vTanggal)}</span>
						{(uTokenObj.userhak === "FREEROLE") && (
							<span className="classfontsmaller"> &middot; <small>({id})</small></span>
						)}

						<div className="d-none d-md-block classfontsmaller text-danger">
						<small>[
						{(vTglinputtxt)!=="" && (
							<span>{UFunc.renderHTML(vTglInput)} &middot; </span>
						)}			
						{UFunc.renderHTML(vUsername)}
						]</small>
						</div>
					</div>

					{(is_loading||false)===true ? (
						<CSpinner color="primary" className="d-md-none" size="sm"/>
					) : (
					<CDropdown className="d-md-none">
					<CDropdownToggle caret={false} className="p-0" color="transparent">
						<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
					</CDropdownToggle>

					<CDropdownMenu className="py-1">
						<CDropdownItem onClick={()=>hdlKlikItemprint(id)}>
						<CIcon className="classikoncetak align-middle mx-0 me-2" height={25}/>
						Cetak Copy Nota
						</CDropdownItem>

						<CDropdownItem onClick={()=>hdlKlikViewdetil(id)}>
						<CIcon className="classikondetil align-middle mx-0 me-2" height={25}/>
						{uBahasaObj.caption_lihatdetil||"caption_lihatdetil"}
						</CDropdownItem>
						
						{(uIsAllowedhapus && uIsEOMExecuted && (init_expired||"TIDAK")==="TIDAK")  && (
						<>
						<CDropdownDivider/>

						<CDropdownItem onClick={()=>hdlKlikItemhapus(id)}>
						<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
						{uBahasaObj.caption_hapusdata||"caption_hapusdata"}
						</CDropdownItem>
						</>
						)}
					</CDropdownMenu>
					</CDropdown>
					)}
				</CCol>
	
				<CCol xs="12" className="my-3 px-1 classborderbottom d-md-none" />
				
				<CCol xs="3" className="px-1 d-md-none text-start">{uBahasaObj.word_nota||"Nota"}</CCol>
				<CCol xs="9" md="2" lg="3" className="px-1 text-md-start">
					<div>
						<span className="text-info">{UFunc.renderHTML(vNonota)}</span>
						<small className="mx-1">&middot;</small>
						<span className="text-uppercase text-warning">
							<small>({vJenispiutang})</small>
						</span>
					</div>

					{((customer||"") !== "") && (
					<div className="d-none d-md-block">
						<i className="">{UFunc.renderHTML(vCustomer)}</i>
					</div>
					)}

					{((uTokenObj.userhak==="FREEROLE") && (status_jurnal||"")!==""
					) && (
					<div className="d-none d-md-block classfontsmaller">
						<small>&middot; S.Jurnal: <b>{status_jurnal||"NOT YET"}</b> &middot;</small>
					</div>
					)}
				</CCol>

				{((customer||"")!=="") && (
					<>
					<CCol xs="3" className="px-1 d-md-none text-start">{uBahasaObj.word_customer||"word_customer"}</CCol>
					<CCol xs="9" className="px-1 d-md-none fw-bolder">{UFunc.renderHTML(vCustomer)}</CCol>
					</>
				)}

				{(uTokenObj.userhak==="FREEROLE") && (
					<>
					<CCol xs="3" className="px-1 d-md-none text-start">{uBahasaObj.caption_sjurnal||"S.Jurnal"}</CCol>
					<CCol xs="9" className="px-1 d-md-none fw-bolder classfontsmaller">
						<span>{status_jurnal||"-"}</span>
					</CCol>
					</>
				)}

				<CCol xs="12" className="my-3 px-1 classborderbottom d-md-none"/>

				{((parseFloat(diskon)||0) > 0) && (
					<>
					<CCol xs="5" className="px-1 d-md-none text-start classfontsmaller">{uBahasaObj.word_diskon||"Diskon"} (Rp)</CCol>
					<CCol xs="7" className="px-1 d-md-none classfontsmaller">{UFunc.formatAngka(diskon)}</CCol>
					</>
				)}

				{((parseFloat(dp_awal)||0) > 0) && (
					<>
					<CCol xs="5" className="px-1 d-md-none text-start classfontsmaller">{uBahasaObj.caption_titipandp||"caption_titipandp"} (Rp)</CCol>
					<CCol xs="7" className="px-1 d-md-none classfontsmaller">{UFunc.formatAngka(dp_awal)}</CCol>
					</>
				)}
				{(parseFloat(b_kartu||0) > 0) && (
					<>
					<CCol xs="5" className="px-1 d-md-none text-start classfontsmaller">{uBahasaObj.caption_biayatransfer||"B.Transfer"} (Rp)</CCol>
					<CCol xs="7" className="px-1 d-md-none classfontsmaller">{UFunc.formatAngka(b_kartu)}</CCol>
					</>
				)}

				<CCol xs="5" className="px-1 d-md-none text-start classfontsmaller">{uBahasaObj.caption_jmlbayar||"Jml Bayar"} (Rp)</CCol>
				<CCol xs="7" className="px-1 d-md-none classfontsmaller">
					<span className={""+(vJmlBayar<vNetto?" text-danger":" text-success")}>{UFunc.formatAngka(terbayar)}</span>
				</CCol>

				<CCol xs="12" className="my-3 px-1 classborderbottom d-md-none"/>

				<CCol xs="6" className="px-1 d-md-none text-start"><small>{uBahasaObj.caption_jenispayment||"Metode Pembayaran"}</small></CCol>
				<CCol xs="6" md="2" className="px-1 text-md-center">
					<small>{UFunc.renderHTML(vKas)}</small>
				</CCol>
				
				<CCol xs="5" className="px-1 d-md-none text-start"><small>{uBahasaObj.word_inputby||"Inputby"}</small></CCol>
				<CCol xs="7" className="px-1 d-md-none">
					<span className="text-danger classfontsmaller">
					<small>[
						{(vTglinputtxt)!=="" && (
							<span>{UFunc.renderHTML(vTglInput)} &middot; </span>
						)}			
						{UFunc.renderHTML(vUsername)}
					]</small>
					</span>
				</CCol>

				<CCol md="3" className="px-1 text-start d-none d-md-block">
					<small>
					<ul className="font-italic m-0 ms-2 ps-4">
					{(vDiskon > 0) && (
						<>
						<li>
						<div className="d-flex justify-content-between">
							<span>{uBahasaObj.word_diskon||"word_diskon"}</span>
							<span>{UFunc.formatAngka(diskon)}</span>
						</div>
						</li>
						</>
					)}
					{((parseInt(dp_awal)||0) > 0) && (
						<li>
						<div className="d-flex justify-content-between">
						<span>{uBahasaObj.caption_titipandp||"caption_titipandp"}</span>
						<span className="text-primary">{UFunc.formatAngka(dp_awal)}</span>
						</div>
						</li>
					)}
					{(parseInt(b_kartu||0) > 0) && (
						<li>
						<div className="d-flex justify-content-between">
						<span>{uBahasaObj.caption_biayatransfer||"B.Transfer"}</span>
						<span className="text-primary">{UFunc.formatAngka(b_kartu)}</span>
						</div>
						</li>
					)}
					<li className="">
						<div className="d-flex justify-content-between">
						<span>{uBahasaObj.caption_jmlbayar||"caption_jmlbayar"}</span>
						<span className={""+(vJmlBayar<vNetto?" text-danger":" text-success")}>{UFunc.formatAngka(terbayar)}</span>
						</div>
					</li>
					</ul>
					</small>
				</CCol>

				<CCol xs="12" className="my-3 px-1 classborderbottom d-md-none"/>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_total||"word_total"} (Rp)</CCol>
				<CCol xs="7" md="2" lg="1" className="px-1 px-lg-0 fw-bolder">
					{UFunc.formatAngka(nilai)}
				</CCol>

				<CCol md="1" className="px-md-0 text-center d-none d-md-block">
					{(is_loading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<>
					<CTooltip content={"--"+(uBahasaObj.caption_cetakcopynota||"caption_cetakcopynota")+": "+vCaptionUpper}>
					<CLink 
						className="classikontabel classikoncetak classcursorpointer classikoncoreui mx-0 me-lg-1"
						onClick={()=>hdlKlikItemprint(id)} />
					</CTooltip>

					<span className="d-md-none mx-1">&middot;</span>

					<CDropdown>
					<CDropdownToggle color="transparent" caret={false} className="p-0">
						<CIcon icon="cilChevronBottom" className="classikoncoreui mx-0" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu>	
						<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikViewdetil(id)}>
							<CIcon className="classikondetil align-middle mx-0 me-2" height={25}/>
							{uBahasaObj.caption_lihatdetil||"caption_lihatdetil"}
						</CDropdownItem>

						{(uIsAllowedhapus && uIsEOMExecuted && (init_expired||"TIDAK")==="TIDAK")  && (
						<>
						<CDropdownDivider/>
						<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikItemhapus(id)}>
							<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
							{uBahasaObj.caption_hapusdata||"caption_hapusdata"}
						</CDropdownItem>
						</>
						)}
					</CDropdownMenu>	
					</CDropdown>
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgview = () => {
		if(!uIsShowDlgview) return (<></>)//>
		if(parseInt(uTabelIDPilih) <= 0) return (<></>)//>

		const vObjidx 	= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		const vItemsarr = uDatamainArr[vObjidx].items||[];
		if(vItemsarr.length <= 0) return (<></>)//>

		const vKembalian	= parseInt(uDatamainArr[vObjidx].kembali || 0);
		const vJumlah		= parseInt(uDatamainArr[vObjidx].nilai||"0");
		const vSubtotal		= vJumlah - (parseInt(uDatamainArr[vObjidx].dp_awal)||0) - (parseInt(uDatamainArr[vObjidx].diskon)||0);
		const vTotal 		= vSubtotal + (parseInt(uDatamainArr[vObjidx].b_kartu)||0);

		return (
		<>
		<CRow className="align-items-stretch">
			<CCol lg="6" className="mb-3 mb-lg-0">
				<CCard className="h-100">
				<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
					<small>Data Nota</small>
				</CCardHeader>

				<CCardBody>
				<small>
				<div className="my-1 d-flex justify-content-between">
					<div>{uBahasaObj.word_tanggal}</div>
					<div className="fw-bolder text-end">{UFunc.TglAngka(uDatamainArr[vObjidx].tanggal)}</div>
				</div>

				<div className="my-1 d-flex justify-content-between">
					<div>{uBahasaObj.word_nota}</div>
					<div className="fw-bolder text-end">{uDatamainArr[vObjidx].nonota}</div>
				</div>

				<div className="my-1 d-flex justify-content-between">
					<div>{uBahasaObj.word_kasir||"Kasir"}</div>
					<div className="classfontsmaller text-end text-danger">
						{uDatamainArr[vObjidx].usercaption||"UNDF"}
					</div>
				</div>
				</small>
				</CCardBody>
				</CCard>
			</CCol>

			<CCol className="">
				<CCard className="h-100">
				<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
					<small>Summary Pembayaran</small>
				</CCardHeader>

				<CCardBody>
				<small>
				<div className="my-1 d-flex justify-content-between">
					<div>{uBahasaObj.caption_jenispayment||"Jenis Pembayaran"}</div>
					<div className="text-warning text-end">
						{(uDatamainArr[vObjidx].tabelref||"")==="tpendapatan" 
							? "PENDAPATAN" : "PIUTANG"}
					</div>
				</div>

				{(uDatamainArr[vObjidx].customer||"")!=="" && (
				<div className="my-1 d-flex justify-content-between">
					<div>{uBahasaObj.word_customer}</div>
					<div className="fw-bolder text-end">{uDatamainArr[vObjidx].customer}</div>
				</div>
				)}

				<div className="my-1 d-flex justify-content-between">
					<div>{uBahasaObj.caption_metodepembayaran||"Metode Pembayaran"}</div>
					<div className="fw-bolder text-end">
						{uDatamainArr[vObjidx].kas || "-"}
					</div>
				</div>
				</small>
				</CCardBody>
				</CCard>
			</CCol>
		</CRow>

		<CCard className="mt-3">
		<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
			<small className="text-muted">{uBahasaObj.caption_itembarang}</small>
		</CCardHeader>

		<CCardBody className="px-1">
			<small>
			<div className=" d-none d-lg-block">
			<CRow className="mx-0 py-1 classborderbottom">
				<CCol xs="1" className="px-1 text-center">{uBahasaObj.word_no||"No"}</CCol>
				<CCol xs="5" className="px-1 text-start">{uBahasaObj.word_item}</CCol>
				<CCol xs="2" className="px-1 text-center">{uBahasaObj.caption_hsatuan} (Rp)</CCol>
				<CCol xs="2" className="px-1 text-center">{uBahasaObj.word_qty}</CCol>
				<CCol xs="2" className="px-1 text-center">{uBahasaObj.word_total} (Rp)</CCol>
			</CRow>
			</div>

			{vItemsarr.map((vItems,vKeys)=>{
				const vCaptionitem	= 
					((vItems.caption_pasien||"")!==""?"["+vItems.caption_pasien+"] ":"")+
					vItems.caption_item;

				return (
				<CRow className="mx-0 py-1 classborderbottom" key={vKeys}>
				<CCol xs="1" className="px-1 text-end">{vKeys+1}.</CCol>
				<CCol xs="5" className="px-1 text-start">
					{vCaptionitem}

					{(vItems.jenis_item||"") === "PIHAKLAIN" && (
						<div className="classfontsmaller fst-italic">
							<CIcon icon="cilArrowRight" className="me-1" height={15}/>
							{vItems.keterangan || "-"}
						</div>
					)}
				</CCol>
				<CCol xs="2" className="px-1 text-end text-success">{UFunc.formatAngka(vItems.h_jual_sat)}</CCol>
				<CCol xs="2" className="px-1 text-center">
					{UFunc.formatAngka(vItems.qty)} <b>{vItems.satuan||"UNIT"}</b>
				</CCol>
				<CCol xs="2" className="px-1 text-end text-primary">
					<b>{UFunc.formatAngka(parseInt(vItems.qty||"0") * vItems.h_jual_sat)}</b>
				</CCol>
				</CRow>
				)
			})}
			</small>
		</CCardBody>
		</CCard>

		<CRow className="align-items-stretch mt-lg-3">
			<CCol lg="6" className="mt-3 mt-lg-0">
				{(uDatamainArr[vObjidx].tabelref||"")!=="tpendapatan" && (
				<CCard className="h-100">
				<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
					<small>{uBahasaObj.caption_datatransaksi||"Data Transaksi"}</small>
				</CCardHeader>

				<CCardBody>
				<small>
				<div className="my-1 d-flex justify-content-between">
					<div>{uBahasaObj.caption_notatransaksi}</div>
					<div className="fw-bolder text-end">{uDatamainArr[vObjidx].nota_sebelum}</div>
				</div>

				<div className="my-1 d-flex justify-content-between">
					<div xs="6">{uBahasaObj.caption_tgltransaksi}</div>
					<div className="fw-bolder text-end">{UFunc.TglAngka(uDatamainArr[vObjidx].tgl_transaksi)}</div>
				</div>

				<div className="my-1 d-flex justify-content-between">
					<div>{uBahasaObj.caption_nilaitransaksi}</div>
					<div className="fw-bolder text-end">{UFunc.formatAngka(uDatamainArr[vObjidx].nilai_transaksi)}</div>
				</div>
				</small>
				</CCardBody>
				</CCard>
				)}
			</CCol>

			<CCol className={(uDatamainArr[vObjidx].tabelref||"")!=="tpendapatan" ? "mt-3 mt-lg-0" : ""}>
				<CCard>
				<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
					<small>{uBahasaObj.caption_totalsummary||"Total Summary"}</small>
				</CCardHeader>

				<CCardBody>
				<small>
				<div className="my-1 d-flex justify-content-between">
					<div>
						{(uDatamainArr[vObjidx].tabelref||"")==="tpendapatan" && (
							<>{uBahasaObj.caption_nilaibruto||"N.Bruto"}</>
						)}
						{(uDatamainArr[vObjidx].tabelref||"")!=="tpendapatan" && (
							<>{uBahasaObj.caption_sisasebelum||"Nilai Piutang"}</>
						)}
						<span className="ms-1">(Rp)</span>
					</div>
					<div className="text-end">
						<b>{UFunc.formatAngka(vJumlah)}</b>
					</div>
				</div>

				{parseInt(uDatamainArr[vObjidx].diskon||"0") > 0 && (
				<div className="my-1 d-flex justify-content-between">
					<div>{uBahasaObj.word_diskon||"diskon"} (Rp)</div>
					<div className="text-end text-danger">
						{UFunc.formatAngka(uDatamainArr[vObjidx].diskon)}
					</div>
				</div>
				)}

				{parseInt(uDatamainArr[vObjidx].dp_awal||"0") > 0 && (
				<div className="my-1 d-flex justify-content-between">
					<div xs="7">{uBahasaObj.caption_titipandp||"Titipan DP"} (Rp)</div>
					<div className="text-end">
						{UFunc.formatAngka(uDatamainArr[vObjidx].dp_awal)}
					</div>
				</div>
				)}

				{(parseInt(uDatamainArr[vObjidx].dp_awal||"0") > 0 
					|| parseInt(uDatamainArr[vObjidx].diskon||"0") > 0) && (
				<>
				<div className="my-1 d-flex justify-content-between">
					<div className="text-end"/>
					<div className="text-end classborderbottom"/>
				</div>

				<div className="my-1 d-flex justify-content-between">
					<div>{uBahasaObj.caption_subtotal||"SubTotal"} (Rp)</div>
					<div className="text-end">
						{UFunc.formatAngka(vSubtotal)}
					</div>
				</div>
				</>
				)}

				{parseInt(uDatamainArr[vObjidx].b_kartu||"0") > 0 && (
				<>
				<div className="my-1 d-flex justify-content-between">
					<div>{uBahasaObj.caption_bkartu||"B.Non Tunai"} (Rp)</div>
					<div className="text-end">
						{UFunc.formatAngka(uDatamainArr[vObjidx].b_kartu)}
					</div>
				</div>

				<div className="my-1 d-flex justify-content-between">
					<div className="text-end"/>
					<div className="w-50 text-end classborderbottom"/>
				</div>

				<div className="my-1 d-flex justify-content-between">
					<div>{uBahasaObj.word_total||"Total"} (Rp)</div>
					<div className="text-end">
						{UFunc.formatAngka(vTotal)}
					</div>
				</div>
				</>
				)}

				<big className="text-success">
				<div className="my-1 d-flex justify-content-between">
					<div>{uBahasaObj.caption_jmlbayar||"Jml Bayar"} (Rp)</div>
					<div className="text-end">
						<b>{UFunc.formatAngka(uDatamainArr[vObjidx].terbayar)}</b>
					</div>
				</div>
				</big>

				<div className="my-1 d-flex justify-content-between">
					<div className="text-end"/>
					<div className="w-50 text-end classborderbottom"/>
				</div>

				<div className={"my-1 d-flex justify-content-between"+(vKembalian < 0 ? " text-danger":"")}>
					<div>
						{vKembalian>=0 
							? (uBahasaObj.word_kembali||"Kembali")
							: (uBahasaObj.caption_sisapiutang||"Sisa Piutang")} (Rp)
					</div>
					<div className={"text-end"}>
						{UFunc.formatAngka(Math.abs(vKembalian))}
					</div>
				</div>
				</small>
				</CCardBody>
				</CCard>
			</CCol>
		</CRow>
		</>
		)//>
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiLoaddata = (_PAGE) => {
		uFuncname 	= apiLoaddata.name;
		_PAGE		= parseInt(_PAGE) || 1
		setHTMLError500();

		const vKeyword = uKeywordObj.kwd_copynota  || "";
		const vHandelview	= (uHandelView===true || uFirstload==="YA") ?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_firstload: uFirstload,
			send_pg: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_copynota/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("("+uComponentname+"-"+uFuncname+") dataobject : "+JSON.stringify(output_string.dataobject));
				let vTmpObj = JSON.parse(output_string.dataobject||"[]");
				vTmpObj.map(vItems=>vItems.pg = _PAGE);

				setDatamainArr([...vDatamainArr,...vTmpObj]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpObj.length);

				setDataidentitas(JSON.parse(output_string.dataidentitas || JSON.stringify(uDataidentitas)));
				setDatasetting(JSON.parse(output_string.datasetting || JSON.stringify(uDatasetting)));
				
				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaditem = (_IDTABEL) => {
		uFuncname = apiLoaditem.name;

		_IDTABEL = parseInt(_IDTABEL) || 0;
		const vObjidx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vItemsarr		= uDatamainArr[vObjidx].items || [];
		if(vItemsarr.length > 0) { setShowDlgview(true); return; }
		
		uDatamainArr[vObjidx].is_loading	= true;

		/*//--TEST_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") LEWAT ");
		let vTimeout	= setTimeout(()=>{
			clearTimeout(vTimeout)
			uDatamainArr[vObjidx].is_loading	= false;
			setEfekview(!uEfekview);
			setShowDlgview(true)
		},2000); return
		//--END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_bayarid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_copynota/ld_nota";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vObjidx].is_loading	= false;
			setEfekview(!uEfekview);
			if(output_string.tampil) {
				const vTmpObj	= JSON.parse(output_string.datanota||"{}")

				uDatamainArr[vObjidx].nilai_transaksi 	= vTmpObj.nilai_transaksi || 0;
				uDatamainArr[vObjidx].nota_sebelum 		= vTmpObj.nota_sebelum || "UNDF";
				uDatamainArr[vObjidx].tgl_transaksi 	= vTmpObj.tgl_transaksi || "";
				uDatamainArr[vObjidx].items = vTmpObj.arritems || [];
				setShowDlgview(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vObjidx].is_loading	= false;
			setEfekview(!uEfekview);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = () => {
		uFuncname 	= apiProseshapus.name;
		if(uTabelIDPilih <= 0) {showToast("[0] ERROR !"); return;}
		const vObjidx= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);

		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		console.log("("+uComponentname+"-"+uFuncname+") vObjidx : "+vObjidx);

		uDatamainArr.splice(vObjidx,1);
		setTabelRowSelect(-1);
		setJmlData((parseInt(uDatamainArr.length)||0));
		setEfekview(!uEfekview);
		hdlToggleFHapus();
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_kethapus : uInpKethapus,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_copynota/pr_hapus";

		setLoading(true);
		uElBtnSimpanhapus.disabled = true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			uElBtnSimpanhapus.disabled = false;

			if(output_string.sukses) {
				uDatamainArr.splice(vObjidx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				setEfekview(!uEfekview);
				hdlToggleFHapus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				uElBtnSimpanhapus.disabled = true;
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnSimpanhapus.disabled = false;
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
			//uNavigate.push("/error500");
		});
	}
	const apiLoadnota = (_IDTABEL) => {
		uFuncname = apiLoadnota.name;

		const vBayarID = parseInt(_IDTABEL) || 0; if(vBayarID <= 0) return;
		setDataCetakObj(null);

		const vIdx 	= UFunc.getObjectIndeks(uDatamainArr,vBayarID);
		uDatamainArr[vIdx].is_loading = true;
		setEfekview2(!uEfekview2)

		//alert("vBayarID = "+vBayarID); return;

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiLoadnota) vIdx = "+vIdx);
		setFinishingBayar(true);
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_bayarid : vBayarID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_copynota/ld_nota";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);

			uDatamainArr[vIdx].is_loading = false;
			setEfekview(!uEfekview);
			if(output_string.tampil) {
				setDataCetakObj(JSON.parse(output_string.datanota || "{}"));
				//console.log("("+uComponentname+"-"+uFuncname+") datanota : "+(output_string.datanota||""));
				setFinishingBayar(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vIdx].is_loading = false;
			setEfekview(!uEfekview);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{});
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

		return()=>{ 
			uDispatch({type: "set", gInitHeaderAction: {}});
    		hdlToggleFHapus();hdlToggleDlgview()
			setDatamainArr([]); setDatasetting({}); setDataidentitas({}) 
		}
	},[]);
	/*React.useEffect(()=>{
		//console.log("("+uComponentname+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;

		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);//-*/
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uIsEOMExecuted) return;

		uNavigate("/kasir/kasirtoko/forminput");
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!uIsFhapusShow) { initFHapusAwal(); return; }

		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			setElBtnSimpanhapus(document.getElementById("btnDialogSimpan"));

			const vElFocus = document.getElementById("inpkethapus");
			vElFocus && vElFocus.focus();
		},350);
	},[uIsFhapusShow]);
	React.useEffect(()=>{ uElJmldata && initJmldata(); },[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData]);
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		//console.log("("+uComponentname+" - [uPageActive]) vDatapageArr => "+JSON.stringify(vDatapageArr));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]);//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uFinishingBayar) return; 
		prosesCetaknota(); 
	},[uFinishingBayar]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_cComponentName
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)

	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_copynota || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uIsShowDlgview} 
			dialogHeader={uHeaderDlgview}
			toggle={hdlToggleDlgview}
			renderContent={contentDlgview()}/>

		<MyFormHapus
			value={uInpKethapus}
			onCHvalue={(e)=>setInpKethapus(e.target.value)}
			onSimpan={()=>hdlKlikFHapusSimpan()}
			show={uIsFhapusShow} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleFHapus}/>

		<iframe id="ifmcontentstoprint" className="d-none">
			<CImage src={uDatasetting.url_image_nota||uUrllogoDefault} id="idimglogo" />
		</iframe>
		</>
	)
}
	
export default Copynota;
