import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CHeaderBrand,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,CFormInput,CFormTextarea,CInputGroup,CInputGroupText,CButton,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import NumberFormat from 'react-number-format';
import {
	MyButton,
	MyDialogform,
	MyDialogview,
	MyDialoglookup,
	MyProfileDetil,
	MyPage500,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc,cKeycodebypass } from '../../helpers/functions'

const pjson 		= require('../../../package.json')
const Returbeliform = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props;
	const { id } 			= useParams(); 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uIsEOMExecuted			= useSelector(state => state.gIsEOMExecuted);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uTimeelapse,setTimeelapse]		= React.useState(0); 

	const [uDatasupplierArr,setDatasupplierArr]	= React.useState([])
	const [uISBtnsimpandisabled,setBtnsimpandisabled] 	= React.useState(false);
	const [uDatamainObj,setDatamainObj]		= React.useState({})
	const [uISFormupdate,setFormupdate]		= React.useState(false)

	//--DLG_FORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	//--END DLG_FORM--/

	//--DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]	= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]		= React.useState({});
	//--END DLG_LOOKUP--/

	//--DLG_VIEW--/
	const [uISDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uDlgviewObj,setDlgviewObj]		= React.useState({});
	//--END DLG_VIEW--/

	//---HANDEL--/
	const hdlKDownDocument = (_EV) => {
		if(_EV.keyCode === 120) {
			_EV.preventDefault();
			_EV.stopPropagation();

			document.getElementById("btnSimpan") && 
				document.getElementById("btnSimpan").click();

			return;
		}
	}
	const hdlKlikBack = async() => {
		if(!uISFormupdate) 
			{ uNavigate(-1); return }

		if(await Konfirm(
			"<b>Anda Akan Meninggalkan Form Retur Pembelian</b>.."+
			"<p>Perubahan Data Tidak Akan TERSIMPAN Jika Anda Tetap Akan Melanjutkan.</p>"+
			"<p>Apakah Anda Tetap Akan KEMBALI ?</p>"+
			"")) uNavigate(-1);
	}
	const hdlKlikReset = async() => {
		if(!uISFormupdate) 
			{ apiLoaddata(); return }

		if(await Konfirm(
			"<b>Anda Akan MERESET Form Retur Pembelian</b>.."+
			"<p>Perubahan Data Tidak Akan TERSIMPAN Jika Anda Tetap Akan Melanjutkan.</p>"+
			"<p>Apakah Anda Tetap Akan MERESET ?</p>"+
			"")) {
			setDatamainObj({});

			if(!isMobile)
				document.getElementById("inptglretur") &&
					document.getElementById("inptglretur").focus()
		}
	}
	const hdlKDownSupplierlookup = (_EV) => {
		if(!cKeycodebypass.includes(_EV.keyCode)) {
			uDatamainObj.supplier_id = 0;
		}
		
		if(_EV.keyCode !== 13) { setEfekview(!uEfekview); return; }
		if((uDatamainObj.supplier_caption||"").trim()==="") {
			setEfekview(!uEfekview);
			return;
		}

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uDatasupplierArr.filter((vItems)=>
			(vItems.nama_supplier||"").toUpperCase().includes((uDatamainObj.supplier_caption||"").toUpperCase()));
		
		//console.log("(hdlKDownSupplierlookup) vArrCariMaster => "+JSON.stringify(vArrCariMaster));
		if (vArrCariMaster.length === 1) {
			uDatamainObj.supplier_id		= (vArrCariMaster[0].id || 0);
			uDatamainObj.supplier_caption 	= (vArrCariMaster[0].nama_supplier);
			setEfekview(!uEfekview);

			document.getElementById("inpsuppliercaption") &&
				document.getElementById("inpsuppliercaption").blur()

			return;
		}

		hdlKlikSupplierlookup();
	}
	const hdlKlikSupplierlookup = () => {
		uDlglookupObj.dlg_header	= "Lookup Data: SUPPLIER";
		uDlglookupObj.dlg_input 	= uDatamainObj.supplier_caption || "";
		uDlglookupObj.data_arr 		= uDatasupplierArr.map(vItems => {
			return { id: vItems.id, caption: vItems.nama_supplier }
		})

		setDlglookupshow(true);
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM 	= parseInt(_IDITEM)||0; if(_IDITEM <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatasupplierArr,_IDITEM);

		uDatamainObj.supplier_id		= _IDITEM;
		uDatamainObj.supplier_caption	= (uDatasupplierArr[vArridx]||{}).nama_supplier || "Undf";

		setDlglookupshow(false);
	}
	const hdlKlikSisasetretur = (_IDMASUK) => {
		_IDMASUK 	= parseInt(_IDMASUK)||0;

		const vDatasisaArr 	= (uDatamainObj.sisa_arr||[]);
		const vArridx		= UFunc.getObjectIndeks(vDatasisaArr,_IDMASUK,"id_masuk");
		const vCaptionupper	= (vDatasisaArr[vArridx].barang_caption||"").toUpperCase();

		const vRowIdx	= UFunc.getTabelRowIndeks("idtabelsisa","idtr"+_IDMASUK);
		uDatamainObj.sisa_rowidx	= vRowIdx;

		uDlgformObj.dlg_jenis 	= "SETRETUR";
		uDlgformObj.dlg_header 	= "Set Retur: "+vCaptionupper;
		uDlgformObj.id_masuk 	= _IDMASUK;
		uDlgformObj.tgl_proses	= vDatasisaArr[vArridx].tgl_proses;
		uDlgformObj.barang_caption	= vDatasisaArr[vArridx].barang_caption;
		uDlgformObj.barang_id	= vDatasisaArr[vArridx].barang_id;
		uDlgformObj.no_batch	= vDatasisaArr[vArridx].no_batch;
		uDlgformObj.exp_date	= vDatasisaArr[vArridx].exp_date;
		uDlgformObj.h_satuan	= vDatasisaArr[vArridx].h_satuan;
		uDlgformObj.sisa		= vDatasisaArr[vArridx].sisa;
		uDlgformObj.jml_retur	= vDatasisaArr[vArridx].jml_retur;

		if(uISDlgviewshow) setDlgviewshow(false);
		setDlgformshow(true);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			
			if(!isMobile)
				document.getElementById("inpjmlretur") &&
					document.getElementById("inpjmlretur").focus()
		},350)
	}
	const hdlKlikDlgformsimpan = () => {
		//--VALIDASI--/
		let vElfocus	= document.getElementById("inpjmlretur");
		if((parseInt(uDlgformObj.jml_retur)||0) > (parseInt(uDlgformObj.sisa)||0)) {
			vElfocus && vElfocus.focus();
			showToast("Jumlah Retur Melebihi Jumlah SISA !"+
				""); return;
		}
		const vReturidx		= UFunc.getObjectIndeks((uDatamainObj.retur_arr||[]),uDlgformObj.id_masuk,"id_masuk");
		if(vReturidx < 0) {
			if((parseInt(uDlgformObj.jml_retur)||0) <= 0) {
				vElfocus && vElfocus.focus();
				showToast("Jumlah Retur Tidak Valid !"+
					""); return;
			}
		}
		//--END VALIDASI--/

		const vDatasisaArr 	= (uDatamainObj.sisa_arr||[]);
		const vArridx		= UFunc.getObjectIndeks(vDatasisaArr,uDlgformObj.id_masuk,"id_masuk");
		(uDatamainObj.sisa_arr||[])[vArridx].jml_retur 	= uDlgformObj.jml_retur;
		
		//--HAPUS_ITEMRETUR_JIKA_JML_NOL--/
		if((parseInt(uDlgformObj.jml_retur)||0) <= 0) {
			if(vReturidx >= 0) {
				(uDatamainObj.sisa_arr||[])[vArridx].sorted 	= undefined;
				(uDatamainObj.retur_arr||[]).splice(vReturidx,1);
				(uDatamainObj.sisa_arr || []).sort((a,b)=> (parseInt(b.sorted)||0) - (parseInt(a.sorted)||0));
			}
			setDlgformshow(false);

			uDatamainObj.nilai_total= (uDatamainObj.retur_arr||[]).reduce((vSUMs,vItems)=>
				vSUMs + ((parseInt(vItems.jml_retur)||1) * (parseFloat(vItems.h_satuan)||0)),0);
			return;
		}
		//--END HAPUS_ITEMRETUR_JIKA_JML_NOL--/
		(uDatamainObj.sisa_arr||[])[vArridx].sorted 	= 1;
		(uDatamainObj.sisa_arr || []).sort((a,b)=> (parseInt(b.sorted)||0) - (parseInt(a.sorted)||0));

		uDatamainObj.retur_rowidx	= undefined;
		const vTmpObj			= {
			id_masuk: uDlgformObj.id_masuk,
			tgl_proses: uDlgformObj.tgl_proses,
			barang_caption: uDlgformObj.barang_caption,
			barang_id: uDlgformObj.barang_id,
			no_batch: uDlgformObj.no_batch,
			exp_date: uDlgformObj.exp_date,
			h_satuan: uDlgformObj.h_satuan,
			sisa: uDlgformObj.sisa,
			jml_retur: uDlgformObj.jml_retur,
		}
		if(vReturidx < 0)
			uDatamainObj.retur_arr 	= [...(uDatamainObj.retur_arr||[]), vTmpObj];
		else (uDatamainObj.retur_arr||[])[vReturidx] = vTmpObj;

		uDatamainObj.nilai_total= (uDatamainObj.retur_arr||[]).reduce((vSUMs,vItems)=>
			vSUMs + ((parseInt(vItems.jml_retur)||1) * (parseFloat(vItems.h_satuan)||0)),0);

		setDlgformshow(false);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);			
			const vRowIdx	= UFunc.getTabelRowIndeks("idtabelsisa","idtr"+uDlgformObj.id_masuk);
			uDatamainObj.sisa_rowidx	= vRowIdx;

			setEfekview(!uEfekview);
		},350)
	}
	const hdlKlikReturtambah = () => {
		if((parseInt(uDatamainObj.supplier_id)||0) <= 0) {
			showToast("Supplier Harus Dipilih Dahulu..");
			return;
		}

		uDlgviewObj.dlg_header	= "Tambah Retur Sisa";
		setDlgviewshow(true);
	}
	const hdlKlikBatalkanretur = async(_IDMASUK) => {
		_IDMASUK 	= parseInt(_IDMASUK)||0;

		uDatamainObj.sisa_rowidx	= undefined;
		const vDatareturArr = (uDatamainObj.retur_arr||[]);
		const vArridx		= UFunc.getObjectIndeks(vDatareturArr,_IDMASUK,"id_masuk");
		const vCaptionupper	= (vDatareturArr[vArridx].barang_caption||"").toUpperCase();

		const vRowIdx		= UFunc.getTabelRowIndeks("idtabelretur","idtr"+_IDMASUK);
		uDatamainObj.retur_rowidx	= vRowIdx;

		if(await Konfirm("Yakin Akan Membatalkan Retur ?")) {
			(uDatamainObj.retur_arr||[]).splice(vArridx,1);
			uDatamainObj.nilai_total= (uDatamainObj.retur_arr||[]).reduce((vSUMs,vItems)=>
				vSUMs + ((parseInt(vItems.jml_retur)||1) * (parseFloat(vItems.h_satuan)||0)),0);

			const vSisaidx	= UFunc.getObjectIndeks((uDatamainObj.sisa_arr||[]),_IDMASUK,"id_masuk");
			const vRowIdx	= UFunc.getTabelRowIndeks("idtabelsisa","idtr"+_IDMASUK);
			(uDatamainObj.sisa_arr||[])[vSisaidx].jml_retur = 0;
			(uDatamainObj.sisa_arr||[])[vSisaidx].sorted 	= undefined;
			uDatamainObj.sisa_rowidx	= vRowIdx;
			uDatamainObj.retur_rowidx	= undefined;

			(uDatamainObj.sisa_arr || []).sort((a,b)=> (parseInt(b.sorted)||0) - (parseInt(a.sorted)||0));
			setEfekview(!uEfekview);
		}
	}
	const hdlKlikSimpanmain = async() => {
		//--VALIDASI--/
		let vElfocus	= document.getElementById("inpsuppliercaption");
		if((parseInt(uDatamainObj.supplier_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("<b>Supplier Harus Dipilih</b>.."+
				"<br/>Mohon Diperbaiki !"+
				""); return;
		}
		if((uDatamainObj.retur_arr || []).length <= 0) {
			showToast("<b>Item Retur Belum Ditentukan</b>.."+
				"<br/>Mohon Diperbaiki !"+
				""); return;
		}
		//--END VALIDASI--/

		if(await Konfirm(
			"<big class='fw-bolder'>Input Retur Pembelian</big>"+

			//"<div class='classborderbottom my-3'></div>"+

			"<div class='d-flex justify-content-between my-1'>"+
				"<div>Tgl Retur</div>"+
				"<b>"+UFunc.TglAngka(uDatamainObj.tgl_retur)+"</b>"+
			"</div>"+

			"<div class='d-flex justify-content-between my-1'>"+
				"<div>Supplier</div>"+
				"<b>"+(uDatamainObj.supplier_caption||"Undf Caption")+"</b>"+
			"</div>"+

			"<div class='classborderbottom my-3'></div>"+

			"<div class='d-flex justify-content-between my-1'>"+
				"<div>Total Retur (Rp)</div>"+
				"<b class='text-success'>"+UFunc.formatAngka(uDatamainObj.nilai_total)+"</b>"+
			"</div>"+
			
			"<div class='my-3'></div>"+
			"<p>Apakah Anda Yakin ?</p>"+
			"")) apiProsessimpan();
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentTransaksi = () => {
		if(uHTMLError500) return "";

		return (
		<>
		<CRow className="my-2">
			<CCol>Tgl Retur</CCol>
			{uTokenObj.userhak!=="FREEROLE" ? (
				<CCol xs="7" className="text-end">{UFunc.HariAngka()}</CCol>
			) : (
				<CCol xs="7" className="text-end">
					<CFormInput size="sm" type="date"
						value={uDatamainObj.tgl_retur}
						onChange={(e)=>{uDatamainObj.tgl_retur=e.target.value;setEfekview(!uEfekview)}}
						id="inptglretur"/>
				</CCol>
			)}
		</CRow>

		<CRow className="my-2">
			<CCol>Supplier</CCol>
			<CCol xs="7" className="text-end">
				<CInputGroup size="sm">
					<CFormInput size="sm" 
						value={uDatamainObj.supplier_caption||""}
						onChange={(e)=>{uDatamainObj.supplier_caption=e.target.value;setEfekview(!uEfekview)}}
						onKeyDown={(e)=>hdlKDownSupplierlookup(e)}
						placeholder="--Keyword Supplier"
						id="inpsuppliercaption"/>
					<CButton size="sm" color="dark"
						onClick={()=>hdlKlikSupplierlookup()}
						id="btnSupplierlookup">
					<CIcon icon="cilSearch"/>
					</CButton>
				</CInputGroup>
			</CCol>
		</CRow>
		</>
		)//>
	}
	const contentSummary = () => {
		if(uHTMLError500) return "";

		return (
		<CRow className="align-items-center">
			<CCol className="">Total Retur (Rp)</CCol>
			<CCol xs="8" className="text-end">
				<big className="fw-bolder text-success fs-1">{UFunc.formatAngka(uDatamainObj.nilai_total)}</big>
			</CCol>
		</CRow>
		)//>
	}
	const contentItemsisa = () => {
		if(uHTMLError500) return "";

		if((parseInt(uDatamainObj.supplier_id)||0) <= 0) return (
			<div className="p-4 text-muted fst-italic">
			&middot;&middot; Silahkan Pilih Supplier Terlebih Dahulu &middot;&middot;
			</div>
		)//>

		if((uDatamainObj.is_loadsisa||false)===true) return (
			<div className="py-2 py-lg-5 text-center">
			<CSpinner color="primary"/>
			</div>
		)//>

		const vDatasisaArr	= (uDatamainObj.sisa_arr || []);//.sort((a,b)=> (parseInt(b.jml_retur)||0) - (parseInt(a.jml_retur)||0));

		return (
		<table className="table table-borderless table-striped" id="idtabelsisa">
			<thead>
				<tr className="align-top text-center classfontsmaller">
				<th width={35} className="">No</th>
				<th width="15%" className="text-start">Tgl Beli</th>
				<th className="text-start">Nama Barang</th>
				<th width="10%" className="">Jml.Sisa</th>
				<th width="10%" className="">Jml Retur</th>
				<th width="10%" className="">&middot;</th>
				</tr>
			</thead>

			<tbody className="classfontsmaller">
			{vDatasisaArr.map((vItems,vKeys)=>{
				const {
					id_masuk,tgl_proses,barang_caption,barang_id,
					no_batch,exp_date,h_satuan,jml_beli,sisa,jml_retur,
				} = vItems;

				return (
				<tr id={"idtr"+id_masuk}
					className={"align-top"+(uDatamainObj.sisa_rowidx===vKeys?" classrowselect":"")} 
					key={vKeys}>
					<td className="text-end"><small>{vKeys+1}</small></td>
					<td className="text-start"><small>{UFunc.TglAngka(tgl_proses)}</small></td>
					<td className="text-start"><small>{(barang_caption||"Undf Barang")}</small></td>
					<td className="text-center fw-bolder">{UFunc.formatAngka(sisa)}</td>
					<td className="text-center text-danger">{UFunc.formatAngka(jml_retur)}</td>
					<td className="text-center">
						<CTooltip content="--Set Retur Barang">
						<CLink onClick={()=>hdlKlikSisasetretur(id_masuk)} className="classcursorpointer">
						{(parseInt(jml_retur)||0) <= 0 ? (
						<CIcon icon="cilChevronRight" className="classikoncoreui mx-0 align-middle" height={25}/>
						) : (
						<CIcon icon="cilPencil" className="classikoncoreui mx-0 align-middle" style={{backgroundColor:"#d4ac0d88"}} height={25}/>
						)}
						</CLink>
						</CTooltip>
					</td>
				</tr>
				)//>
			})}
			</tbody>
		</table>
		)//>
	}
	const contentItemretur = () => {
		if(uHTMLError500) return "";

		const vDatareturArr	= uDatamainObj.retur_arr || [];
		return (
		<table className="table table-borderless table-striped" id="idtabelretur">
			<thead>
				<tr className="align-top classfontsmaller text-center">
				<th width="5%" className="d-none d-lg-table-cell">&middot;</th>
				<th width={35} className="">No</th>
				<th className="text-start">Nama Barang</th>
				<th width="7%" className="">QTY</th>
				<th width="17%" className="">H.Satuan<small className="d-block">(Rp)</small></th>
				<th width="20%" className="">Total<small className="d-block">(Rp)</small></th>
				<th width="5%" className="d-lg-none">&middot;</th>
				</tr>
			</thead>

			<tbody className="classfontsmaller">
			{vDatareturArr.map((vItems,vKeys)=>{
				const {
					id_masuk,tgl_proses,barang_caption,barang_id,
					no_batch,exp_date,h_satuan,jml_beli,sisa,jml_retur,
				} = vItems;

				return (
				<tr id={"idtr"+id_masuk}
					className={"align-top"+(uDatamainObj.retur_rowidx===vKeys?" classrowselect":"")} 
					key={vKeys}>
					<td className="text-center d-none d-lg-table-cell">
						<CTooltip content="--Batalkan Retur">
						<CLink onClick={()=>hdlKlikBatalkanretur(id_masuk)} className="classcursorpointer">
						<CIcon icon="cilChevronLeft" className="classikoncoreui mx-0 align-middle" height={25}/>
						</CLink>
						</CTooltip>
					</td>
					<td className="text-end"><small>{vKeys+1}</small></td>
					<td className="text-start"><small>{(barang_caption||"Undf Barang")}</small></td>
					<td className="text-center text-primary">{UFunc.formatAngka(jml_retur)}</td>
					<td className="text-end"><small>{UFunc.formatAngka(h_satuan)}</small></td>
					<td className="text-end text-success">
					{UFunc.formatAngka((parseFloat(h_satuan)||0) * (parseInt(jml_retur)||1))}
					</td>
					<td className="text-center d-lg-none">
						<CLink onClick={()=>hdlKlikBatalkanretur(id_masuk)} className="classikontabel classikonhapus classcursorpointer"/>
					</td>
				</tr>
				)//>
			})}
			</tbody>

			{(parseFloat(uDatamainObj.nilai_total)||0) > 0 && (
			<tfoot className="border-top fw-bolder">
			<tr className="align-top">
				<td className="d-none d-lg-table-cell"/>
				<td colSpan={4} className="text-center">JUMLAH</td>
				<td align="right">{UFunc.formatAngka(uDatamainObj.nilai_total)}</td>
				<td className="text-center d-lg-none"/>
			</tr>
			</tfoot>
			)}
		</table>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		return (
		<CForm>
		<small>
		<CRow className="my-2">
			<CCol xs="3">Nama Barang</CCol>
			<CCol className="text-end fw-bolder ps-0">{uDlgformObj.barang_caption||"Undf Barang"}</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4">Batch No.</CCol>
			<CCol className="text-end fw-bolder">
				{uDlgformObj.no_batch||"-"}
				{(uDlgformObj.exp_date||"")!=="" && (
				<>
					<span className="mx-1">&middot;</span>
					<span className="fst-italic">{UFunc.TglAngka(uDlgformObj.exp_date)}</span>
				</>
				)}
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
			<CCol xs="4">H.Satuan (Rp)</CCol>
			<CCol className="text-end fw-bolder">{UFunc.formatAngka(uDlgformObj.h_satuan)}</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4">Stok Sisa</CCol>
			<CCol className="text-end fw-bolder">{UFunc.formatAngka(uDlgformObj.sisa)} PCS</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2 text-success">
			<CCol xs="4" md="6" className="fw-bolder">JML RETUR</CCol>
			<CCol className="text-end">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number"
					max={uDlgformObj.sisa}
					min={0}
					value={uDlgformObj.jml_retur||0}
					onChange={(e)=>{uDlgformObj.jml_retur=e.target.value;setEfekview(!uEfekview)}}
					onFocus={(e)=>e.target.select()}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					className="text-end"
					id="inpjmlretur"/>
				<CInputGroupText>PCS</CInputGroupText>
				</CInputGroup>
			</CCol>
		</CRow>
		</small>
		</CForm>
		)//>
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)//>

		return contentItemsisa();
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {
		setHTMLError500();
		setDatasupplierArr([]);
		setBtnsimpandisabled(true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		setSessionaktif(false);
		let vTestingFE = setTimeout(()=>{
			clearTimeout(vTestingFE);
			setLoading(false);
			setIsFormprocess(false);

			setSessionaktif(true);
		},2000); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_returbeli/ld_form";
		
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoading(false);
			if(output_string.tampil) {
				setBtnsimpandisabled(false);
				setDatasupplierArr(JSON.parse(output_string.datasupplier || "[]"));
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Returbeliform - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
				return;
			}

			if(vTimeDiff > 800) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},800);
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Returbeliform - apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadsuppliersisa = () => {
		const vSupplierid	= parseInt(uDatamainObj.supplier_id)||0;
		uDatamainObj.is_loadsisa	= true;
		setBtnsimpandisabled(true);

		/*let vTimeout	= setTimeout(()=>{
			clearTimeout(vTimeout);

			uDatamainObj.is_loadsisa	= false;
			setBtnsimpandisabled(false);
			setLoading(false);
		},3000); return//-*/

		const vDATAS	= JSON.stringify({
			send_supplierid	: vSupplierid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_returbeli/ld_barangsisa";
		
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			uDatamainObj.is_loadsisa	= false;
			setBtnsimpandisabled(false);
			setLoading(false);
			if(output_string.tampil) {
				uDatamainObj.sisa_arr	= JSON.parse(output_string.databarang || "[]");
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Returbeliform - apiLoadsuppliersisa) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
				return;
			}

			if(vTimeDiff > 800) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},800);
			}
		})
		.catch((error) =>{
			uDatamainObj.is_loadsisa	= false;
			setBtnsimpandisabled(false);
			setLoading(false);
			console.log("(Returbeliform - apiLoadsuppliersisa) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsessimpan = () => {
		setBtnsimpandisabled(true);
		setLoading(true);

		const vTmpObj		= {fake_pars:"1",...uDatamainObj};
		vTmpObj.sisa_arr	= undefined;
		vTmpObj.fake_pars	= undefined;

		/*let vTimeout	= setTimeout(()=>{
			clearTimeout(vTimeout);

			setBtnsimpandisabled(false);
			setLoading(false);

			showToast(uBahasaObj.pesan_sukses||"Sukses !","SUKSES");
		},3000); return//-*/

		const vDATAS	= JSON.stringify({
			send_jsondata	: JSON.stringify(vTmpObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_returbeli/pr_simpan";
		
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setBtnsimpandisabled(false);
			setLoading(false);
			if(output_string.sukses) {
				setDatamainObj({});
				showToast(uBahasaObj.pesan_sukses||"Sukses !","SUKSES");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Returbeliform - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
				return;
			}

			if(vTimeDiff > 800) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},800);
			}
		})
		.catch((error) =>{
			setBtnsimpandisabled(false);
			setLoading(false);
			console.log("(Returbeliform - apiProsessimpan) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);

		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
		if(!uIsEOMExecuted) 
			{ uNavigate(-1); return; }

		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	
    	apiLoaddata();

    	if(!isMobile)
			document.getElementById("inptglretur") &&
				document.getElementById("inptglretur").focus()

    	return () => {
    		setDatamainObj({});//--HIDE_FOR_TEST--/
    		setDlgformshow(false);setDlglookupshow(false);
    	}
	},[])
	React.useEffect(()=>{
		if(uDatamainObj.tgl_retur===undefined) 
			uDatamainObj.tgl_retur = UFunc.DbDate();
	},[uDatamainObj,uDatamainObj.tgl_retur])
	React.useEffect(()=>{
		const vSupplierid	= (parseInt(uDatamainObj.supplier_id)||0);
		uDatamainObj.sisa_arr	= undefined; uDatamainObj.sisa_rowidx = undefined;
		uDatamainObj.retur_arr	= undefined; uDatamainObj.retur_rowidx = undefined;
		uDatamainObj.nilai_total= 0;
		if(vSupplierid === 0) { return; }

		const vArridx = UFunc.getObjectIndeks(uDatasupplierArr,vSupplierid);
		uDatamainObj.sisa_arr	= (uDatasupplierArr[vArridx]||{}).sisa_arr || [];

		if((uDatamainObj.sisa_arr||[]).length > 0) {
			setEfekview(!uEfekview);
			return;
		}

		apiLoadsuppliersisa();
	},[uDatamainObj,uDatamainObj.supplier_id])
	React.useEffect(()=>{
	},[uDatamainObj,uDatamainObj.supplier_id])
	React.useEffect(()=>{
		if((parseInt(uDatamainObj.supplier_id)||0) === 0
			&& (parseFloat(uDatamainObj.nilai_total)||0) === 0
			&& (uDatamainObj.retur_arr||[]).length <= 0
			&& (uDatamainObj.tgl_retur||UFunc.DbDate()) === UFunc.DbDate()
		) setFormupdate(false)
		else setFormupdate(true)
	},[uDatamainObj,
	uDatamainObj.supplier_id,
	uDatamainObj.nilai_total,
	uDatamainObj.retur_arr||[],
	uDatamainObj.tgl_retur])
	React.useEffect(()=>{
		if(!uISDlgformshow)
			{ setDlgformObj({}); return }
	},[uISDlgformshow])
	React.useEffect(()=>{
		if(!uISDlglookupshow)
			{ setDlglookupObj({}); return; }
	},[uISDlglookupshow])
	//---END EFFECT--/

	//console.log("(Returbeliform) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={()=>hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder text-uppercase">Form Retur Pembelian</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer"/>
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5">
		<CContainer fluid className="px-0">
			{uHTMLError500 ? (<MyPage500 content={uHTMLError500}/>) : (
			<CForm>
			<CRow className="align-items-stretch">
			<CCol lg="6" className="mb-3">
			<CCard className="h-100 classcardbg">
				<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
					<small>Data Transaksi</small>
				</CCardHeader>
				<CCardBody>{contentTransaksi()}</CCardBody>
			</CCard>
			</CCol>

			<CCol className="mb-3 d-none d-lg-block">
			<CCard className="h-100 classcardbg">
				<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
					<small>Summary</small>
				</CCardHeader>
				<CCardBody>{contentSummary()}</CCardBody>
			</CCard>
			</CCol>
			</CRow>

			<CRow className="align-items-stretch">
			<CCol lg="6" className="mb-3 d-none d-lg-block">
			<CCard className="h-100 classcardbg">
				<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
					<small>Data Barang SISA</small>
				</CCardHeader>
				<CCardBody className="px-0">{contentItemsisa()}</CCardBody>
			</CCard>
			</CCol>

			<CCol className="mb-3">
			<CCard className="h-100 classcardbg">
				<CCardHeader className="py-1 py-lg-0 pt-lg-1 d-flex justify-content-between">
					<div className="classfontsmaller text-muted">
						<small>Data Barang RETUR</small>
					</div>

					{(uDatamainObj.is_loadsisa||false)===true ? (
						<CSpinner color="primary" className="d-lg-none" size="sm"/>
					) : (
						<CLink onClick={()=>hdlKlikReturtambah()} className="classikontabel classikontambah d-lg-none"/>
					)}
				</CCardHeader>
				<CCardBody className="px-0">{contentItemretur()}</CCardBody>
			</CCard>
			</CCol>
			</CRow>
			</CForm>
			)}
		</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton disabled={uISBtnsimpandisabled}
				centered={true}
				iconname="cilCheckAlt"
				onClick={()=>hdlKlikSimpanmain()}
				id="btnSimpan">
				Simpan [F9]
			</MyButton>
		</CFooter>
		</div>

		<MyDialogview
			options={{centered:true}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewObj.dlg_header}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>

		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.dlg_header}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.dlg_header||""}
			inputValue={uDlglookupObj.dlg_input||""}
			dataItemArr={uDlglookupObj.data_arr || []} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>
		</>
	)//>
}	

export default Returbeliform