import React from 'react';
//import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CForm,
	CInputGroup,
	CFormInput,
	CButton,
	CTooltip,
	CLink,
	CImage,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import NumberFormat from 'react-number-format';
import { Konfirm } from '../../helpers/onConfirm';

const pjson     			= require('../../../package.json');
const Headersubkasirpiutang = (props) => {
	const uActiveroute 	= useSelector(state => state.activeRoute);
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	//const uNavigate  	= useNavigate();
	const uDispatch		= useDispatch();

	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uKeywordallObj	= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uEfekapp			= useSelector(state => state.effectApp);

	const uKwdpiutangObj	= uKeywordallObj.kwd_kasirpiutang || {};

	const [uKeywordteks,setKeywordteks] 	= React.useState(uKwdpiutangObj.kwd_inputteks || "YA");
	const [uInpkeyword,setInpkeyword] 		= React.useState(uKwdpiutangObj.kwd_value || "");

	const hdlKeydown = (_EV) => {
		if(_EV.which===13) {
			_EV.stopPropagation();
			_EV.preventDefault();

			document.getElementById("btnCari") &&
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari = () => {
		//---SAVE_KEYWORD--/
		//console.log("(Headersubkasirpiutang = hdlKlikCari) uInpkeyword = "+uInpkeyword.replace(/[^0-9,]/g, ""));
		uKwdpiutangObj.kwd_value 		= uKeywordteks==="YA" ? uInpkeyword.trim() : (parseInt((uInpkeyword.replace(/[^0-9,]/g, "")).replace(/,/g,"."))||0); 
		uKwdpiutangObj.kwd_inputteks 	= uKeywordteks;

		uKeywordallObj.kwd_kasirpiutang = uKwdpiutangObj;
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordallObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
			uKeywordallObj.kwd_kasirpiutang = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordallObj));
	    	setInpkeyword(""); setKeywordteks("YA");
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);

			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		//prosesReset(); return;
		if((uInpkeyword||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}

	//console.log("("+cComponentName+") Checked..");
	return(
		<>
		<div className="d-none d-lg-block">
			<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"}
				className="align-middle me-1" 
				height={30} />
			<small className="fst-italic">{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]}</small>
		</div>
		
		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm>
				<CInputGroup 
					style={{minWidth:295,width:"auto"}} 
					size="sm">
				<CTooltip content="--Toggle Keyword TEKS / NUMBER..">
				<CButton size="sm" color="light" className="border" 
					onClick={()=>{
						setKeywordteks(uKeywordteks==="YA" ? "TIDAK" : "YA")
					}}
					id="btnToggleteks">

					{(uKeywordteks==="YA") ? (
					<CIcon icon="cilTextSquare"/>
					) : (
					<CIcon icon="cilCash" className="text-success"/>
					)}
				</CButton>
				</CTooltip>

				{uKeywordteks==="YA" ? (
					<CFormInput size="sm" 
						value={uInpkeyword} 
						onChange={(e) => setInpkeyword(e.target.value)} 
						onKeyDown={(e) => hdlKeydown(e)} 
						placeholder={"--"+(uBahasaObj.petunjuk_keyword || "petunjuk_keyword")+".."} 
						id="inpkeyword"/>
				) : (
					<NumberFormat 
						displayType={'text'} 
						value={uInpkeyword}
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpkeyword(values.value)}
						renderText={values => (
							<CFormInput size="sm"
								value={values}
								placeholder="--Masukkan Angka/Nilai" 
								onChange={(e)=> setInpkeyword(e.target.value)}
								onKeyDown={(e) => hdlKeydown(e)} 
								onFocus={(e)=>e.target.select()}
								id="inpkeyword"/>
						)} 
						prefix=""/>
				)}

				<CButton size="sm" color="light" onClick={()=>hdlKlikCari()}
					className="border" 
					id="btnCari">
					<CIcon icon="cilMagnifyingGlass" />
				</CButton>
				</CInputGroup>
			</CForm>

			<span className="mx-1 ms-2 d-none d-lg-inline">&middot;</span>
			{uIsEOMExecuted ? (
			<CTooltip content={uBahasaObj.caption_resetview || "caption_resetview"}>
				<CLink
					 className="classcursorpointer classikon classikonreset" 
					onClick={()=>hdlKlikReset()}/>
			</CTooltip>
			) : (
			<span className="classblinking ms-1">
				<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classikoncoreui" height={30}/>
			</span>
			)}

			<span className="d-none d-md-block">
				<span className="me-1">&middot;</span>
				<b id="idjmldata">0</b> {uBahasaObj.word_data||"word_data"}
			</span>
		</div>
		</>
	);
}

export default Headersubkasirpiutang;